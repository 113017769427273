import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import expand from "../assets/expand.svg";
import condense from "../assets/condense.svg";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import {
  fetchRateCardData,
  previewCalcRateCardData,
} from "../actions/rateCard";
// import { ExportToPDF } from "../helper/exportToPDF";
import jnjlogo from "../assets/jnj-logo.png";
import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { setIsExecutingAction } from "../actions/calcConfig";

export function FMVRateCard({ rateCardDataToProcess }) {
  const monthsList = useRef([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const dispatch = useDispatch();

  const [userID, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [userWWID, setUserWWID] = useState("");
  const [baseRate, setBaseRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [calcName, setCalcName] = useState("");
  const [locale, setLocale] = useState("en-US");
  const [specialtyData, setSpecialtyData] = useState([]);
  const [activityTypesData, setActivityTypesData] = useState([]);
  const [travelDistanceData, setTravelDistanceData] = useState([]);
  const [groundRulesData, setGroundRulesData] = useState([]);
  const [hcpStatureData, setHcpStatureData] = useState([]);
  const [segmentsData, setSegmentsData] = useState([]);
  const [hourlyRateCardData, setHourlyRateCardData] = useState([]);
  const [travelTimeDiscount, setTravelTimeDiscount] = useState("");
  const [segmentId, setSegmentId] = useState("");
  // const [isTranslationAvailable, setIsTranslationAvailable] = useState(false);
  const [expandStandardHours, setExpandStandardHours] = useState(false);
  const [expandTravelDistance, setExpandTravelDistance] = useState(false);
  const [expandActivityTypes, setExpandActivityTypes] = useState(false);
  const [expandHCPSegmentLookup, setExpandHCPSegmentLookup] = useState(false);
  const [expandCalculationInpts, setExpandCalculationInpts] = useState(false);
  const [expandHourlyRateCard, setExpandHourlyRateCard] = useState(false);
  const [expandGroundRules, setExpandGroundRules] = useState(false);
  const [isDraftVersion, setIsDrftVersion] = useState(false);
  const [showMinRange, setShowMinRange] = useState(false);
  const [showMidRange, setShowMidRange] = useState(false);
  const [showMaxRange, setShowMaxRange] = useState(false);
  const [statureColSpan, setStatureColSpan] = useState("1");
  const [statureColSpanPDF, setStatureColSpanPDF] = useState(1);
  const [hideSpecialityMultiplier, setHideSpecialityMultiplier] =
    useState(false);
  const [hideStatureMultiplier, setHideStatureMultiplier] = useState(false);
  const [toggleExpandCollaps, setToggleExpandCollaps] = useState(false);

  const [completedbyLabel, setcompletedbyLabel] = useState("COMPLETED BY");
  const [referenceNumberLabel, setreferenceNumberLabel] =
    useState("Reference Number");
  const [calculationInputsLabel, setcalculationInputsLabel] =
    useState("CALCULATION INPUTS");
  const [baseRatePerHourLabel, setbaseRatePerHourLabel] =
    useState("Base Rate Per Hour");
  const [hcpSpecialityMultipliersLabel, sethcpSpecialityMultipliersLabel] =
    useState("HCP Specialty multipliers");
  const [hcpKolMultipliersLabel, sethcpKolMultipliersLabel] = useState(
    "HCP KOL multipliers"
  );
  const [hcpFmvHourlyRateCardLabel, sethcpFmvHourlyRateCardLabel] = useState(
    "HCP FMV Hourly Rate Card (inclusive of multipliers)"
  );
  const [nonKolLabel, setnonKolLabel] = useState("Non KOL");
  const [localKolLabel, setlocalKolLabel] = useState("Local KOL");
  const [nationalKolLabel, setnationalKolLabel] = useState("National KOL");
  const [internationalKolLabel, setinternationalKolLabel] =
    useState("International KOL");
  const [hcpSpecialtySegmentsLabel, sethcpSpecialtySegmentsLabel] = useState(
    "HCP specialty segments"
  );
  const [baseLabel, setbaseLabel] = useState("Base");
  const [rulesLabel, setrulesLabel] = useState("Ground Rules");
  const [hcpSegmentLookupLabel, sethcpSegmentLookupLabel] =
    useState("HCP Segment Lookup");
  const [specialtyLabel, setspecialtyLabel] = useState("Specialty");
  const [hcpSpecialtySegmentLabel, sethcpSpecialtySegmentLabel] = useState(
    "HCP Specialty Segment"
  );
  const [activityTypesLabel, setactivityTypesLabel] =
    useState("Activity Types");
  const [caphrsLabel, setcaphrsLabel] = useState("Cap (Hrs)");
  const [
    regulatoryCapsPerHcpStatureRateHrLabel,
    setregulatoryCapsPerHcpStatureRateHrLabel,
  ] = useState("Regulatory Caps Per HCP Stature (Rate/Hr)");
  const [
    regulatoryCapsPerHcpStatureRateDayOrSessionLabel,
    setregulatoryCapsPerHcpStatureRateDayOrSessionLabel,
  ] = useState("Regulatory Caps Per HCP Stature (Rate/Day or Session)");
  const [travelTimeOfTheHourlyRateLabel, settravelTimeOfTheHourlyRateLabel] =
    useState("Travel time - % of the hourly rate");
  const [activityLocationLabel, setactivityLocationLabel] =
    useState("Activity Location");
  const [countryConsensusLabel, setcountryConsensusLabel] =
    useState("Market Consensus");
  const [distanceLabel, setdistanceLabel] = useState("Distance");
  const [commentsLabel, setcommentsLabel] = useState("Comments");
  const [prepTimeLabel, setprepTimeLabel] = useState("Prep Time");
  const [travelTimeLabel, settravelTimeLabel] = useState("Travel Time");
  const [standardHoursLabel, setstandardHoursLabel] =
    useState("Standard hours");
  const [midLabel, setmidLabel] = useState("Mid");
  const [minimumLabel, setminimumLabel] = useState("Minimum");
  const [maximumLabel, setmaximumLabel] = useState("Maximum");
  const [hoursLabel, sethoursLabel] = useState("hours");
  const [activityTypeLabel, setactivityTypeLabel] = useState("Activity Type");
  const [serviceTimeLabel, setserviceTimeLabel] = useState("Service Time");
  const [calcRegion, setCalcRegion] = useState("");
  const [sectorLabel, setSectorLabel] = useState("Sector");
  const [regionLabel, setRegionLabel] = useState("Region");
  const [countryLabel, setCountryLabel] = useState("Market");
  const [calcSectors, setCalcSectors] = useState("");
  const [lastPublishedLabel, setLastPublishedLabel] =
    useState("Last Published On");
  const [lastUpdatedLabel, setLastUpdatedLabel] = useState("Last Updated On");
  const [lastPublished, setLastPublished] = useState("");

  const userInformation = useSelector((state) => state.userInformation);
  const userInformationAuth = useSelector((state) => state.userInformationAuth);
  const calculatorData = useSelector(
    (state) => state.calculator?.calculatorData
  );
  const rateCardData = useSelector((state) => state.rateCard?.rateCardData);
  const referenceNumberData = useSelector(
    (state) => state.rateCard?.referenceNumberData?.data
  );
  const languageTranslationData = useSelector(
    (state) => state.calculator?.selectedTranslationData
  );
  const draftVersionStatusData = useSelector(
    (state) => state.calculator?.draftVersionStatusData
  );
  const calculatorHeaderData = useSelector(
    (state) => state.calculator?.calculatorHeaderData
  );
  const contrySectorNameData = useSelector(
    (state) => state.calculator?.contrySectorNameData
  );
  const isPreviewCalculatorData = useSelector(
    (state) => state.calculator?.isPreviewCalculatorData
  );

  useEffect(() => {
    if (userInformation && userInformation?.data) {
      //setUserID(userInformation.data.users[0].name);
      let name = (userInformationAuth?.data1.mail).split('@')[0];
      setUserID(name);
      setUserName(
        (
          userInformationAuth.data.users[0].givenNameFromAuth +
          " " +
          userInformationAuth.data.users[0].snFromAuth
        ).toUpperCase()
      );
      setUserWWID(userInformationAuth?.data1.employeeId);
    }
  }, [userInformationAuth]);

  useEffect(() => {
    if (calculatorData) {
      if (calculatorData?.data?.basic) {
        setBaseRate(calculatorData?.data?.basic.base_rate);
        setCurrency(calculatorData?.data?.basic.currency);
        setCalcName(calculatorData?.data?.basic.name);
        setCalcRegion(calculatorData?.data?.basic?.region);
        if (calculatorData?.data?.basic?.last_updated_date) {
          let date = new Date(calculatorData?.data?.basic?.last_updated_date);
          setLastPublished(
            monthsList.current[date.getMonth()] +
              " " +
              date.getDate() +
              ", " +
              date.getFullYear()
          );
        }
        if (calculatorData?.data?.sectors) {
          setCalcSectors(
            _.map(calculatorData?.data?.sectors, (o) => {
              return o.sector;
            }).join(", ")
          );
        }
        // setIsTranslationAvailable(
        //   calculatorData?.data?.basic.is_translation_available === true
        //     ? true
        //     : false
        // );
        let decimalPointer = calculatorData?.data?.basic.punc_decimal_pointer;
        let thousandPointer = calculatorData?.data?.basic.punc_thousand_pointer;

        if (
          decimalPointer &&
          decimalPointer !== null &&
          thousandPointer &&
          thousandPointer !== null
        ) {
          setLocale(
            decimalPointer.toLowerCase() === "point(.)"
              ? "en-US"
              : thousandPointer.toLowerCase() === "point(.)"
              ? "de"
              : "fr-FR"
          );
        }
      }

      // if (calculatorData?.data?.hcpStatures) {
      //     setHCPStaturesList(calculatorData?.data?.hcpStatures.map((obj, i) => {
      //         return (
      //             <option key={i} value={obj.hcp_stature_id}>
      //                 {obj.HCP_Stature}
      //             </option>
      //         );
      //     }))
      // }
      if (calculatorData?.data?.hcpStatures) {
        setHcpStatureData(calculatorData?.data?.hcpStatures);
      }
      if (calculatorData?.data?.segments) {
        setSegmentsData(calculatorData?.data?.segments);
      }
      if (calculatorData?.data?.groundRules) {
        //setGroundRulesData(calculatorData?.data?.groundRules[0]?.rule_desc);
        setGroundRulesData(calculatorData?.data?.groundRules);
      }
      if (calculatorData?.data?.hcpSpecialty) {
        setSpecialtyData(calculatorData?.data?.hcpSpecialty);
      }
      if (calculatorData?.data?.activityTypes) {
        setActivityTypesData(calculatorData?.data?.activityTypes);
      }
      if (calculatorData?.data?.travelDistances) {
        setTravelDistanceData(calculatorData?.data?.travelDistances);
      }
      if (calculatorData?.data?.travelTimeDiscount) {
        let travelDiscount =
          calculatorData?.data?.travelTimeDiscount.percentage_discount;
        setTravelTimeDiscount(
          travelDiscount && travelDiscount !== null ? travelDiscount : 0
        );
      }
    }
  }, [calculatorData]);

  useEffect(() => {
    if (
      languageTranslationData !== undefined &&
      languageTranslationData !== ""
    ) {
      function findTranslation(keyname) {
        if (
          _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE &&
          _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE !== "" &&
          _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE !== undefined
        ) {
          return _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE;
        } else {
          return "";
        }
      }
      setcompletedbyLabel(
        findTranslation("completed_by") !== ""
          ? findTranslation("completed_by")
          : "COMPLETED BY"
      );
      setSectorLabel(
        findTranslation("sector") !== "" ? findTranslation("sector") : "Sector"
      );
      setCountryLabel(
        findTranslation("Market") !== "" ? findTranslation("Market") : "Market"
      );
      setRegionLabel(
        findTranslation("region") !== "" ? findTranslation("region") : "region"
      );
      setLastPublishedLabel(
        findTranslation("last_published_on") !== ""
          ? findTranslation("last_published_on")
          : "Last Published On"
      );
      setLastUpdatedLabel(
        findTranslation("last_updated_on") !== ""
          ? findTranslation("last_updated_on")
          : "Last Updated On"
      );
      setreferenceNumberLabel(
        findTranslation("reference_number") !== ""
          ? findTranslation("reference_number")
          : "Reference Number"
      );
      setcalculationInputsLabel(
        findTranslation("calculation_inputs") !== ""
          ? findTranslation("calculation_inputs")
          : "CALCULATION INPUTS"
      );
      setbaseRatePerHourLabel(
        findTranslation("base_rate_per_hour") !== ""
          ? findTranslation("base_rate_per_hour")
          : "Base Rate Per Hour"
      );
      sethcpSpecialityMultipliersLabel(
        findTranslation("hcp_speciality_multipliers") !== ""
          ? findTranslation("hcp_speciality_multipliers")
          : "HCP Specialty multipliers"
      );
      sethcpKolMultipliersLabel(
        findTranslation("hcp_kol_multipliers") !== ""
          ? findTranslation("hcp_kol_multipliers")
          : "HCP KOL multipliers"
      );
      sethcpFmvHourlyRateCardLabel(
        findTranslation("hcp_fmv_hourly_rate_card") !== ""
          ? findTranslation("hcp_fmv_hourly_rate_card")
          : "HCP FMV Hourly Rate Card (inclusive of multipliers)"
      );
      setnonKolLabel(
        findTranslation("non_kol") !== ""
          ? findTranslation("non_kol")
          : "Non KOL"
      );
      setlocalKolLabel(
        findTranslation("local_kol") !== ""
          ? findTranslation("local_kol")
          : "Local KOL"
      );
      setnationalKolLabel(
        findTranslation("national_kol") !== ""
          ? findTranslation("national_kol")
          : "National KOL"
      );
      setinternationalKolLabel(
        findTranslation("international_kol") !== ""
          ? findTranslation("international_kol")
          : "International KOL"
      );
      sethcpSpecialtySegmentsLabel(
        findTranslation("hcp_specialty_segments") !== ""
          ? findTranslation("hcp_specialty_segments")
          : "HCP specialty segments"
      );
      setbaseLabel(
        findTranslation("base") !== "" ? findTranslation("base") : "Base"
      );
      setrulesLabel(
        findTranslation("rules") !== ""
          ? findTranslation("rules")
          : "Ground Rules"
      );
      sethcpSegmentLookupLabel(
        findTranslation("hcp_segment_lookup") !== ""
          ? findTranslation("hcp_segment_lookup")
          : "HCP Segment Lookup"
      );
      setspecialtyLabel(
        findTranslation("specialty") !== ""
          ? findTranslation("specialty")
          : "Specialty"
      );
      sethcpSpecialtySegmentLabel(
        findTranslation("hcp_specialty_segment") !== ""
          ? findTranslation("hcp_specialty_segment")
          : "HCP Specialty Segment"
      );
      setactivityTypesLabel(
        findTranslation("activity_types") !== ""
          ? findTranslation("activity_types")
          : "Activity Types"
      );
      setcaphrsLabel(
        findTranslation("cap_hrs") !== ""
          ? findTranslation("cap_hrs")
          : "Cap (Hrs)"
      );
      setregulatoryCapsPerHcpStatureRateHrLabel(
        findTranslation("regulatory_caps_per_hcp_stature_rate_hr") !== ""
          ? findTranslation("regulatory_caps_per_hcp_stature_rate_hr")
          : "Regulatory Caps Per HCP Stature (Rate/Hr)"
      );
      setregulatoryCapsPerHcpStatureRateDayOrSessionLabel(
        findTranslation(
          "regulatory_caps_per_hcp_stature_rate_day_or_session"
        ) !== ""
          ? findTranslation(
              "regulatory_caps_per_hcp_stature_rate_day_or_session"
            )
          : "Regulatory Caps Per HCP Stature (Rate/Day or Session)"
      );
      settravelTimeOfTheHourlyRateLabel(
        findTranslation("travel_time_of_the_hourly_rate") !== ""
          ? findTranslation("travel_time_of_the_hourly_rate")
          : "Travel time - % of the hourly rate"
      );
      setactivityLocationLabel(
        findTranslation("activity_location") !== ""
          ? findTranslation("activity_location")
          : "Activity Location"
      );
      setcountryConsensusLabel(
        findTranslation("Market_consensus") !== ""
          ? findTranslation("Market_consensus")
          : "Market Consensus"
      );
      setdistanceLabel(
        findTranslation("distance") !== ""
          ? findTranslation("distance")
          : "Distance"
      );
      setcommentsLabel(
        findTranslation("comments") !== ""
          ? findTranslation("comments")
          : "Comments"
      );
      setprepTimeLabel(
        findTranslation("prep_time") !== ""
          ? findTranslation("prep_time")
          : "Prep Time"
      );
      settravelTimeLabel(
        findTranslation("travel_time") !== ""
          ? findTranslation("travel_time")
          : "Travel Time"
      );
      setstandardHoursLabel(
        findTranslation("standard_hours") !== ""
          ? findTranslation("standard_hours")
          : "Standard hours"
      );
      setmidLabel(
        findTranslation("mid") !== "" ? findTranslation("mid") : "Mid"
      );
      setminimumLabel(
        findTranslation("minimum") !== ""
          ? findTranslation("minimum")
          : "Minimum"
      );
      setmaximumLabel(
        findTranslation("maximum") !== ""
          ? findTranslation("maximum")
          : "Maximum"
      );
      sethoursLabel(
        findTranslation("hours") !== "" ? findTranslation("hours") : "hours"
      );
      setactivityTypeLabel(
        findTranslation("activity_type") !== ""
          ? findTranslation("activity_type")
          : "Activity Type"
      );
      setserviceTimeLabel(
        findTranslation("service_time") !== ""
          ? findTranslation("service_time")
          : "Service Time"
      );
    }
  }, [languageTranslationData]);

  useEffect(() => {
    if (
      rateCardDataToProcess !== undefined &&
      rateCardDataToProcess.country_id &&
      rateCardDataToProcess.sector_id
    ) {
      let data = {
        country_id: rateCardDataToProcess?.country_id,
        sector_id: rateCardDataToProcess?.sector_id,
      };
      dispatch(fetchRateCardData(data));
    } else if (
      rateCardDataToProcess !== undefined &&
      rateCardDataToProcess.preview
    ) {
      let data = {
        calc_id: rateCardDataToProcess.calc_id,
        ...(rateCardDataToProcess.version !== "" &&
        rateCardDataToProcess.version
          ? { version: rateCardDataToProcess.version }
          : {}),
      };
      dispatch(previewCalcRateCardData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateCardDataToProcess]);

  useEffect(() => {
    if (rateCardData !== undefined && rateCardData?.data !== undefined) {
      setHourlyRateCardData(rateCardData?.data?.segments);
      // eslint-disable-next-line array-callback-return
      rateCardData?.data?.segments.map((o) => {
        if (o.isBase) {
          setSegmentId(o.segment_id);
        }
      });
      if (rateCardData?.data?.hide_speciality_multiplier === true) {
        setHideSpecialityMultiplier(true);
      } else {
        setHideSpecialityMultiplier(false);
      }
      if (rateCardData?.data?.hide_stature_multiplier === true) {
        setHideStatureMultiplier(true);
      } else {
        setHideStatureMultiplier(false);
      }
      if (
        rateCardData?.data?.ranges_applicable &&
        rateCardData?.data?.ranges_applicable !== undefined &&
        rateCardData?.data?.ranges_applicable !== ""
      ) {
        let rangedata = JSON.parse(rateCardData?.data?.ranges_applicable);
        if (rangedata?.length === 0) {
          setShowMinRange(true);
          setShowMidRange(true);
          setShowMaxRange(true);
          setStatureColSpan("3");
          setStatureColSpanPDF(3);
        } else if (rangedata?.length > 0) {
          let span = rangedata?.length?.toString();
          setStatureColSpan(span);
          setStatureColSpanPDF(rangedata?.length);
          let mincheck = false,
            midcheck = false,
            maxcheck = false;
          _.map(rangedata, (o) => {
            if (o?.toLowerCase() === "min") {
              mincheck = true;
            }
            if (o?.toLowerCase() === "mid") {
              midcheck = true;
            }
            if (o?.toLowerCase() === "max") {
              maxcheck = true;
            }
          });
          if (mincheck) {
            setShowMinRange(true);
          } else {
            setShowMinRange(false);
          }
          if (midcheck) {
            setShowMidRange(true);
          } else {
            setShowMidRange(false);
          }
          if (maxcheck) {
            setShowMaxRange(true);
          } else {
            setShowMaxRange(false);
          }
        }
      } else if (rateCardData?.data?.ranges_applicable === null) {
        setShowMinRange(true);
        setShowMidRange(true);
        setShowMaxRange(true);
        setStatureColSpan("3");
        setStatureColSpanPDF(3);
      }
    }
  }, [rateCardData]);

  useEffect(() => {
    if (
      draftVersionStatusData &&
      draftVersionStatusData !== "" &&
      draftVersionStatusData !== undefined
    ) {
      if (draftVersionStatusData?.toLowerCase() === "draftversion") {
        setIsDrftVersion(true);
      }
    }
  }, [draftVersionStatusData]);

  const CalculationInptsTable = () => {
    return (
      <table className="base_rate_table">
        <tbody>
          <tr className="">
            <td className="table_side_heading left-padding">
              {baseRatePerHourLabel}
            </td>
            <td className="br_comman_col">{currency}</td>
            <td className="br_comman_col">
              {baseRate?.toLocaleString(locale)}
            </td>
          </tr>
          {!hideSpecialityMultiplier ? (
            <SegmentsTable tableData={segmentsData}></SegmentsTable>
          ) : (
            ""
          )}
          {!hideStatureMultiplier ? (
            <HCPStatureTable tableData={hcpStatureData}></HCPStatureTable>
          ) : (
            ""
          )}
        </tbody>
      </table>
    );
  };

  const CalculationInptsTableForPDF = () => {
    let mapdata = [],
      val = 0;
    if (!hideSpecialityMultiplier && !hideStatureMultiplier) {
      if (segmentsData?.length > hcpStatureData?.length) {
        mapdata = segmentsData;
        val = 1;
      } else if (segmentsData?.length < hcpStatureData?.length) {
        mapdata = hcpStatureData;
        val = 0;
      }
    }
    if (hideSpecialityMultiplier && !hideStatureMultiplier) {
      mapdata = hcpStatureData;
      val = 0;
    }
    if (!hideSpecialityMultiplier && hideStatureMultiplier) {
      mapdata = segmentsData;
      val = 1;
    }
    // eslint-disable-next-line array-callback-return
    const col = mapdata?.map((row, index) => {
      if (index > val) {
        return <td key={index + 1}></td>;
      }
    });
    return (
      <table
        id="ratecard_calculaorinputs_id"
        className="base_rate_table rate_card_inputs_css"
      >
        <tbody>
          <tr className="">
            <td className="table_side_heading left-padding">
              {baseRatePerHourLabel}
            </td>
            <td className="br_comman_col">{currency}</td>
            <td className="br_comman_col">{CheckSeparator(baseRate)}</td>
            {col}
          </tr>
          {!hideSpecialityMultiplier ? (
            <SegmentsTable tableData={segmentsData}></SegmentsTable>
          ) : (
            ""
          )}
          {!hideStatureMultiplier ? (
            <HCPStatureTable tableData={hcpStatureData}></HCPStatureTable>
          ) : (
            ""
          )}
        </tbody>
      </table>
    );
  };

  const SegmentsTable = (props) => {
    const row1 = props.tableData?.map((row, index) => {
      return (
        <td className="br_comman_col" key={index + 2}>
          {row?.segment}
          {row?.segment_id === segmentId ? " (" + baseLabel + ")" : ""}
        </td>
      );
    });
    const row2 = props.tableData?.map((row, index) => {
      return (
        <td className="br_multiplier_col" key={index + 2}>
          {Number.isInteger(row?.multiplier)
            ? locale === "en-US"
              ? row?.multiplier?.toFixed(1)
              : row?.multiplier + ",0"
            : row?.multiplier?.toLocaleString(locale)}
        </td>
      );
    });
    return (
      <>
        <tr className="">
          <td className="table_side_heading hcp_border_bnone left-padding">
            {hcpSpecialityMultipliersLabel}
          </td>
          {row1}
        </tr>
        <tr className="">
          <td className="table_side_heading hcp_border_tnone"></td>
          {row2}
        </tr>
      </>
    );
  };

  const HCPStatureTable = (props) => {
    const row1 = props.tableData?.map((row, index) => {
      return (
        <td className="br_comman_col" key={index + 2}>
          {row?.HCP_Stature}
        </td>
      );
    });
    const row2 = props.tableData?.map((row, index) => {
      return (
        <td className="br_multiplier_col" key={index + 2}>
          {Number.isInteger(row?.multiplier)
            ? locale === "en-US"
              ? row?.multiplier?.toFixed(1)
              : row?.multiplier + ",0"
            : row?.multiplier?.toLocaleString(locale)}
        </td>
      );
    });
    return (
      <>
        <tr className="">
          <td className="table_side_heading hcp_border_bnone left-padding">
            {hcpKolMultipliersLabel}
          </td>
          <td className="br_comman_col"></td>
          {row1}
          {!hideSpecialityMultiplier ? <td className="br_comman_col"></td> : ""}
        </tr>
        <tr className="">
          <td className="table_side_heading hcp_border_tnone"></td>
          <td className="br_comman_col"></td>
          {row2}
          {!hideSpecialityMultiplier ? <td className="br_comman_col"></td> : ""}
        </tr>
      </>
    );
  };

  const GroundRulesTable = (props) => {
    const rows = props.tableData?.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td className="left-padding">
            <div dangerouslySetInnerHTML={{ __html: row?.rule_desc }} />
          </td>
        </tr>
      );
    });
    return (
      <table className="ground_rules_table">
        <tbody>
          <tr key={0} className="header">
            <td className="specialty_header left-padding">{rulesLabel}</td>
          </tr>
          {rows}
        </tbody>
      </table>
    );
  };

  const HourlyRateCardTable = (props) => {
    const rows1 = props.tableData?.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td key={0} className="left-padding">
            {row?.segment}
            {row?.isBase ? " (" + baseLabel + ")" : ""}
          </td>
          {hcpStatureData?.map((obj, i) => {
            // eslint-disable-next-line array-callback-return
            return row?.hcpStatures?.map((o, index1) => {
              if (obj?.hcp_stature_id === o?.hcp_stature_id) {
                return (
                  <>
                    {showMinRange ? (
                      <td key={i + 1} className="hrc_col_min">
                        {o?.min?.toLocaleString(locale)}
                      </td>
                    ) : (
                      ""
                    )}
                    {showMidRange ? (
                      <td
                        key={i + 2}
                        className={
                          o?.isBase
                            ? "hrc_col_mid hrc_col_isbase"
                            : "hrc_col_mid"
                        }
                      >
                        {o.mid?.toLocaleString(locale)}
                      </td>
                    ) : (
                      ""
                    )}
                    {showMaxRange ? (
                      <td key={i + 3} className="hrc_col_max">
                        {o?.max?.toLocaleString(locale)}
                      </td>
                    ) : (
                      ""
                    )}
                  </>
                );
              }
            });
          })}
        </tr>
      );
    });
    return (
      <>
        <table className="hourly_rate_card">
          <tbody>
            <tr key={0} className="header">
              <td key={0} className="kol_rc_left_header left-padding">
                {hcpKolMultipliersLabel}
              </td>
              {hcpStatureData.map((obj, index2) => {
                return (
                  <td
                    className="kol_rate_card_header"
                    colSpan={statureColSpan}
                    key={index2 + 1}
                  >
                    {obj?.HCP_Stature}
                  </td>
                );
              })}
            </tr>
            <tr key={1} className="table_header">
              <td key={0} className="kol_rc_left_header left-padding">
                {hcpSpecialtySegmentsLabel}
              </td>
              {hcpStatureData.map((obj, index3) => {
                return (
                  <>
                    {showMinRange ? (
                      <td
                        key={index3 + 1}
                        className="hourly_rate_card_comman_Col"
                      >
                        {minimumLabel}
                      </td>
                    ) : (
                      ""
                    )}
                    {showMidRange ? (
                      <td
                        key={index3 + 2}
                        className="hourly_rate_card_comman_Col"
                      >
                        {midLabel}
                      </td>
                    ) : (
                      ""
                    )}
                    {showMaxRange ? (
                      <td
                        key={index3 + 3}
                        className="hourly_rate_card_comman_Col"
                      >
                        {maximumLabel}
                      </td>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </tr>
            {rows1}
          </tbody>
        </table>
      </>
    );
  };

  const HCPSegmentLookupTable = (props) => {
    const rows = props.tableData.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td className="specialty left-padding">{row.HCP_Specialty}</td>
          <td className="segment">
            {row.segment}
            {row.segment_id === segmentId ? " (" + baseLabel + ")" : ""}
          </td>
        </tr>
      );
    });
    return (
      <table className="segment_lookup">
        <tbody>
          <tr key={0} className="header">
            <td className="specialty_header left-padding">{specialtyLabel}</td>
            <td className="segment_header align-middle">
              {hcpSpecialtySegmentLabel}
            </td>
          </tr>
          {rows}
        </tbody>
      </table>
    );
  };

  const ActivityTypesTable = (props) => {
    const rows = props.tableData?.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td className="left-padding">{row.Activity_Type}</td>
          <td className="at_col_Center">
            {row?.hcp_stature_hr_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.statureCaps, (o) => {
              return o?.["HCP Stature"]?.toLowerCase()?.includes("non");
            }).rate_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.statureCaps, (o) => {
              return o?.["HCP Stature"]?.toLowerCase()?.includes("local");
            }).rate_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.statureCaps, (o) => {
              return o?.["HCP Stature"]?.includes("National");
            }).rate_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.statureCaps, (o) => {
              return o?.["HCP Stature"]?.includes("International");
            })?.rate_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.sessionCaps, (o) => {
              return o?.hcp_stature?.toLowerCase()?.includes("non");
            })?.session_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.sessionCaps, (o) => {
              return o?.hcp_stature?.toLowerCase()?.includes("local");
            })?.session_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.sessionCaps, (o) => {
              return o?.hcp_stature?.includes("National");
            })?.session_cap?.toLocaleString(locale)}
          </td>
          <td className="at_col_Center">
            {_.find(row?.sessionCaps, (o) => {
              return o?.hcp_stature?.includes("International");
            })?.session_cap?.toLocaleString(locale)}
          </td>
        </tr>
      );
    });
    return (
      <>
        <table className="activity_type">
          <tbody>
            <tr key={0} className="header">
              <td className="activity_Type_header">{activityTypeLabel}</td>
              <td className="activity_Type_cap_header">{caphrsLabel}</td>
              <td className="hcp_stature_cap_header">
                {regulatoryCapsPerHcpStatureRateHrLabel}
              </td>
              <td className="hcp_stature_cap_header">
                {regulatoryCapsPerHcpStatureRateDayOrSessionLabel}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="activity_type">
          <tbody>
            <tr key={1} className="table_header">
              <td className="activity_Type_table_header"></td>
              <td className="activity_Type_cap_Col">{caphrsLabel}</td>
              <td className="activity_Type_comman_Col">{nonKolLabel}</td>
              <td className="activity_Type_comman_Col">{localKolLabel}</td>
              <td className="activity_Type_comman_Col">{nationalKolLabel}</td>
              <td className="activity_Type_comman_Col">
                {internationalKolLabel}
              </td>
              <td className="activity_Type_comman_Col">{nonKolLabel}</td>
              <td className="activity_Type_comman_Col">{localKolLabel}</td>
              <td className="activity_Type_comman_Col">{nationalKolLabel}</td>
              <td className="activity_Type_comman_Col">
                {internationalKolLabel}
              </td>
            </tr>
            {rows}
          </tbody>
        </table>
      </>
    );
  };

  const TravelDistanceTable = (props) => {
    const rows = props.tableData.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td className="distance left-padding">{row.title}</td>
          <td className="travel_time">{row.time_hr?.toLocaleString(locale)}</td>
          <td className="comments left-padding">{row.comments}</td>
        </tr>
      );
    });
    return (
      <table className="travel_distance">
        <tbody>
          <tr key={0} className="header">
            <td>{activityLocationLabel}</td>
            <td>{countryConsensusLabel}</td>
            <td></td>
          </tr>
          <tr key={1} className="table_header">
            <td>{distanceLabel}</td>
            <td>{hoursLabel}</td>
            <td>{commentsLabel}</td>
          </tr>
          {rows}
        </tbody>
      </table>
    );
  };

  const StandardHoursTable = (props) => {
    const rows = props.tableData.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td className="activity_type_name left-padding">
            {row.Activity_Type}
          </td>
          <td className="service_time">
            {row.service_time_hrs?.toLocaleString(locale)}
          </td>
          <td className="prep_time">
            {row.prep_time_hrs?.toLocaleString(locale)}
          </td>
          <td className="comments left-padding">{row.comments}</td>
        </tr>
      );
    });
    return (
      <table className="stnd_hours">
        <tbody>
          <tr key={0} className="header">
            <td>{activityTypeLabel}</td>
            <td>{serviceTimeLabel}</td>
            <td>{prepTimeLabel}</td>
            <td></td>
          </tr>
          <tr key={1} className="table_header">
            <td></td>
            <td>{hoursLabel}</td>
            <td>{hoursLabel}</td>
            <td>{commentsLabel}</td>
          </tr>
          {rows}
        </tbody>
      </table>
    );
  };

  // const ExportRateCardToPDF = () => {
  //     ExportToPDF('div-fmv-ratecard', 'FMV Rate Card', undefined, 'HCP FMV Hourly Rate Card');
  // }
  const CheckSeparator = (val) => {
    if (locale === "fr-FR") {
      let stringEng = val?.toLocaleString("en-US");
      let spaceStr = stringEng?.replace(/,/g, " ");
      let newStr = spaceStr?.replace(/\./g, ",");
      return newStr;
    } else {
      return val?.toLocaleString(locale);
    }
  };
 
 
   function newGroundRule(str) {
    let arr = [];
    let arr1 = [];
    if (str) {
      for (let i = 0; i < str.length; i++) {
        if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "s" &&
          str[i + 3] === "p" &&
          str[i + 4] === "a" &&
          str[i + 5] === "n" &&
          str[i + 6] === ">"
        ) {
          i = i + 6;
        } else if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "s" &&
          str[i + 3] === "t" &&
          str[i + 4] === "r" &&
          str[i + 5] === "o" &&
          str[i + 6] === "n" &&
          str[i + 7] === "g" &&
          str[i + 8] === ">"
        ) {
          i = i + 8;
        } else if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "u" &&
          str[i + 3] === ">"
        ) {
          i = i + 3;
        } else if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "e" &&
          str[i + 3] === "m" &&
          str[i + 4] === ">"
        ) {
          i = i + 4;
        }else if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "a" &&          
          str[i + 3] === ">"
        ) {
          i = i + 3;
        } else if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "d" &&   
          str[i + 3] === "e" &&  
          str[i + 4] === "l" &&         
          str[i + 5] === ">"
        ) {
          i = i + 5;
        }
        else if (
          str[i] === "<" &&
          str[i + 1] === "/" &&
          str[i + 2] === "h" &&   
          str[i + 3] === "3" &&                  
          str[i + 4] === ">"
        ) {
          i = i + 4;
        } else {
          arr.push(str[i]);
        }
      }
     
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "<" && arr[i + 1] === "/" && arr[i - 1] !== ">") {
          arr1.push("<");
          arr1.push("b");
          arr1.push("r");
          arr1.push(">");
          arr1.push(arr[i]);
        } else {
          arr1.push(arr[i]);
        }
      }
    }
    return arr1.join("");
  }
  
  const ExportRateCardToJsPDF = () => {
    //const table = GroundRulesTable1();
    dispatch(setIsExecutingAction(true));
    document.getElementById("ratecard_calculaorinputs_id").style.display =
      "block";
    let HourlyRateCardDocData = [];
    if (hourlyRateCardData?.length > 0 && hcpStatureData?.length > 0) {
      _.map(hourlyRateCardData, (obj) => {
        let tempobj = [];
        let segmentt, s_min, s_mid, s_max;
        segmentt = obj?.segment + (obj?.isBase ? " (" + baseLabel + ")" : "");
        tempobj.push(segmentt);
        _.map(hcpStatureData, (sobj) => {
          _.map(obj?.hcpStatures, (o) => {
            if (sobj?.hcp_stature_id === o?.hcp_stature_id) {
              s_min = CheckSeparator(o?.min);
              s_mid = CheckSeparator(o?.mid);
              s_max = CheckSeparator(o?.max);
              if (showMinRange) {
                tempobj.push(s_min);
              }
              if (showMidRange) {
                tempobj.push(s_mid);
              }
              if (showMaxRange) {
                tempobj.push(s_max);
              }
            }
          });
        });
        HourlyRateCardDocData.push(tempobj);
      });
    }
    let headingsRow = [];
    headingsRow.push(hcpSpecialtySegmentsLabel);
    if (hcpStatureData?.length > 0) {
      _.map(hcpStatureData, (o) => {
        if (showMinRange) {
          headingsRow.push(minimumLabel);
        }
        if (showMidRange) {
          headingsRow.push(midLabel);
        }
        if (showMaxRange) {
          headingsRow.push(maximumLabel);
        }
      });
    }
    let HourlyRateCardbody = [];
    HourlyRateCardbody.push(headingsRow);
    if (HourlyRateCardDocData?.length > 0) {
      for (let i = 0; i < HourlyRateCardDocData.length; i++) {
        let row = [];
        for (let key in HourlyRateCardDocData[i]) {
          row.push(HourlyRateCardDocData[i][key]);
        }
        HourlyRateCardbody.push(row);
      }
    }
    let HourlyRateCardHead = [];
    HourlyRateCardHead.push({
      content: hcpKolMultipliersLabel,
      colSpan: 1,
      styles: { halign: "center", fillColor: "#EB1701", fontSize: 7 },
    });
    if (hcpStatureData?.length > 0) {
      _.map(hcpStatureData, (o) => {
        HourlyRateCardHead.push({
          content: o?.HCP_Stature,
          colSpan: statureColSpanPDF,
          styles: { halign: "center", fillColor: "#EB1701", fontSize: 7 },
        });
      });
    }

    let travelDistanceDocData = [];
    if (travelDistanceData?.length > 0) {
      travelDistanceDocData = _.map(travelDistanceData, (o) => {
        return {
          title: o?.title,
          time_hr: CheckSeparator(o?.time_hr),
          comments: o?.comments,
        };
      });
    }
    let travelDistancebody = [];
    if (travelDistanceDocData?.length > 0) {
      for (let i = 0; i < travelDistanceDocData.length; i++) {
        let row = [];
        for (let key in travelDistanceDocData[i]) {
          row.push(travelDistanceDocData[i][key]);
        }
        travelDistancebody.push(row);
      }
    }

    let activityTypesStatureData = [];
    if (activityTypesData?.length > 0) {
      activityTypesStatureData = _.map(activityTypesData, (o) => {
        return {
          Activity_Type: o?.Activity_Type,
          hcp_stature_hr_cap: CheckSeparator(o?.hcp_stature_hr_cap),
          hrs_nonkol: CheckSeparator(
            _.find(o?.statureCaps, (obj) => {
              return obj?.["HCP Stature"]?.toLowerCase()?.includes("non");
            })?.rate_cap
          ),
          hrs_localkol: CheckSeparator(
            _.find(o?.statureCaps, (obj) => {
              return obj?.["HCP Stature"]?.toLowerCase()?.includes("local");
            })?.rate_cap
          ),
          hrs_nationalkol: CheckSeparator(
            _.find(o?.statureCaps, (obj) => {
              return obj?.["HCP Stature"]?.includes("National");
            })?.rate_cap
          ),
          hrs_internationalkol: CheckSeparator(
            _.find(o?.statureCaps, (obj) => {
              return obj?.["HCP Stature"]?.includes("International");
            })?.rate_cap
          ),
          date_nonkol: CheckSeparator(
            _.find(o?.sessionCaps, (obj) => {
              return obj?.hcp_stature?.toLowerCase()?.includes("non");
            })?.session_cap
          ),
          date_localkol: CheckSeparator(
            _.find(o?.sessionCaps, (obj) => {
              return obj?.hcp_stature?.toLowerCase()?.includes("local");
            })?.session_cap
          ),
          date_nationalkol: CheckSeparator(
            _.find(o?.sessionCaps, (obj) => {
              return obj?.hcp_stature?.includes("National");
            })?.session_cap
          ),
          date_internationalkol: CheckSeparator(
            _.find(o?.sessionCaps, (obj) => {
              return obj?.hcp_stature?.includes("International");
            })?.session_cap
          ),
        };
      });
    }
    let activityTypesStaturebody = [];
    activityTypesStaturebody.push([
      activityTypeLabel,
      caphrsLabel,
      nonKolLabel,
      localKolLabel,
      nationalKolLabel,
      internationalKolLabel,
      nonKolLabel,
      localKolLabel,
      nationalKolLabel,
      internationalKolLabel,
    ]);
    if (activityTypesStatureData?.length > 0) {
      for (let i = 0; i < activityTypesStatureData.length; i++) {
        let row = [];
        for (let key in activityTypesStatureData[i]) {
          row.push(activityTypesStatureData[i][key]);
        }
        activityTypesStaturebody.push(row);
      }
    }

    let activityTypesDocData = [];
    if (activityTypesData?.length > 0) {
      activityTypesDocData = _.map(activityTypesData, (o) => {
        return {
          Activity_Type: o?.Activity_Type,
          service_time_hrs: CheckSeparator(o?.service_time_hrs),
          prep_time_hrs: CheckSeparator(o?.prep_time_hrs),
          comments: o?.comments,
        };
      });
    }
    let body = [];
    if (activityTypesDocData?.length > 0) {
      for (let i = 0; i < activityTypesDocData.length; i++) {
        let row = [];
        for (let key in activityTypesDocData[i]) {
          row.push(activityTypesDocData[i][key]);
        }
        body.push(row);
      }
    }
    let specialtyDocData = [];
    if (specialtyData?.length > 0) {
      specialtyDocData = _.map(specialtyData, (o) => {
        return {
          HCP_Specialty: o?.HCP_Specialty,
          segment:
            o?.segment +
            (o?.segment_id === segmentId ? " (" + baseLabel + ")" : ""),
        };
      });
    }
    let specialtyDatabody = [];
    if (specialtyDocData?.length > 0) {
      for (let i = 0; i < specialtyDocData.length; i++) {
        let row = [];
        for (let key in specialtyDocData[i]) {
          row.push(specialtyDocData[i][key]);
        }
        specialtyDatabody.push(row);
      }
    }

    var headerElement = document.getElementById("calculator-header-info");
    Promise.all([
      new Promise((success, fail) => {
        if (headerElement) {
          html2canvas(headerElement).then((canvas) => {
            success(canvas);
          });
        } else {
          success(null);
        }
      }),
    ]).then((canvases, other) => {
      const doc = new jsPDF();
      doc.addImage(jnjlogo, "PNG", 12, 4, 40, 7);
      if (isDraftVersion === true) {
        doc.setFontSize(13);
        doc.setTextColor("#EB1701");
        doc.text("DRAFT VERSION", doc.internal.pageSize.getWidth() / 2 - 16, 8);
        doc.setTextColor(0, 0, 0);
      }

      doc.setFontSize(12);
      //doc.setTextColor("#EB1701");
      doc.setTextColor("#EB1701");
      var splitTitle = doc.splitTextToSize(calcName, 90);
      var titleHeight =
        splitTitle && splitTitle.length > 0
          ? splitTitle.length === 1
            ? splitTitle.length * 17
            : splitTitle.length === 2
            ? splitTitle.length * 11
            : splitTitle.length === 3
            ? splitTitle.length * 9
            : splitTitle.length * 8
          : 8;

      doc.text(splitTitle, 14, 17);
      doc.setFontSize(7);
      doc.setTextColor(0, 100, 0);
      doc.text(
        "HCP FFS FMV Calculator " +
          (calculatorHeaderData !== " " ? calculatorHeaderData : "") +
          " - " +
          (isDraftVersion === true ? lastUpdatedLabel : lastPublishedLabel) +
          ": " +
          lastPublished,
        14,
        titleHeight + 5
      );
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
      doc.text(
        regionLabel +
          ": " +
          calcRegion +
          "  " +
          countryLabel +
          ": " +
          (contrySectorNameData?.country_name?.label !== ""
            ? contrySectorNameData?.country_name?.label
            : ""),
        doc.internal.pageSize.getWidth() - 14,
        16,
        "right"
      );
      // doc.text(sectorLabel + ": " + calcSectors, doc.internal.pageSize.getWidth() - 14, 19, "right");
      if (!isPreviewCalculatorData) {
        let sectorsToMap = _.map(
          _.filter(calculatorData?.data?.sectors, (o) => {
            return (
              o.sector.toLowerCase() !==
              contrySectorNameData?.sector_name?.label?.toLowerCase()
            );
          }),
          (obj) => {
            return obj.sector;
          }
        ).join(", ");
        if (sectorsToMap && sectorsToMap !== null && sectorsToMap !== "") {
          doc.setFontSize(7);
          let startX = doc.internal.pageSize.getWidth() - 14;
          const inputValue =
            sectorLabel +
            ": **" +
            contrySectorNameData?.sector_name?.label +
            "**, " +
            sectorsToMap;
          const reverseArray = inputValue.split("**");
          const arrayOfCustomText = reverseArray?.reverse();
          arrayOfCustomText.map((text, i) => {
            doc.setTextColor("#EB1701");
            if (i % 2 === 0) {
              doc.setTextColor(0, 0, 0);
            }
            doc.text(text, startX, 19, "right");
            startX = startX - doc.getStringUnitWidth(text) * 2.5;
            return text;
          });
        } else {
          doc.setFontSize(7);
          let startX = doc.internal.pageSize.getWidth() - 14;
          const inputValue =
            sectorLabel +
            ": **" +
            contrySectorNameData?.sector_name?.label +
            "**";
          const reverseArray = inputValue.split("**");
          const arrayOfCustomText = reverseArray?.reverse();
          arrayOfCustomText.map((text, i) => {
            doc.setTextColor("#EB1701");
            if (i % 2 === 0) {
              doc.setTextColor(0, 0, 0);
            }
            doc.text(text, startX, 19, "right");
            startX = startX - doc.getStringUnitWidth(text) * 2.5;
            return text;
          });
        }
      } else {
        doc.text(
          sectorLabel + ": " + calcSectors,
          doc.internal.pageSize.getWidth() - 14,
          19,
          "right"
        );
      }
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text("FMV Hourly Rate Card", 14, titleHeight + 16);
      doc.autoTable({
        startY: titleHeight + 22,
        bodyStyles: { fontSize: 8, fontStyle: "bold" },
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 50 },
          2: { cellWidth: 15 },
          3: { cellWidth: 25 },
        },
        body: [
          [
            {
              content: completedbyLabel,
            },
            {
              content: userName,
            },
            {
              content: "WWID",
            },
            {
              content: userWWID,
            },
            {
              content: referenceNumberLabel,
            },
            {
              content:
                referenceNumberData !== undefined ? referenceNumberData : "",
            },
          ],
        ],
        theme: "plain",
      });
      doc.setFontSize(8);
      doc.text(calculationInputsLabel, 14, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        columnStyles: { 0: { fontStyle: "bold", fontSize: 7 } },
        didParseCell: function (data) {
          if (data.row.index === 2) {
            data.cell.styles.fontStyle = "bold";
          }
          if (data.row.index === 4) {
            data.cell.styles.fontStyle = "bold";
          }
        },
        html: "#ratecard_calculaorinputs_id",
        theme: "grid",
        bodyStyles: { fontSize: 6 },
      });
      doc.setFontSize(8);
      doc.text(hcpFmvHourlyRateCardLabel, 14, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        columnStyles: { 0: { cellWidth: 20 } },
        bodyStyles: { fontSize: 6 },
        headStyles: {
          halign: "center",
          fontSize: 7,
          lineColor: [211, 211, 211],
          lineWidth: 0.1,
        },
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fillColor = "#EB1701";
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          }
        },
        head: [HourlyRateCardHead],
        body: HourlyRateCardbody,
        theme: "grid",
      });

      var splitTitleName = doc.splitTextToSize(
        "Company Ground Rules associated with HCP FFS FMV for " + calcName,
        180
      );

      let newGroundRulesData;
      if (groundRulesData?.[0]?.rule_desc) {
        let str = groundRulesData?.[0]?.rule_desc.split("");
        let arr = newGroundRule(str);
        newGroundRulesData = arr;
      } else {
        newGroundRulesData = "";
      }

    
      
      let table3 = document.createElement("table");

      if (newGroundRulesData === "") {
        table3.innerHTML = "<thead><tr><th>Ground Rules</th></tr></thead>";
      } else {
        table3.innerHTML =
          "<thead><tr><th>Ground Rules</th></tr></thead><tbody><tr><td>" +
          newGroundRulesData +
          "</td></tr></tbody>";
      }
     

      doc.setFontSize(8);
      doc.text(splitTitleName, 14, doc.lastAutoTable.finalY + 10);
     
      doc.autoTable({
        html: table3,
        headStyles: { fillColor: "#EB1701", fontSize: 7 },
        bodyStyles: { fontSize: 6 },

        startY:
          splitTitleName.length > 1
            ? doc.lastAutoTable.finalY + 20
            : doc.lastAutoTable.finalY + 15,
        theme: "grid",
      });

      //const table1 = GroundRulesTable1();
      var date = new Date();
      // var datesuffix = date.toDateString().substring(4).replaceAll(" ", "-");
      var dtProps = date.toDateString().substring(4).split(" ");
      var datesuffix = dtProps[2] + "-" + dtProps[0] + "-" + dtProps[1];

      var fileName =
        datesuffix + "_" + userID?.toUpperCase() + "_FMV_Hourly_Rate_Card.pdf";

      // doc.html(table3, {
      //   callback: function (doc) {
      //     doc.save(fileName);
      //   },
      // });

      doc.setFontSize(8);
      doc.text(hcpSegmentLookupLabel, 14, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        headStyles: { fillColor: "#EB1701", fontSize: 7 },
        bodyStyles: { fontSize: 6 },
        head: [
          [
            {
              content: specialtyLabel,
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
            {
              content: hcpSpecialtySegmentLabel,
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        body: specialtyDatabody,
        startY: doc.lastAutoTable.finalY + 15,
        theme: "grid",
      });
      doc.setFontSize(8);
      doc.text(activityTypesLabel, 14, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        columnStyles: { 0: { cellWidth: 35 } },
        bodyStyles: { fontSize: 6 },
        headStyles: {
          halign: "center",
          fillColor: "EB1701",
          fontSize: 7,
          lineColor: [211, 211, 211],
          lineWidth: 0.1,
        },
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fillColor = "#EB1701";
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          }
        },
        head: [
          [
            {
              content: activityTypeLabel,
              colSpan: 1,
              styles: { halign: "center", fillColor: "EB1701", fontSize: 7 },
            },
            {
              content: caphrsLabel,
              colSpan: 1,
              styles: { halign: "center", fillColor: "EB1701", fontSize: 7 },
            },
            {
              content: regulatoryCapsPerHcpStatureRateHrLabel,
              colSpan: 4,
              styles: { halign: "center", fillColor: "EB1701", fontSize: 7 },
            },
            {
              content: regulatoryCapsPerHcpStatureRateDayOrSessionLabel,
              colSpan: 4,
              styles: { halign: "center", fillColor: "EB1701", fontSize: 7 },
            },
          ],
        ],
        body: activityTypesStaturebody,
        theme: "grid",
      });
      doc.setFontSize(8);
      doc.text(travelTimeLabel, 14, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        columnStyles: { 0: { cellWidth: 90, fontStyle: "bold" } },
        bodyStyles: { fontSize: 6 },
        body: [
          [
            {
              content: travelTimeOfTheHourlyRateLabel,
              styles: {
                halign: "left",
                fillColor: "#EB1701",
                textColor: "#ffffff",
              },
            },
            {
              content: travelTimeDiscount + "%",
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 7,
        headStyles: { fillColor: "#EB1701", fontSize: 7 },
        bodyStyles: { fontSize: 6 },
        head: [
          [
            {
              content: activityLocationLabel + " (" + distanceLabel + ")",
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
            {
              content: countryConsensusLabel + " (" + hoursLabel + ")",
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
            {
              content: commentsLabel,
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        body: travelDistancebody,
        theme: "grid",
      });
      doc.setFontSize(8);
      doc.text(standardHoursLabel, 14, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        headStyles: { fillColor: "#EB1701", fontSize: 7 },
        bodyStyles: { fontSize: 6 },
        head: [
          [
            {
              content: activityTypeLabel,
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
            {
              content: serviceTimeLabel + " (" + hoursLabel + ")",
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
            {
              content: prepTimeLabel + " (" + hoursLabel + ")",
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
            {
              content: commentsLabel,
              colSpan: 1,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        body: body,
        theme: "grid",
      });

      var date = new Date();
      // var datesuffix = date.toDateString().substring(4).replaceAll(" ", "-");
      var dtProps = date.toDateString().substring(4).split(" ");
      var datesuffix = dtProps[2] + "-" + dtProps[0] + "-" + dtProps[1];

      var fileName =
        datesuffix + "_" + userID?.toUpperCase() + "_FMV_Hourly_Rate_Card.pdf";
      doc.save(fileName);
      dispatch(setIsExecutingAction(false));
    });

    document.getElementById("ratecard_calculaorinputs_id").style.display = "";
  };

  const onClickExpandAll = () => {
    if (!toggleExpandCollaps) {
      setToggleExpandCollaps(true);
      setExpandStandardHours(true);
      setExpandTravelDistance(true);
      setExpandActivityTypes(true);
      setExpandHCPSegmentLookup(true);
      setExpandCalculationInpts(true);
      setExpandHourlyRateCard(true);
      setExpandGroundRules(true);
    }
    if (toggleExpandCollaps) {
      setToggleExpandCollaps(false);
      setExpandStandardHours(false);
      setExpandTravelDistance(false);
      setExpandActivityTypes(false);
      setExpandHCPSegmentLookup(false);
      setExpandCalculationInpts(false);
      setExpandHourlyRateCard(false);
      setExpandGroundRules(false);
    }
  };

  return (
    <div>
      <div className="fmv-ratecard" id="div-fmv-ratecard">
        <Container>
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {completedbyLabel}
            </Col>
            <Col className="label-name" md={3}>
              {userName}
            </Col>
            <Col className="label-name" md={1}>
              WWID
            </Col>
            <Col className="label-name" md={2}>
              {userWWID}
            </Col>
            <Col className="label-name" md={2}>
              {referenceNumberLabel}
            </Col>
            {referenceNumberData !== undefined ? (
              <Col className="label-name" md={2}>
                {referenceNumberData}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <div className="expand_all_link" onClick={onClickExpandAll}>
              {!toggleExpandCollaps ? "Expand All" : "Collapse All"}
            </div>
          </Row>
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                {calculationInputsLabel}
              </span>
              <Image
                src={expandCalculationInpts ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() =>
                  setExpandCalculationInpts(!expandCalculationInpts)
                }
              ></Image>
            </Col>
          </Row>
          {expandCalculationInpts ? (
            <Row>
              <CalculationInptsTable></CalculationInptsTable>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <CalculationInptsTableForPDF></CalculationInptsTableForPDF>
          </Row>
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                {hcpFmvHourlyRateCardLabel}
              </span>
              <Image
                src={expandHourlyRateCard ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() => setExpandHourlyRateCard(!expandHourlyRateCard)}
              ></Image>
            </Col>
          </Row>
          {expandHourlyRateCard ? (
            <Row>
              <HourlyRateCardTable
                tableData={hourlyRateCardData}
              ></HourlyRateCardTable>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                Company Ground Rules associated with HCP FFS FMV for {calcName}
              </span>
              <Image
                src={expandGroundRules ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() => setExpandGroundRules(!expandGroundRules)}
              ></Image>
            </Col>
          </Row>
          {expandGroundRules ? (
           /*  <Row>
              <GroundRulesTable tableData={groundRulesData}></GroundRulesTable>
            </Row> */
            <Row>  
	     <table className="ground_rules_table">
        <tbody>
          <tr key={0} className="header">
            <td className="specialty_header left-padding">{rulesLabel}</td>
          </tr>
      
          
	  <tr>
	  <td>
       {/* <RenderRichText html={groundRulesData} /> */}
       <div dangerouslySetInnerHTML={{ __html: groundRulesData[0]?.rule_desc  }} />
       </td>
	  </tr>

                </tbody>
      </table>          
             
                      </Row>
          ) : (
            ""
          )}
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                {hcpSegmentLookupLabel}
              </span>
              <Image
                src={expandHCPSegmentLookup ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() =>
                  setExpandHCPSegmentLookup(!expandHCPSegmentLookup)
                }
              ></Image>
            </Col>
          </Row>
          {expandHCPSegmentLookup ? (
            <Row>
              <HCPSegmentLookupTable
                tableData={specialtyData}
              ></HCPSegmentLookupTable>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                {activityTypesLabel}
              </span>
              <Image
                src={expandActivityTypes ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() => setExpandActivityTypes(!expandActivityTypes)}
              ></Image>
            </Col>
          </Row>
          {expandActivityTypes ? (
            <Row>
              <ActivityTypesTable
                tableData={activityTypesData}
              ></ActivityTypesTable>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                {travelTimeLabel}
              </span>
              <Image
                src={expandTravelDistance ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() => setExpandTravelDistance(!expandTravelDistance)}
              ></Image>
            </Col>
          </Row>
          {expandTravelDistance ? (
            <>
              <Row>
                <table className="travel_discount">
                  <tbody>
                    <tr>
                      <td className="travel_discount_label left-padding">
                        {travelTimeOfTheHourlyRateLabel}
                      </td>
                      <td className="discount_percentage">
                        {travelTimeDiscount}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
              <Row>
                <TravelDistanceTable
                  tableData={travelDistanceData}
                ></TravelDistanceTable>
              </Row>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col className="calc-section-header" md={12}>
              <span className="calc-section-headerlabeltext">
                {standardHoursLabel}
              </span>
              <Image
                src={expandStandardHours ? condense : expand}
                className="expand-item"
                alt="Expand Section"
                onClick={() => setExpandStandardHours(!expandStandardHours)}
              ></Image>
            </Col>
          </Row>
          {expandStandardHours ? (
            <Row>
              <StandardHoursTable
                tableData={activityTypesData}
              ></StandardHoursTable>
            </Row>
          ) : (
            ""
          )}
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
        </Container>
      </div>
      <div className="calc-config-buttons">
        <Button
          variant="success"
          className="export-to-pdf"
          onClick={ExportRateCardToJsPDF}
        >
          EXPORT TO PDF
        </Button>
      </div>
    </div>
  );
}
