import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
// import Select from "react-select";
import _ from "lodash";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { fetchKolDashboardData } from "../actions/kolAssessment";
import { fetchMasterConfigData } from "../actions/masterDataConfig";
import { setIsExecutingAction } from "../actions/calcConfig";
import { ExportToPDF } from "../helper/exportToPDF";

export function FMVKOLAssessment() {
  const [userID, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [userWWID, setUserWWID] = useState("");
  const [hcpFullName, setHcpFullName] = useState("");
  // const [hcpIdentifier, setHCPIdentifier] = useState("");
  // const [hcpSpecialtiesList, setHCPSpecialtiesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [isDownloadPdfSelected, setIsDownloadPdfSelected] = useState(false);

  const [assessmentScore, setAssessmentScore] = useState({});
  const [assessmentFreeText, setAssessmentFreeText] = useState({});
  const [statureAssessed, setStatureAssessed] = useState("");

  const userInformation = useSelector(state => state.userInformation);
  const userInformationAuth = useSelector(state => state.userInformationAuth);
  const kolDashboardData = useSelector(
    state => state.kolAssessment.kolDashboardData
  );
  // const calculatorData = useSelector(state => state.calculator?.calculatorData);
  const hcpStatureMaster = useSelector(state => state.masterDataConfig);
  const isExecuting = useSelector(state => state.calcConfig.isExecuting);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKolDashboardData());
    dispatch(fetchMasterConfigData("hcp_statures"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInformationAuth && userInformationAuth?.data) {
      let name = (userInformationAuth?.data1.mail).split('@')[0];
      setUserID(name);
      setUserName(
        (
          userInformationAuth.data.users[0].givenNameFromAuth +
          " " +
          userInformationAuth.data.users[0].snFromAuth
        ).toUpperCase()
      );
      setUserWWID(userInformationAuth?.data1.employeeId);
    }
  }, []);

  /* useEffect(() => {
    if (calculatorData) {
      if (calculatorData?.data?.hcpSpecialty) {
        setHCPSpecialtiesList(
          _.map(calculatorData?.data?.hcpSpecialty, obj => {
            return { name: obj.hcp_speciality_id, label: obj.HCP_Specialty };
          })
        );
      }
    }
  }, [calculatorData]); */

  useEffect(() => {
    if (isExecuting && isDownloadPdfSelected) {
      document.getElementById("fmvassessment_hcpfullname_id").style.border =
        "none";
    /* document.getElementById("fmvassessment_hcpidentifier_id").style.border =
        "none";
       document.getElementById("fmvassessment_hcpspecialitycol_id").style.width =
        "39.33333333%"; */

      var els = document.getElementsByClassName("free_Question_Answer");
      _.forEach(els, function (obj) {
        obj.style.display = "none";
      });
      var eltext = document.getElementsByClassName("kol_ass_textarea_pdf");
      _.forEach(eltext, function (obj) {
        obj.style.display = "block";
      });

      ExportToPDF(
        "div-fmv-kolassessment",
        "FMV_KOL_Assessment",
        userID?.toUpperCase(),
        undefined,
        "HCP FMV KOL Assessment",
        dispatch,
        false
      );

      document.getElementById("fmvassessment_hcpfullname_id").style.border = "";
      /* document.getElementById("fmvassessment_hcpidentifier_id").style.border =
         "";
         document.getElementById("fmvassessment_hcpspecialitycol_id").style.width =
         ""; */
      var elss = document.getElementsByClassName("free_Question_Answer");
      _.forEach(elss, function (obj) {
        obj.style.display = "";
      });
      var elstext = document.getElementsByClassName("kol_ass_textarea_pdf");
      _.forEach(elstext, function (obj) {
        obj.style.display = "";
      });
      setIsDownloadPdfSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuting, isDownloadPdfSelected]);

  const FormatSection = () => {
    if (kolDashboardData?.data && kolDashboardData?.data.length > 0) {
      let allSections = kolDashboardData?.data.map((row, index) => {
        return (
          <div key={index}>
            <Row>
              <Col className="section-header" md={12}>
                C.{row.order}. {row.section}
              </Col>
            </Row>
            <Row>
              <AssessmentTable
                section={row.section}
                heading={row.heading}
                assessments={row.assessments}
              ></AssessmentTable>
            </Row>
          </div>
        );
      });
      return <div>{allSections}</div>;
    }
    return <div></div>;
  };

  const AssessmentTable = props => {
    let sectionID = "";
    let assessmentCount = 0;

    assessmentCount = props.assessments.length;

    const rows = props.assessments.map((row, index) => {
      sectionID = row.section_id;

      if (row.type.toUpperCase() === "YES/NO") {
        return (
          <tr key={index + 2} className="align-middle label-name">
            {assessmentCount === 1 ? <td>#</td> : <td>{row.order}</td>}
            <td className="align-left left-padding">{row.assessment}</td>
            <td>
              <DropdownTypeAssessment assessment={row}></DropdownTypeAssessment>
            </td>
            <td>
              {assessmentScore &&
              assessmentScore[row.section_id] &&
              assessmentScore[row.section_id][row.assessment_id]
                ? assessmentScore[row.section_id][row.assessment_id]
                : "0"}
            </td>
          </tr>
        );
      } else if (row.type.toUpperCase() === "FREE QUESTION") {
        return (
          <tr key={index + 2} className="align-middle label-name">
            <td></td>
            <td className="align-left left-padding">{row.assessment}</td>
            <td colspan="2">
              <FreeTextTypeAssessment assessment={row}></FreeTextTypeAssessment>
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={index + 2}>
            <td colSpan={4} className="options">
              <RadioTypeAssessment
                assessment={row}
                assessmentCount={assessmentCount}
              ></RadioTypeAssessment>
            </td>
          </tr>
        );
      }
    });
    return (
      <table className="assessment-table">
        <tbody>
          <tr key={0} className="table_header">
            <td className="assessment-order">#</td>
            <td className="left-padding assessment-title">{props.heading}</td>
            <td className="assessment-type">Select applicable answer</td>
            <td className="assessment-score">Score</td>
          </tr>
          {rows}
          <tr key={1} className="option-table-header">
            <td></td>
            <td className="left-padding">Total {props.section} Score</td>
            <td></td>
            <td className="align-middle">
              {assessmentCount > 0 ? CalculateScore(sectionID) : ""}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const FreeTextTypeAssessment = props => {
    return (
      <>
      <textarea
        className="free_Question_Answer"
        parentid={props.assessment.section_id}
        id={props.assessment.assessment_id}
        defaultValue={
          assessmentFreeText &&
          assessmentFreeText[props.assessment.section_id] &&
          assessmentFreeText[props.assessment.section_id][
            props.assessment.assessment_id
          ]
            ? assessmentFreeText[props.assessment.section_id][
                props.assessment.assessment_id
              ]
            : ""
        }
        onBlur={OnChangeOfFreeText}
        />
        <div className="kol_ass_textarea_pdf">
          {assessmentFreeText &&
            assessmentFreeText[props.assessment.section_id] &&
            assessmentFreeText[props.assessment.section_id][
            props.assessment.assessment_id
            ]
            ? assessmentFreeText[props.assessment.section_id][
            props.assessment.assessment_id
            ]
            : ""
          }
        </div>
      </>
    );
  };

  const OnChangeOfFreeText = e => {
    let sectionID = e.target.attributes["parentid"].value;
    let assessmentID = e.target.id;

    let currentVal = _.clone(assessmentFreeText);
    if (!currentVal[sectionID]) {
      currentVal[sectionID] = {};
    }
    currentVal[sectionID][assessmentID] = e.target.value;

    setAssessmentFreeText(currentVal);
  };

  const DropdownTypeAssessment = props => {
    let yScore = 1;
    let nScore = 0;

    if (
      _.find(props.assessment.options, o => {
        return o.option.toUpperCase() === "YES";
      })
    ) {
      yScore = _.find(props.assessment.options, o => {
        return o.option.toUpperCase() === "YES";
      }).score;
    }
    if (
      _.find(props.assessment.options, o => {
        return o.option.toUpperCase() === "NO";
      })
    ) {
      nScore = _.find(props.assessment.options, o => {
        return o.option.toUpperCase() === "NO";
      }).score;
    }
    return (
      <select
        className="assessment-select"
        parentid={props.assessment.section_id}
        id={props.assessment.assessment_id}
        value={
          assessmentScore &&
          assessmentScore[props.assessment.section_id] &&
          assessmentScore[props.assessment.section_id][
            props.assessment.assessment_id
          ]
            ? assessmentScore[props.assessment.section_id][
                props.assessment.assessment_id
              ]
            : "0"
        }
        onChange={OnSelectionChange}
      >
        <option value={yScore}>Y</option>
        <option value={nScore}>N</option>
      </select>
    );
  };

  const OnSelectionChange = e => {
    let sectionID = e.target.attributes["parentid"].value;
    let assessmentID = e.target.id;

    let currentVal = _.clone(assessmentScore);
    if (!currentVal[sectionID]) {
      currentVal[sectionID] = {};
    }
    currentVal[sectionID][assessmentID] = e.target.value;

    setAssessmentScore(currentVal);
  };

  const RadioTypeAssessment = props => {
    const rows = props.assessment.options.map((row, index) => {
      return (
        <tr
          key={index + 1}
          className={
            index === props.assessment.options?.length - 1
              ? "align-middle radiotablelastrow"
              : "align-middle"
          }
        >
          <td></td>
          <td className="align-left left-padding">{row.option}</td>
          <td>
            <input
              type="radio"
              name={row.assessment_id}
              id={row.assessment_id}
              parentid={props.assessment.section_id}
              onChange={OnRadioSelectionChange}
              checked={
                assessmentScore &&
                assessmentScore[props.assessment.section_id] &&
                assessmentScore[props.assessment.section_id][
                  props.assessment.assessment_id
                  // eslint-disable-next-line eqeqeq
                ] == row.score
                  ? true
                  : false
              }
              score={row.score}
              value={row.score}
            />
          </td>
          <td>
            {assessmentScore &&
            assessmentScore[props.assessment.section_id] &&
            assessmentScore[props.assessment.section_id][
              props.assessment.assessment_id
              // eslint-disable-next-line eqeqeq
            ] == row.score
              ? row.score
              : "0"}
          </td>
        </tr>
      );
    });
    return (
      <table className="option-table">
        <tbody>
          <tr
            key={0}
            className="option-table-header align-middle radiotablefirstrow"
          >
            {props.assessmentCount === 1 ? (
              <td className="option-header-order">#</td>
            ) : (
              <td className="option-header-order">{props.assessment.order}</td>
            )}
            <td className="option-header-option left-padding">
              {props.assessment.assessment}
            </td>
            <td className="option-header-radio"></td>
            <td className="option-header-score"></td>
          </tr>
          {rows}
        </tbody>
      </table>
    );
  };

  const OnRadioSelectionChange = e => {
    let sectionID = e.target.attributes["parentid"].value;
    let assessmentID = e.target.id;

    let currentVal = _.clone(assessmentScore);
    if (!currentVal[sectionID]) {
      currentVal[sectionID] = {};
    }
    currentVal[sectionID][assessmentID] = e.target.attributes["score"].value;

    setAssessmentScore(currentVal);
  };

  const FormatStatureOutput = () => {
    if (kolDashboardData?.data && kolDashboardData?.data.length > 0) {
      let allSections = kolDashboardData?.data.map((row, index) => {
        return (
          <tr key={index + 1} className="option-table-header no-background">
            <td className="output-order align-middle">C.{row.order}</td>
            <td className="output-title left-padding">
              Total {row.section} Score
            </td>
            <td className="output-score align-middle">
              {CalculateScore(row.id)}
            </td>
          </tr>
        );
      });
      return (
        <table className="output-score-table">
          <tbody>
            {allSections}
            <tr key={0} className="option-table-header">
              <td className="output-order"></td>
              <td className="output-title left-padding">Total Score</td>
              <td className="output-score align-middle">{CalculateScore()}</td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  const CalculateScore = sectionID => {
    let totalScore = 0;

    if (sectionID) {
      if (assessmentScore[sectionID]) {
        totalScore = _.sum(
          _.map(assessmentScore[sectionID], obj => {
            return parseInt(obj);
          })
        );
      }
    } else {
      _.forEach(_.keys(assessmentScore), secID => {
        totalScore += CalculateScore(secID);
      });
    }
    setStatureAssessed(DeriveKOLStature(totalScore));
    return totalScore;
  };

  const DeriveKOLStature = score => {
    let stature = "";

    if (hcpStatureMaster?.data) {
      let statureData = hcpStatureMaster?.data?.data;

      if (statureData) {
        _.forEach(statureData, obj => {
          if (score >= parseInt(obj.min) && score <= parseInt(obj.max)) {
            stature = obj.title;
            return stature;
          }
        });
        if (stature === "") {
          _.forEach(statureData, obj => {
            if (obj.title?.toLowerCase().includes("international")) {
              if (score > parseInt(obj.max)) {
                stature =
                  "In the event score outweighs max allowable value, refer to International KOL score guidelines.";
                return stature;
              }
            }
          });
        }
      }
    }
    return stature;
  };

  const FormatStatureGuidelines = () => {
    if (hcpStatureMaster?.data) {
      if(hcpStatureMaster?.data?.data?.length){
    //  console.log({"Key check" : hcpStatureMaster?.data?.data});
      let statureData = hcpStatureMaster?.data?.data;

      if (statureData && statureData.length > 0) {
        statureData = _.orderBy(statureData, "orders", "desc");

        let rows = statureData.map((row, index) => {
          return (
            <tr key={index} className="option-table-header no-background">
              <td className="result left-padding">{row.title}</td>
              <td className="result align-middle">
                {row.min} - {row.max}
              </td>
            </tr>
          );
        });
        return (
          <table className="output-score-table">
            <tbody>{rows}</tbody>
          </table>
        );
      }
    }
    else{return <></>}
    }
    else{return <></>}
  };

  const FormatResult = () => {
    return (
      <table className="output-score-table">
        <tbody>
          <tr key={0} className="option-table-header no-background">
            <td className="result left-padding">Total Score</td>
            <td className="result align-middle">{CalculateScore()}</td>
          </tr>
          <tr key={1} className="option-table-header no-background">
            <td className="result left-padding">
              Resulting KOL Stature Assessment
            </td>
            <td className="result align-middle">{statureAssessed}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const ExportKOLAssessmentToPDF = () => {
    setIsDownloadPdfSelected(true);
    dispatch(setIsExecutingAction(true));
  };

  const setHCPFullName = (value) => {
    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setHcpFullName(value);
    }
  };

  return (
    <div>
      <div className="fmv-kolassessment" id="div-fmv-kolassessment">
        <Container>
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              COMPLETED BY
            </Col>
            <Col className="label-name" md={3}>
              {userName}
            </Col>
            <Col className="label-name" md={1}>
              WWID
            </Col>
            <Col className="label-name" md={2}>
              {userWWID}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="calc-section-header" md={12}>
              Table A - Enter contact details of the HCP being assessed
            </Col>
          </Row>
          <Row>
            <Container className="hcp-detail">
              <div className="inner-detail">
                <Row>
                  <Col className="section-header" md={12}>
                    HCP Details
                  </Col>
                </Row>
                <Row className="data-row">
                  <Col className="label-name" md={3}>
                    Enter HCP full name
                  </Col>
                  <Col md={3}>
                    <input
                      id="fmvassessment_hcpfullname_id"
                      type="text"
                      value={hcpFullName}
                      onChange={e => setHCPFullName(e.target.value)}
                    />
                  </Col>
                </Row>
                {/* <Row className="data-row">
                  <Col className="label-name" md={3}>
                    Enter HCP identifier # (if applicable)
                  </Col>
                  <Col md={3}>
                    <input
                      id="fmvassessment_hcpidentifier_id"
                      type="text"
                      value={hcpIdentifier}
                      onChange={e => setHCPIdentifier(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="data-row">
                  <Col className="label-name" md={3}>
                    Select HCP Specialty
                  </Col>
                  <Col md={3} id="fmvassessment_hcpspecialitycol_id">
                    <Select
                      options={hcpSpecialtiesList}
                      onChange={e => setSelectedSpecialty(e.label)}
                      getOptionValue={option => option.name}
                    />
                  </Col>
                </Row> */}
              </div>
            </Container>
          </Row>
          <Row className="data-row"></Row>
        </Container>
        <Container>
          <Row>
            <Col className="calc-section-header" md={12}>
              Table B - KOL stature assessment
            </Col>
          </Row>
          {kolDashboardData && kolDashboardData?.data ? (
            <FormatSection></FormatSection>
          ) : (
            ""
          )}
          <Row className="data-row"></Row>
        </Container>
        <Container>
          <Row>
            <Col className="calc-section-header" md={12}>
              KOL Stature Output
            </Col>
          </Row>
          <Row>
            <Col className="section-header" md={12}>
              Total KOL Stature Score
            </Col>
          </Row>
          {kolDashboardData && kolDashboardData?.data ? (
            <Row>
              <FormatStatureOutput></FormatStatureOutput>
            </Row>
          ) : (
            ""
          )}
          <Row className="data-row"></Row>
        </Container>
        <Container>
          <Row>
            <Col className="calc-section-header" md={12}>
              KOL Stature Guidelines
            </Col>
          </Row>
          {hcpStatureMaster &&
          hcpStatureMaster?.data &&
          hcpStatureMaster?.data?.data ? (
            <Row>
              <FormatStatureGuidelines></FormatStatureGuidelines>
            </Row>
          ) : (
            ""
          )}
          <Row className="data-row"></Row>
        </Container>
        <Container>
          <Row>
            <Col className="calc-section-header" md={12}>
              RESULT
            </Col>
          </Row>
          <Row>
            <FormatResult></FormatResult>
          </Row>
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
        </Container>
      </div>
      <div className="calc-config-buttons">
        <Button
          variant="success"
          className="export-to-pdf"
          onClick={ExportKOLAssessmentToPDF}
        >
          EXPORT TO PDF
        </Button>
      </div>
    </div>
  );
}
