/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authentication/authConfig";
import { Route, Switch } from "react-router";
import { Container } from "react-bootstrap";
import _ from "lodash";
import { Home } from "./components/home";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Calculator } from "./components/calculator";
import { CalculatorDashboard } from "./components/calcDashboard";
import { CalculatorConfiguration } from "./components/calcConfiguration";
import { MasterDataConfiguration } from "./components/masterDataConfiguration";
import { PageConfiguration } from "./components/pageConfiguration";
import { UserRolesDashboard } from "./components/userRolesDashboard";
import { AccessDenied } from "./components/accessDenied";
import { RenderRichText } from "./helper/renderRichText";
import {
  fetchAppMessages,
  fetchApplicationMessagesData
} from "./actions/appMessages";
import { KolDashboard } from "./components/kolDashboard";
import { KOLAssessmentConfiguration } from "./components/kolAssessmentConfiguration";
import { LanguageTranslation } from "./components/languageTranslation";
import { CalcConfigHistory } from "./components/calcConfigHistory";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { getAuthToken } from "./authentication/authToken";


function App() {
  const isDev =
    process.env.REACT_APP_ENVIRONMENT &&
    process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "dev";
  const isAuthenticated = useIsAuthenticated();
  let { instance } = useMsal();
  const browserName = useRef("");
  const dispatch = useDispatch();

  const [initialize, setInitialize] = useState(false);
  const [message, setMessage] = useState("");

  const pageConfig = useSelector(state => state.appMessages.appMessagePages);
  const pageConfigData = useSelector(
    state => state.appMessages.pageAppMessageData
  );
  useEffect(()=>{
 if(isAuthenticated){
    const { graphToken } = getAuthToken();
    const bearer =
      `Bearer ${graphToken}`
    fetch("https://graph.microsoft.com/v1.0/me?$select=employeeId,displayName,givenName,surname,mail", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const res1 = await res.json();

        function getUserInformation(data) {
          return {
            type: "GET_USER_INFO_AUTH_USER_DETAILS",
            payload: data
          };
        }
        dispatch(getUserInformation(res1));
        
      })
      .catch((err) => {
        console.log({ err });
      });
 }
  },[isAuthenticated])
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT) {
      document.title = "FMV-" + process.env.REACT_APP_ENVIRONMENT;
    } else {
      document.title = "FMV";
    }
    if (
      window.location &&
      (window.location.search || "").toLowerCase().includes("logout=true")
    ) {
      //way to logout via query string
      instance.logout();
    }
  });

  useEffect(() => {
    if (!isAuthenticated) {
      setTimeout(() => {
        if (instance.getAllAccounts().length === 0) {
          instance
            .handleRedirectPromise()
            .then(_response => {
              console.log(_response);
            })
            .catch(err => {
              console.error(err);
            });

          if (!localStorage["redirectURI"]) {
            localStorage["redirectURI"] = window.location.pathname;
          }
          instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
          });
        }
      }, 500);
      detectBrowser();
    } else {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      const userName = instance.getAllAccounts()[0].username;
      window.msalInstance = instance;
      window.msalUserName = userName;

      if (localStorage["redirectURI"] && localStorage["redirectURI"] !== "") {
        let redirectURI = localStorage["redirectURI"];
        localStorage.removeItem("redirectURI");
        window.location.pathname = redirectURI;
      }

      // Set auto token renewal call on each 5 min
      setInterval(() => {
        let config = {
          scopes: loginRequest.scopes,
          authority: msalConfig.auth.authority,
          account: instance.getAllAccounts()[0]
        };
        let redRequest = {
          scopes: loginRequest.scopes,
          loginHint: userName
        };
        instance
          .acquireTokenSilent(config)
          .then(
            res => {
              console.log("Token Renewed... ", isDev ? res : {});
            },
            err => {
              if (err instanceof InteractionRequiredAuthError) {
                return instance.acquireTokenRedirect(redRequest);
              }
            }
          )
          .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
              return instance.acquireTokenRedirect(redRequest);
            }
          });
      }, 5 * 60 * 1000);

      setTimeout(() => {
        window.location.hash = "";
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    // NOSONAR
    if (!initialize && isAuthenticated) {
      dispatch(fetchAppMessages());
    }
    setInitialize(true);
  }, [initialize, isAuthenticated]); // NOSONAR

  useEffect(() => {
    if (pageConfig !== undefined) {
      let AppMessagePageInfo = _.find(
        pageConfig?.data,
        o => {
          return o.page_name.trim().toLowerCase() === "application messages";
        },
        0
      );

      if (AppMessagePageInfo !== undefined) {
        dispatch(fetchApplicationMessagesData(AppMessagePageInfo.id));
      }
    }
  }, [pageConfig]);

  useEffect(() => {
    if (pageConfigData !== undefined) {
      let pagecontent = _.find(
        pageConfigData?.data,
        o => {
          return (
            o.content_type.trim().toLowerCase() ===
            "browser compatibility message"
          );
        },
        0
      );

      if (pagecontent !== undefined) {
        setMessage(pagecontent.contents);
      }
    }
  }, [pageConfigData]);

  function detectBrowser() {
    if (navigator.userAgent.indexOf("Edg") !== -1) {
      browserName.current = "Edg";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      browserName.current = "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      browserName.current = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      browserName.current = "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      !!document.documentMode === true
    ) {
      browserName.current = "IE";
    } else if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      browserName.current = "Opera";
    } else {
      browserName.current = "Unknown";
    }
  }

  return (
    <ErrorBoundary>
      <div>
        {isAuthenticated ? (
          <div className="main-layout">
            {browserName.current === "Chrome" ||
            browserName.current === "Edg" ||
            browserName.current === "Safari" ? (
              ""
            ) : (
              <div className="browser-support-msg">
                <RenderRichText html={message} />
              </div>
            )}
            <Header></Header>
            <Container className="minContainer">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/fmvcalculator/:preview?/:param1?/:param2?"
                  component={Calculator}
                />
                <Route
                  exact
                  path="/calculatorconfig/:calculatorid?/:version?"
                  component={CalculatorConfiguration}
                />
                <Route
                  exact
                  path="/calculatordashboard"
                  component={CalculatorDashboard}
                />
                <Route
                  exact
                  path="/masterdata/:listname?"
                  component={MasterDataConfiguration}
                />
                <Route exact path="/pageconfig" component={PageConfiguration} />
                <Route exact path="/UserRoles" component={UserRolesDashboard} />
                <Route exact path="/accessdenied" component={AccessDenied} />
                <Route exact path="/kolassessments" component={KolDashboard} />
                <Route
                  exact
                  path="/kolassessmentconfig/:itemtype?/:posttype?/:itemid?"
                  component={KOLAssessmentConfiguration}
                />
                <Route
                  exact
                  path="/languagetranslation"
                  component={LanguageTranslation}
                />
                <Route
                  exact
                  path="/calculatorhistory/:calcconfigid?"
                  component={CalcConfigHistory}
                />
              </Switch>
            </Container>
            <Footer></Footer>
          </div>
        ) : (
          <div>Please Wait...</div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default App;
