import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import { RenderRichText } from "../helper/renderRichText";
import { fetchMasterData, setIsExecutingAction } from "../actions/calcConfig";
import { setReferenceNumberData } from "../actions/rateCard";
import { UpdateEndUserActivity } from "../actions/endUserActivity";
import { ExportToPDF } from "../helper/exportToPDF";
import jnjlogo from "../assets/jnj-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import Nirmala from "../assets/fonts/NirmalaB.ttf";
import YuGothic from "../assets/fonts/YuGothicUI.ttf";
import JhenGhei from "../assets/fonts/JhengHeiUI.ttf";
import Segui from "../assets/fonts/seguisb.ttf";
import { fetchMasterConfigData } from "../actions/masterDataConfig";

let error = false;
export function FMVCalculator({
  globalCapMessage,
  countryId,
  sectorId,
  regionId,
}) {
  const monthsList = useRef([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const [userID, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [userWWID, setUserWWID] = useState("");

  const [refCategoryList, setRefCategoryList] = useState([]);
  const [referenceCategory, setReferenceCategory] = useState("");
  const [referenceCategoryValue, setReferenceCategoryValue] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");

  const [baseRate, setBaseRate] = useState("");
  const [minRange, setMinRange] = useState("");
  const [maxRange, setMaxRange] = useState("");
  const [roundOf, setRoundOf] = useState("");
  const [currency, setCurrency] = useState("");
  const [locale, setLocale] = useState("en-US");
  const [hourCap, setHourCap] = useState();
  const [mostRestrictiveHourCap, setMostRestrictiveHourCap] = useState();
  const [rateCap, setRateCap] = useState();
  const [mostRestrictiveRateCap, setMostRestrictiveRateCap] = useState();
  const [sessionCap, setSessionCap] = useState();
  const [mostRestrictiveSessionCap, setMostRestrictiveSessionCap] = useState();
  const [activityHourCap, setActivityHourCap] = useState(0);
  const [activityRateCap, setActivityRateCap] = useState(0);
  const [activitySessionCap, setActivitySessionCap] = useState(0);
  const [hcpSpecialtiesList, setHCPSpecialtiesList] = useState([]);
  const [hcpSpecialty, setHCPSpecialty] = useState("");
  const [hcpSpecialtyMultiplier, setHCPSpecialtyMultiplier] = useState("");
  const [hcpStaturesList, setHCPStaturesList] = useState([]);
  const [hcpStature, setHCPStature] = useState("");
  const [hcpStatureMultiplier, setHCPStatureMultiplier] = useState("");
  const [activityTypesList, setActivityTypesList] = useState([]);
  const [activityType, setActivityType] = useState("");
  const [travelDistancesList, setTravelDistancesList] = useState([]);
  const [travelDistance, setTravelDistance] = useState("");
  const [isRoundTrip, setIsRoundTrip] = useState("");
  const [stndServiceTime, setStndServiceTime] = useState(0);
  const [overrideServiceTime, setOverrideServiceTime] = useState("");
  const [stndPrepTime, setStndPrepTime] = useState(0);
  const [overridePrepTime, setOverridePrepTime] = useState("");
  const [selectedTravelHr, setSelectedTravelHr] = useState(0);
  const [stndTravelTime, setStndTravelTime] = useState(0);
  const [overrideTravelTime, setOverrideTravelTime] = useState("");
  const [overrideService, setOverrideService] = useState(false);
  const [overridePrep, setOverridePrep] = useState(false);
  const [overrideTravel, setOverrideTravel] = useState(false);
  const [disableOverrideTravel, setDisableOverrideTravel] = useState(false);
  const [showExportPDFNote, setShowExportPDFNote] = useState(false);
  const [calcRegion, setCalcRegion] = useState("");
  const [sectorLabel, setSectorLabel] = useState("Sector");
  const [regionLabel, setRegionLabel] = useState("Region");
  const [countryLabel, setCountryLabel] = useState("Country");
  const [calcSectors, setCalcSectors] = useState("");
  const [lastPublishedLabel, setLastPublishedLabel] =
    useState("Last Published On");
  const [lastUpdatedLabel, setLastUpdatedLabel] = useState("Last Updated On");
  const [lastPublished, setLastPublished] = useState("");
  const [
    determineTraveldistanceLinkLabel,
    setDetermineTraveldistanceLinkLabel,
  ] = useState("Click here for recommended site to determine travel distance");
  const [serviceTimeOverrideComment, setServiceTimeOverrideComment] =
    useState("");
  const [prepTimeOverrideComment, setPrepTimeOverrideComment] = useState("");
  const [travelTimeOverrideComment, setTravelTimeOverrideComment] =
    useState("");
  const [travelTimeDiscount, setTravelTimeDiscount] = useState("");
  const [hcpSpecialtyValue, setHCPSpecialtyValue] = useState("");
  const [hcpStatureValue, setHCPStatureValue] = useState("");
  const [activityTypeValue, setActivityTypeValue] = useState("");
  const [travelDistanceValue, setTravelDistanceValue] = useState("");
  const [isRoundTripValue, setIsRoundTripValue] = useState("");
  const [endUserActivityDataToProcess, setEndUserActivityDataToProcess] =
    useState([]);
  const [isAllUserActivityValueSelected, setIsAllUserActivityValueSelected] =
    useState(false);
  const [isExportToPDFActivityCaptured, setIsExportToPDFActivityCaptured] =
    useState(false);

  const [referenceValidation, setReferenceValidation] = useState(false);
  const [serviceTimeValidation, setServiceTimeValidation] = useState(false);
  const [serviceCommentValidation, setServiceCommentValidation] =
    useState(false);
  const [prepTimeValidation, setPrepTimeValidation] = useState(false);
  const [prepCommentValidation, setPrepCommentValidation] = useState(false);
  const [travelTimeValidation, setTravelTimeValidation] = useState(false);
  const [travelTimeMaxValidation, setTravelTimeMaxValidation] = useState(false);
  const [travelCommentValidation, setTravelCommentValidation] = useState(false);
  const [isDownloadcalcPdfSelected, setIsDownloadcalcPdfSelected] =
    useState(false);
  const [isDraftVersion, setIsDrftVersion] = useState(false);

  const [hourlyRate, setHourlyRate] = useState("");
  const [minHourlyRate, setMinHourlyRate] = useState("");
  const [maxHourlyRate, setMaxHourlyRate] = useState("");
  const [totalHours, setTotalHours] = useState("");
  const [totalCompensation, setTotalCompensation] = useState("");
  const [minTotalCompensation, setMinTotalCompensation] = useState("");
  const [maxTotalCompensation, setMaxTotalCompensation] = useState("");
  const [travelCost, setTravelCost] = useState("");
  const [minTravelCost, setMinTravelCost] = useState("");
  const [maxTravelCost, setMaxTravelCost] = useState("");
  const [serviceCost, setServiceCost] = useState("");
  const [minServiceCost, setMinServiceCost] = useState("");
  const [maxServiceCost, setMaxServiceCost] = useState("");
  const [eventCost, setEventCost] = useState("");
  const [minEventCost, setMinEventCost] = useState("");
  const [maxEventCost, setMaxEventCost] = useState("");
  const [prepCost, setPrepCost] = useState("");
  const [minPrepCost, setMinPrepCost] = useState("");
  const [maxPrepCost, setMaxPrepCost] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [minTotalCost, setMinTotalCost] = useState("");
  const [maxTotalCost, setMaxTotalCost] = useState("");
  const [totalCostExclPrep, setTotalCostExclPrep] = useState("");
  const [minTotalCostExclPrep, setMinTotalCostExclPrep] = useState("");
  const [maxTotalCostExclPrep, setMaxTotalCostExclPrep] = useState("");
  const [totalCostExclTravel, setTotalCostExclTravel] = useState("");
  const [minTotalCostExclTravel, setMinTotalCostExclTravel] = useState("");
  const [maxTotalCostExclTravel, setMaxTotalCostExclTravel] = useState("");

  const [actualTotalHours, setActualTotalHours] = useState("");
  const [actualHourlyRate, setActualHourlyRate] = useState("");
  const [actualMinHourlyRate, setActualMinHourlyRate] = useState("");
  const [actualMaxHourlyRate, setActualMaxHourlyRate] = useState("");
  const [actualTravelCost, setActualTravelCost] = useState("");
  const [actualServiceCost, setActualServiceCost] = useState("");
  const [actualPrepCost, setActualPrepCost] = useState("");
  const [actualTotalCost, setActualTotalCost] = useState("");
  const [minActualTotalCost, setMinActualTotalCost] = useState("");
  const [maxActualTotalCost, setMaxActualTotalCost] = useState("");

  const [specialtyActivityData, setSpecialtyActivityData] = useState([]);
  const [statureActivityData, setStatureActivityData] = useState([]);
  const [activityTypeActivityData, setActivityTypeActivityData] = useState([]);

  const [hourlyRateCapApplied, setHourlyRateCapApplied] = useState(false);
  const [isHourlyRateCapped, setIsHourlyRateCapped] = useState(false);
  const [isMinHourlyRateCapped, setIsMinHourlyRateCapped] = useState(false);
  const [isMaxHourlyRateCapped, setIsMaxHourlyRateCapped] = useState(false);
  const [hourCapApplied, setHourCapApplied] = useState(false);
  const [sessionCapApplied, setSessionCapApplied] = useState(false);
  const [isTotalCompensationCapped, setIsTotalCompensationCapped] =
    useState(false);
  const [isMinCompensationCapped, setIsMinCompensationCapped] = useState(false);
  const [isMaxCompensationCapped, setIsMaxCompensationCapped] = useState(false);
  const [showCapExceededMssg, setShowCapExceededMssg] = useState(false);

  const [calcName, setCalcName] = useState("");
  const [calcId, setCalcId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showMinRange, setShowMinRange] = useState(false);
  const [showMidRange, setShowMidRange] = useState(false);
  const [showMaxRange, setShowMaxRange] = useState(false);
  const [hideSpecialityMultiplier, setHideSpecialityMultiplier] =
    useState(false);
  const [hideStatureMultiplier, setHideStatureMultiplier] = useState(false);
  const [hideJspdfButton, setHideJspdfButton] = useState(false);
  const [sessionUuid, setSessionUuid] = useState("");

  const [completedbyLabel, setcompletedbyLabel] = useState("COMPLETED BY");
  const [referenceNumberLabel, setreferenceNumberLabel] =
    useState("Reference Number");
  const [referenceCategoryLabel, setreferenceCategoryLabel] =
    useState("Reference Category");
  const [
    pleaseSelectReferenceCategoryLabel,
    setpleaseSelectReferenceCategoryLabel,
  ] = useState("Please Select Reference Category");
  const [calculationInputsLabel, setcalculationInputsLabel] =
    useState("CALCULATION INPUTS");
  const [baseRateLabel, setbaseRateLabel] = useState("Base Rate");
  const [ratePerHourCapLabel, setratePerHourCapLabel] =
    useState("Rate per hour Cap");
  const [currencyLabel, setcurrencyLabel] = useState("Currency");
  const [sessionCapLabel, setsessionCapLabel] = useState("Session Cap");
  const [hcpSpecialtyLabel, sethcpSpecialtyLabel] = useState("HCP Specialty");
  const [specialtyMultiplierLabel, setspecialtyMultiplierLabel] = useState(
    "Specialty Multiplier"
  );
  const [
    selectTheFollowingAfterHcpSpecialtyIsChosenLabel,
    setselectTheFollowingAfterHcpSpecialtyIsChosenLabel,
  ] = useState("Select the following after HCP Specialty is chosen");
  const [hcpStatureKolStatusLabel, sethcpStatureKolStatusLabel] = useState(
    "HCP Stature (KOL Status)"
  );
  const [kolMultiplierLabel, setkolMultiplierLabel] =
    useState("KOL Multiplier");
  const [activityTypeLabel, setactivityTypeLabel] = useState("Activity Type");
  const [travelDistanceOneWayLabel, settravelDistanceOneWayLabel] = useState(
    "Travel Distance (one-way)"
  );
  const [IsTravelRoundTripLabel, setIsTravelRoundTripLabel] = useState(
    "Is travel - round trip?"
  );
  const [selectHoursLabel, setselectHoursLabel] = useState("Select Hours");
  const [typeLabel, settypeLabel] = useState("Type");
  const [standardHoursLabel, setstandardHoursLabel] =
    useState("Standard hours");
  const [overrideHoursLabel, setoverrideHoursLabel] =
    useState("Override hours");
  const [activityTypeServiceTimeLabel, setactivityTypeServiceTimeLabel] =
    useState("Activity type - service time");
  const [activityTypePrepTimeLabel, setactivityTypePrepTimeLabel] = useState(
    "Activity type - prep time"
  );
  const [activityLocationTravelTimeLabel, setactivityLocationTravelTimeLabel] =
    useState("Activity location - travel time");
  const [travelTimeOfHourlyRateLabel, settravelTimeOfHourlyRateLabel] =
    useState("Travel time - % of hourly rate");
  const [overrideLabel, setoverrideLabel] = useState("Override");
  const [
    pleaseEnterCommentsForOverridingServiceTimeLabel,
    setpleaseEnterCommentsForOverridingServiceTimeLabel,
  ] = useState("Please enter comments for overriding service time");
  const [
    pleaseEnterCommentsForOverridingPrepTimeLabel,
    setpleaseEnterCommentsForOverridingPrepTimeLabel,
  ] = useState("Please enter comments for overriding prep time");
  const [
    pleaseEnterCommentsForOverridingTravelTimeLabel,
    setpleaseEnterCommentsForOverridingTravelTimeLabel,
  ] = useState("Please enter comments for overriding travel time");
  const [pleaseEnterCommentsLabel, setpleaseEnterCommentsLabel] = useState(
    "Please enter comments"
  );
  const [onlyNumbersAreAllowedLabel, setonlyNumbersAreAllowedLabel] = useState(
    "Only numbers are allowed"
  );
  const [travelTimeMaxValidationMsgLabel, settravelTimeMaxValidationMsgLabel] =
    useState(
      "Override travel hour cannot exceed maximum travel time for the selected travel threshold"
    );
  const [calculatedfmvrangeLabel, setcalculatedfmvrangeLabel] = useState(
    "CALCULATED FMV RANGE"
  );
  const [minimumRateLabel, setminimumRateLabel] = useState("Minimum Rate");
  const [midLabel, setmidLabel] = useState("Mid");
  const [maximumRateLabel, setmaximumRateLabel] = useState("Maximum Rate");
  const [minimumLabel, setminimumLabel] = useState("Minimum");
  const [maximumLabel, setmaximumLabel] = useState("Maximum");
  const [fmvRatePerHourRangeLabel, setfmvRatePerHourRangeLabel] = useState(
    "FMV rate per hour range"
  );
  const [fmvTotalCompensationLabel, setfmvTotalCompensationLabel] = useState(
    "FMV - Total Compensation"
  );
  const [calculationDetailsLabel, setcalculationDetailsLabel] = useState(
    "Calculation Details"
  );
  const [localCurrencyLabel, setlocalCurrencyLabel] =
    useState("Local currency");
  const [totalTimeHrsLabel, settotalTimeHrsLabel] =
    useState("Total Time (hrs)");
  const [baseRateInLocalCurrencyLabel, setbaseRateInLocalCurrencyLabel] =
    useState("Base rate (in local currency)");
  const [hourlyCapHrsLabel, sethourlyCapHrsLabel] =
    useState("Hourly Cap (hrs)");
  const [serviceTimeHrsLabel, setserviceTimeHrsLabel] =
    useState("Service Time (hrs)");
  const [adjServiceTimeHrsLabel, setadjServiceTimeHrsLabel] = useState(
    "Adj Service Time (hrs)"
  );
  const [prepTimeHrsLabel, setprepTimeHrsLabel] = useState("Prep Time (hrs)");
  const [adjPrepTimeHrsLabel, setadjPrepTimeHrsLabel] = useState(
    "Adj Prep Time (hrs)"
  );
  const [travelTimeHrsLabel, settravelTimeHrsLabel] =
    useState("Travel Time (hrs)");
  const [adjTravelTimeHrsLabel, setadjTravelTimeHrsLabel] = useState(
    "Adj Travel Time (hrs)"
  );
  const [fmvRatePerHourLabel, setfmvRatePerHourLabel] =
    useState("FMV rate per hour");
  const [hcpSpecialtyMultiplierLabel, sethcpSpecialtyMultiplierLabel] =
    useState("HCP specialty multiplier");
  const [hcpStatureMultiplierLabel, sethcpStatureMultiplierLabel] = useState(
    "HCP stature multiplier"
  );
  const [calculatedRatePerHourLabel, setcalculatedRatePerHourLabel] = useState(
    "Calculated rate per hour"
  );
  const [minRangeLabel, setminRangeLabel] = useState("Min range");
  const [maxRangeLabel, setmaxRangeLabel] = useState("Max range");
  const [totalFeePerActivityLabel, settotalFeePerActivityLabel] = useState(
    "Total fee per activity"
  );
  const [minimumFeeLabel, setminimumFeeLabel] = useState("Minimum fee");
  const [maximumFeeLabel, setmaximumFeeLabel] = useState("Maximum fee");
  const [travelTimeLabel, settravelTimeLabel] = useState("Travel Time");
  const [serviceTimeLabel, setserviceTimeLabel] = useState("Service Time");
  const [hoursLabel, sethoursLabel] = useState("hours");
  const [costOfEventLabel, setcostOfEventLabel] = useState("Cost of event");
  const [prepTimeCostLabel, setprepTimeCostLabel] = useState("Prep Time Cost");
  const [totalCostLabel, settotalCostLabel] = useState("Total cost");
  const [totalCostExclPrepTimeLabel, settotalCostExclPrepTimeLabel] = useState(
    "Total cost excl. prep time"
  );
  const [totalCostExclTravelTimeLabel, settotalCostExclTravelTimeLabel] =
    useState("Total cost excl. travel time");
  const [
    calculatedCompensationWithoutCapsLabel,
    setcalculatedCompensationWithoutCapsLabel,
  ] = useState("CALCULATED COMPENSATION WITHOUT CAPS");
  const [memoInformationPurposeOnlyLabel, setmemoInformationPurposeOnlyLabel] =
    useState("MEMO (INFORMATION PURPOSE ONLY)");
  const [timeLabel, settimeLabel] = useState("Time");
  const [ratePerHourLabel, setratePerHourLabel] = useState("Rate per hour");
  const [travelLabel, settravelLabel] = useState("Travel");
  const [serviceLabel, setserviceLabel] = useState("Service");
  const [preparationLabel, setpreparationLabel] = useState("Preparation");
  const [totalLabel, settotalLabel] = useState("Total");
  const [commentsLabel, setcommentsLabel] = useState("Comments");

  //selectors
  const userInformation = useSelector((state) => state.userInformation);
  const userInformationAuth = useSelector((state) => state.userInformationAuth);
  const refCategoryMaster = useSelector(
    (state) => state.calcConfig?.referenceCategoryMasterData
  );
  const calculatorData = useSelector(
    (state) => state.calculator?.calculatorData
  );

  const selectedTransLanguage = useSelector(
    (state) => state.calculator?.selectedTransLanguage
  );
  const languageTranslationData = useSelector(
    (state) => state.calculator?.selectedTranslationData
  );
  const contrySectorNameData = useSelector(
    (state) => state.calculator?.contrySectorNameData
  );
  const isPreviewCalculatorData = useSelector(
    (state) => state.calculator?.isPreviewCalculatorData
  );
  const isExecuting = useSelector((state) => state.calcConfig.isExecuting);
  const draftVersionStatusData = useSelector(
    (state) => state.calculator?.draftVersionStatusData
  );
  const sessionId = useSelector(
    (state) => state.endUserActivity.endUserSessionUuid
  );
  const calculatorHeaderData = useSelector(
    (state) => state.calculator?.calculatorHeaderData
  );

  let { preview } = useParams();

  const dispatch = useDispatch();

  //travel Time concept
  const [travelTime, setTravelTime] = useState("");
  const masterData = useSelector((state) => state.masterDataConfig);

  useEffect(() => {
    dispatch(fetchMasterConfigData("travel_time"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      masterData &&
      countryId &&
      sectorId &&
      regionId &&
      activityType &&
      travelDistance
    ) {
      let inside = false;
      masterData.data.data.forEach((master) => {
        if (
          master.activity_type_id === activityType &&
          master.travel_distance_id === travelDistance &&
          master.region_id === regionId &&
          master.sector_id === sectorId &&
          master.country_id === countryId
        ) {
          inside = true;
          setTravelTime(master.value);
        }
      });
      if (!inside) {
        setTravelTime("");
      }
    }
  }, [masterData, countryId, sectorId, regionId, activityType, travelDistance]);

  useEffect(() => {
    dispatch(fetchMasterData("reference_categories"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //userInformation
  useEffect(() => {
    if (userInformationAuth && userInformationAuth?.data) {
      let name = (userInformationAuth?.data1.mail).split('@')[0];
      setUserID(name);
      setUserName(
        (
          userInformationAuth.data.users[0].givenNameFromAuth +
          " " +
          userInformationAuth.data.users[0].snFromAuth
        ).toUpperCase()
      );
      setUserWWID(userInformationAuth?.data1.employeeId);
      setUserEmail(userInformationAuth.data.users[0].userID);
    }
  }, [userInformationAuth]);

  useEffect(() => {
    if (calculatorData) {
      if (calculatorData?.data?.basic) {
        setCalcName(calculatorData?.data?.basic?.name);
        setCalcId(calculatorData?.data?.basic?.id);
        setBaseRate(calculatorData?.data?.basic.base_rate);
        setMinRange(calculatorData?.data?.basic.min_range_percentage);
        setMaxRange(calculatorData?.data?.basic.max_range_percentage);
        setRoundOf(calculatorData?.data?.basic.round_of);
        setCurrency(calculatorData?.data?.basic.currency);
        setCalcRegion(calculatorData?.data?.basic?.region);

        if (calculatorData?.data?.basic?.last_updated_date) {
          let date = new Date(calculatorData?.data?.basic?.last_updated_date);
          setLastPublished(
            monthsList.current[date.getMonth()] +
              " " +
              date.getDate() +
              ", " +
              date.getFullYear()
          );
        }

        let decimalPointer = calculatorData?.data?.basic.punc_decimal_pointer;
        let thousandPointer = calculatorData?.data?.basic.punc_thousand_pointer;

        if (
          decimalPointer &&
          decimalPointer !== null &&
          thousandPointer &&
          thousandPointer !== null
        ) {
          setLocale(
            decimalPointer.toLowerCase() === "point(.)"
              ? "en-US"
              : thousandPointer.toLowerCase() === "point(.)"
              ? "de"
              : "fr-FR"
          );
        }

        if (calculatorData?.data?.basic?.hide_speciality_multiplier === true) {
          setHideSpecialityMultiplier(true);
        } else {
          setHideSpecialityMultiplier(false);
        }
        if (calculatorData?.data?.basic?.hide_stature_multiplier === true) {
          setHideStatureMultiplier(true);
        } else {
          setHideStatureMultiplier(false);
        }
        if (
          calculatorData?.data?.basic?.ranges_applicable &&
          calculatorData?.data?.basic?.ranges_applicable !== undefined &&
          calculatorData?.data?.basic?.ranges_applicable !== ""
        ) {
          let rangedata = JSON.parse(
            calculatorData?.data?.basic?.ranges_applicable
          );
          if (rangedata?.length === 0) {
            setShowMinRange(true);
            setShowMidRange(true);
            setShowMaxRange(true);
          } else if (rangedata?.length > 0) {
            let mincheck = false,
              midcheck = false,
              maxcheck = false;
            _.map(rangedata, (o) => {
              if (o?.toLowerCase() === "min") {
                mincheck = true;
              }
              if (o?.toLowerCase() === "mid") {
                midcheck = true;
              }
              if (o?.toLowerCase() === "max") {
                maxcheck = true;
              }
            });
            if (mincheck) {
              setShowMinRange(true);
            } else {
              setShowMinRange(false);
            }
            if (midcheck) {
              setShowMidRange(true);
            } else {
              setShowMidRange(false);
            }
            if (maxcheck) {
              setShowMaxRange(true);
            } else {
              setShowMaxRange(false);
            }
          }
        } else if (calculatorData?.data?.basic?.ranges_applicable === null) {
          setShowMinRange(true);
          setShowMidRange(true);
          setShowMaxRange(true);
        }
      }
      if (calculatorData?.data?.sectors) {
        setCalcSectors(
          _.map(calculatorData?.data?.sectors, (o) => {
            return o.sector;
          }).join(", ")
        );
      }
      if (calculatorData?.data?.countrySpecLimit) {
        if (
          calculatorData?.data?.countrySpecLimit.hour_limit &&
          calculatorData?.data?.countrySpecLimit.hour_limit !== null
        ) {
          setHourCap(calculatorData?.data?.countrySpecLimit.hour_limit);
          setMostRestrictiveHourCap(
            calculatorData?.data?.countrySpecLimit.hour_limit
          );
        } else {
          setHourCap(null);
          setMostRestrictiveHourCap(null);
        }
        if (
          calculatorData?.data?.countrySpecLimit.rate_per_hr_limit &&
          calculatorData?.data?.countrySpecLimit.rate_per_hr_limit !== null
        ) {
          setRateCap(calculatorData?.data?.countrySpecLimit.rate_per_hr_limit);
          setMostRestrictiveRateCap(
            calculatorData?.data?.countrySpecLimit.rate_per_hr_limit
          );
        } else {
          setRateCap(null);
          setMostRestrictiveRateCap(null);
        }
        if (
          calculatorData?.data?.countrySpecLimit.session_limit_general &&
          calculatorData?.data?.countrySpecLimit.session_limit_general !== null
        ) {
          setSessionCap(
            calculatorData?.data?.countrySpecLimit.session_limit_general
          );
          setMostRestrictiveSessionCap(
            calculatorData?.data?.countrySpecLimit.session_limit_general
          );
        } else {
          setSessionCap(null);
          setMostRestrictiveSessionCap(null);
        }
      }
      if (calculatorData?.data?.hcpSpecialty) {
        setHCPSpecialtiesList(
          _.map(calculatorData?.data?.hcpSpecialty, (obj) => {
            return { name: obj.hcp_speciality_id, label: obj.HCP_Specialty };
          })
        );
      }
      if (calculatorData?.data?.hcpStatures) {
        setHCPStaturesList(
          calculatorData?.data?.hcpStatures.map((obj, i) => {
            return (
              <option key={i} value={obj.hcp_stature_id}>
                {obj.HCP_Stature}
              </option>
            );
          })
        );
      }
      if (calculatorData?.data?.activityTypes) {
        setActivityTypesList(
          calculatorData?.data?.activityTypes.map((obj, i) => {
            return (
              <option key={i} value={obj.activity_type_id}>
                {obj.Activity_Type}
              </option>
            );
          })
        );
      }
      if (calculatorData?.data?.travelDistances) {
        setTravelDistancesList(
          calculatorData?.data?.travelDistances.map((obj, i) => {
            return (
              <option key={i} value={obj.travel_dist_id}>
                {obj.title?.toLocaleString(locale)}
              </option>
            );
          })
        );
      }
      if (calculatorData?.data?.travelTimeDiscount) {
        let travelDiscount =
          calculatorData?.data?.travelTimeDiscount.percentage_discount;
        setTravelTimeDiscount(
          travelDiscount && travelDiscount !== null ? travelDiscount : 0
        );
      }
      setHideJspdfButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatorData]);

  useEffect(() => {
    if (
      languageTranslationData !== undefined &&
      languageTranslationData !== ""
    ) {
      function findTranslation(keyname) {
        if (
          _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE &&
          _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE !== "" &&
          _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE !== undefined
        ) {
          return _.find(languageTranslationData, (o) => {
            return o?.KEY === keyname;
          })?.VALUE;
        } else {
          return "";
        }
      }
      setcompletedbyLabel(
        findTranslation("completed_by") !== ""
          ? findTranslation("completed_by")
          : "COMPLETED BY"
      );
      setSectorLabel(
        findTranslation("sector") !== "" ? findTranslation("sector") : "Sector"
      );
      setCountryLabel(
        findTranslation("Market") !== "" ? findTranslation("Market") : "Market"
      );
      setRegionLabel(
        findTranslation("region") !== "" ? findTranslation("region") : "region"
      );
      setLastPublishedLabel(
        findTranslation("last_published_on") !== ""
          ? findTranslation("last_published_on")
          : "Last Published On"
      );
      setLastUpdatedLabel(
        findTranslation("last_updated_on") !== ""
          ? findTranslation("last_updated_on")
          : "Last Updated On"
      );
      setDetermineTraveldistanceLinkLabel(
        findTranslation("determine_travel_distance_link") !== ""
          ? findTranslation("determine_travel_distance_link")
          : "Click here for recommended site to determine travel distance"
      );
      setreferenceNumberLabel(
        findTranslation("reference_number") !== ""
          ? findTranslation("reference_number")
          : "Reference Number"
      );
      setreferenceCategoryLabel(
        findTranslation("reference_category") !== ""
          ? findTranslation("reference_category")
          : "Reference Category"
      );
      setpleaseSelectReferenceCategoryLabel(
        findTranslation("please_select_reference_category") !== ""
          ? findTranslation("please_select_reference_category")
          : "Please Select Reference Category"
      );
      setcalculationInputsLabel(
        findTranslation("calculation_inputs") !== ""
          ? findTranslation("calculation_inputs")
          : "CALCULATION INPUTS"
      );
      setbaseRateLabel(
        findTranslation("base_rate") !== ""
          ? findTranslation("base_rate")
          : "Base Rate"
      );
      setratePerHourCapLabel(
        findTranslation("rate_per_hour_cap") !== ""
          ? findTranslation("rate_per_hour_cap")
          : "Rate per hour Cap"
      );
      setcurrencyLabel(
        findTranslation("currency") !== ""
          ? findTranslation("currency")
          : "Currency"
      );
      setsessionCapLabel(
        findTranslation("session_cap") !== ""
          ? findTranslation("session_cap")
          : "Session Cap"
      );
      sethcpSpecialtyLabel(
        findTranslation("hcp_specialty") !== ""
          ? findTranslation("hcp_specialty")
          : "HCP Specialty"
      );
      setspecialtyMultiplierLabel(
        findTranslation("specialty_multiplier") !== ""
          ? findTranslation("specialty_multiplier")
          : "Specialty Multiplier"
      );
      setselectTheFollowingAfterHcpSpecialtyIsChosenLabel(
        findTranslation(
          "select_the_following_after_hcp_specialty_is_chosen"
        ) !== ""
          ? findTranslation(
              "select_the_following_after_hcp_specialty_is_chosen"
            )
          : "Select the following after HCP Specialty is chosen"
      );
      sethcpStatureKolStatusLabel(
        findTranslation("hcp_stature_kol_status") !== ""
          ? findTranslation("hcp_stature_kol_status")
          : "HCP Stature (KOL Status)"
      );
      setkolMultiplierLabel(
        findTranslation("kol_multiplier") !== ""
          ? findTranslation("kol_multiplier")
          : "KOL Multiplier"
      );
      setactivityTypeLabel(
        findTranslation("activity_type") !== ""
          ? findTranslation("activity_type")
          : "Activity Type"
      );
      settravelDistanceOneWayLabel(
        findTranslation("travel_distance_one_way") !== ""
          ? findTranslation("travel_distance_one_way")
          : "Travel Distance (one-way)"
      );
      setIsTravelRoundTripLabel(
        findTranslation("Is_travel_round_trip") !== ""
          ? findTranslation("Is_travel_round_trip")
          : "Is travel - round trip?"
      );
      setselectHoursLabel(
        findTranslation("select_hours") !== ""
          ? findTranslation("select_hours")
          : "Select Hours"
      );
      settypeLabel(
        findTranslation("type") !== "" ? findTranslation("type") : "Type"
      );
      setstandardHoursLabel(
        findTranslation("standard_hours") !== ""
          ? findTranslation("standard_hours")
          : "Standard hours"
      );
      setoverrideHoursLabel(
        findTranslation("override_hours") !== ""
          ? findTranslation("override_hours")
          : "Override hours"
      );
      setactivityTypeServiceTimeLabel(
        findTranslation("activity_type_service_time") !== ""
          ? findTranslation("activity_type_service_time")
          : "Activity type - service time"
      );
      setactivityTypePrepTimeLabel(
        findTranslation("activity_type_prep_time") !== ""
          ? findTranslation("activity_type_prep_time")
          : "Activity type - prep time"
      );
      setactivityLocationTravelTimeLabel(
        findTranslation("activity_location_travel_time") !== ""
          ? findTranslation("activity_location_travel_time")
          : "Activity location - travel time"
      );
      settravelTimeOfHourlyRateLabel(
        findTranslation("travel_time_of_hourly_rate") !== ""
          ? findTranslation("travel_time_of_hourly_rate")
          : "Travel time - % of hourly rate"
      );
      setoverrideLabel(
        findTranslation("override") !== ""
          ? findTranslation("override")
          : "Override"
      );
      setpleaseEnterCommentsForOverridingServiceTimeLabel(
        findTranslation("please_enter_comments_for_overriding_service_time") !==
          ""
          ? findTranslation("please_enter_comments_for_overriding_service_time")
          : "Please enter comments for overriding service time"
      );
      setpleaseEnterCommentsForOverridingPrepTimeLabel(
        findTranslation("please_enter_comments_for_overriding_prep_time") !== ""
          ? findTranslation("please_enter_comments_for_overriding_prep_time")
          : "Please enter comments for overriding prep time"
      );
      setpleaseEnterCommentsForOverridingTravelTimeLabel(
        findTranslation("please_enter_comments_for_overriding_travel_time") !==
          ""
          ? findTranslation("please_enter_comments_for_overriding_travel_time")
          : "Please enter comments for overriding travel time"
      );
      setpleaseEnterCommentsLabel(
        findTranslation("please_enter_comments") !== ""
          ? findTranslation("please_enter_comments")
          : "Please enter comments"
      );
      setonlyNumbersAreAllowedLabel(
        findTranslation("only_numbers_are_allowed") !== ""
          ? findTranslation("only_numbers_are_allowed")
          : "Only numbers are allowed"
      );
      settravelTimeMaxValidationMsgLabel(
        findTranslation("travel_time_max_validation_msg") !== ""
          ? findTranslation("travel_time_max_validation_msg")
          : "Override travel hour cannot exceed maximum travel time for the selected travel threshold"
      );
      setcalculatedfmvrangeLabel(
        findTranslation("calculated_fmv_range") !== ""
          ? findTranslation("calculated_fmv_range")
          : "CALCULATED FMV RANGE"
      );
      setminimumRateLabel(
        findTranslation("minimum_rate") !== ""
          ? findTranslation("minimum_rate")
          : "Minimum Rate"
      );
      setmidLabel(
        findTranslation("mid") !== "" ? findTranslation("mid") : "Mid"
      );
      setmaximumRateLabel(
        findTranslation("maximum_rate") !== ""
          ? findTranslation("maximum_rate")
          : "Maximum Rate"
      );
      setminimumLabel(
        findTranslation("minimum") !== ""
          ? findTranslation("minimum")
          : "Minimum"
      );
      setmaximumLabel(
        findTranslation("maximum") !== ""
          ? findTranslation("maximum")
          : "Maximum"
      );
      setfmvRatePerHourRangeLabel(
        findTranslation("fmv_rate_per_hour_range") !== ""
          ? findTranslation("fmv_rate_per_hour_range")
          : "FMV rate per hour range"
      );
      setfmvTotalCompensationLabel(
        findTranslation("fmv_total_compensation") !== ""
          ? findTranslation("fmv_total_compensation")
          : "FMV - Total Compensation"
      );
      setcalculationDetailsLabel(
        findTranslation("calculation_details") !== ""
          ? findTranslation("calculation_details")
          : "Calculation Details"
      );
      setlocalCurrencyLabel(
        findTranslation("local_currency") !== ""
          ? findTranslation("local_currency")
          : "Local currency"
      );
      settotalTimeHrsLabel(
        findTranslation("total_time_hrs") !== ""
          ? findTranslation("total_time_hrs")
          : "Total Time (hrs)"
      );
      setbaseRateInLocalCurrencyLabel(
        findTranslation("base_rate_in_local_currency") !== ""
          ? findTranslation("base_rate_in_local_currency")
          : "Base rate (in local currency)"
      );
      sethourlyCapHrsLabel(
        findTranslation("hourly_cap_hrs") !== ""
          ? findTranslation("hourly_cap_hrs")
          : "Hourly Cap (hrs)"
      );
      setserviceTimeHrsLabel(
        findTranslation("service_time_hrs") !== ""
          ? findTranslation("service_time_hrs")
          : "Service Time (hrs)"
      );
      setadjServiceTimeHrsLabel(
        findTranslation("adj_service_time_hrs") !== ""
          ? findTranslation("adj_service_time_hrs")
          : "Adj Service Time (hrs)"
      );
      setprepTimeHrsLabel(
        findTranslation("prep_time_hrs") !== ""
          ? findTranslation("prep_time_hrs")
          : "Prep Time (hrs)"
      );
      setadjPrepTimeHrsLabel(
        findTranslation("adj_prep_time_hrs") !== ""
          ? findTranslation("adj_prep_time_hrs")
          : "Adj Prep Time (hrs)"
      );
      settravelTimeHrsLabel(
        findTranslation("travel_time_hrs") !== ""
          ? findTranslation("travel_time_hrs")
          : "Travel Time (hrs)"
      );
      setadjTravelTimeHrsLabel(
        findTranslation("adj_travel_time_hrs") !== ""
          ? findTranslation("adj_travel_time_hrs")
          : "Adj Travel Time (hrs)"
      );
      setfmvRatePerHourLabel(
        findTranslation("fmv_rate_per_hour") !== ""
          ? findTranslation("fmv_rate_per_hour")
          : "FMV rate per hour"
      );
      sethcpSpecialtyMultiplierLabel(
        findTranslation("hcp_specialty_multiplier") !== ""
          ? findTranslation("hcp_specialty_multiplier")
          : "HCP specialty multiplier"
      );
      sethcpStatureMultiplierLabel(
        findTranslation("hcp_stature_multiplier") !== ""
          ? findTranslation("hcp_stature_multiplier")
          : "HCP stature multiplier"
      );
      setcalculatedRatePerHourLabel(
        findTranslation("calculated_rate_per_hour") !== ""
          ? findTranslation("calculated_rate_per_hour")
          : "Calculated rate per hour"
      );
      setminRangeLabel(
        findTranslation("min_range") !== ""
          ? findTranslation("min_range")
          : "Min range"
      );
      setmaxRangeLabel(
        findTranslation("max_range") !== ""
          ? findTranslation("max_range")
          : "Max range"
      );
      settotalFeePerActivityLabel(
        findTranslation("total_fee_per_activity") !== ""
          ? findTranslation("total_fee_per_activity")
          : "Total fee per activity"
      );
      setminimumFeeLabel(
        findTranslation("minimum_fee") !== ""
          ? findTranslation("minimum_fee")
          : "Minimum fee"
      );
      setmaximumFeeLabel(
        findTranslation("maximum_fee") !== ""
          ? findTranslation("maximum_fee")
          : "Maximum fee"
      );
      settravelTimeLabel(
        findTranslation("travel_time") !== ""
          ? findTranslation("travel_time")
          : "Travel Time"
      );
      setserviceTimeLabel(
        findTranslation("service_time") !== ""
          ? findTranslation("service_time")
          : "Service Time"
      );
      sethoursLabel(
        findTranslation("hours") !== "" ? findTranslation("hours") : "hours"
      );
      setcostOfEventLabel(
        findTranslation("cost_of_event") !== ""
          ? findTranslation("cost_of_event")
          : "Cost of event"
      );
      setprepTimeCostLabel(
        findTranslation("prep_time_cost") !== ""
          ? findTranslation("prep_time_cost")
          : "Prep Time Cost"
      );
      settotalCostLabel(
        findTranslation("total_cost") !== ""
          ? findTranslation("total_cost")
          : "Total cost"
      );
      settotalCostExclPrepTimeLabel(
        findTranslation("total_cost_excl_prep_time") !== ""
          ? findTranslation("total_cost_excl_prep_time")
          : "Total cost excl. prep time"
      );
      settotalCostExclTravelTimeLabel(
        findTranslation("total_cost_excl_travel_time") !== ""
          ? findTranslation("total_cost_excl_travel_time")
          : "Total cost excl. travel time"
      );
      setcalculatedCompensationWithoutCapsLabel(
        findTranslation("calculated_compensation_without_caps") !== ""
          ? findTranslation("calculated_compensation_without_caps")
          : "CALCULATED COMPENSATION WITHOUT CAPS"
      );
      setmemoInformationPurposeOnlyLabel(
        findTranslation("memo_information_purpose_only") !== ""
          ? findTranslation("memo_information_purpose_only")
          : "MEMO (INFORMATION PURPOSE ONLY)"
      );
      settimeLabel(
        findTranslation("time") !== "" ? findTranslation("time") : "Time"
      );
      setratePerHourLabel(
        findTranslation("rate_per_hour") !== ""
          ? findTranslation("rate_per_hour")
          : "Rate per hour"
      );
      settravelLabel(
        findTranslation("travel") !== "" ? findTranslation("travel") : "Travel"
      );
      setserviceLabel(
        findTranslation("service") !== ""
          ? findTranslation("service")
          : "Service"
      );
      setpreparationLabel(
        findTranslation("preparation") !== ""
          ? findTranslation("preparation")
          : "Preparation"
      );
      settotalLabel(
        findTranslation("total") !== "" ? findTranslation("total") : "Total"
      );
      setcommentsLabel(
        findTranslation("comments") !== ""
          ? findTranslation("comments")
          : "Comments"
      );
    }
  }, [languageTranslationData]);

  useEffect(() => {
    if (refCategoryMaster) {
      setRefCategoryList(
        refCategoryMaster?.data?.map((obj, i) => {
          return (
            <option key={i} value={obj.id}>
              {obj.value}
            </option>
          );
        })
      );
    }
  }, [refCategoryMaster]);

  useEffect(() => {
    if (activityType !== undefined && activityType !== "") {
      let acTypeHourCap = _.find(calculatorData?.data?.activityTypes, (o) => {
        return o.activity_type_id === activityType;
      }).hcp_stature_hr_cap;

      setActivityHourCap(
        acTypeHourCap !== undefined &&
          acTypeHourCap !== null &&
          acTypeHourCap !== ""
          ? acTypeHourCap
          : null
      );
      setMostRestrictiveHourCap(
        acTypeHourCap !== undefined &&
          acTypeHourCap !== null &&
          acTypeHourCap !== ""
          ? hourCap !== undefined && hourCap !== null && hourCap !== ""
            ? acTypeHourCap < hourCap
              ? acTypeHourCap
              : hourCap
            : acTypeHourCap
          : hourCap
      );

      let statureCaps = _.find(calculatorData?.data?.activityTypes, (o) => {
        return o.activity_type_id === activityType;
      })?.statureCaps;
      let sessionCaps = _.find(calculatorData?.data?.activityTypes, (o) => {
        return o.activity_type_id === activityType;
      })?.sessionCaps;

      if (hcpStature !== undefined && hcpStature !== "") {
        let acTypeRateCap = _.find(statureCaps, (o) => {
          return o.hcp_stature_id === hcpStature;
        })?.rate_cap;
        setActivityRateCap(
          acTypeRateCap !== undefined &&
            acTypeRateCap !== null &&
            acTypeRateCap !== ""
            ? acTypeRateCap
            : null
        );
        setMostRestrictiveRateCap(
          acTypeRateCap !== undefined &&
            acTypeRateCap !== null &&
            acTypeRateCap !== ""
            ? rateCap !== undefined && rateCap !== null && rateCap !== ""
              ? acTypeRateCap < rateCap
                ? acTypeRateCap
                : rateCap
              : acTypeRateCap
            : rateCap
        );

        let acTypeSessionCap = _.find(sessionCaps, (o) => {
          return o.hcp_stature_id === hcpStature;
        })?.session_cap;
        setActivitySessionCap(
          acTypeSessionCap !== undefined &&
            acTypeSessionCap !== null &&
            acTypeSessionCap !== ""
            ? acTypeSessionCap
            : null
        );
        setMostRestrictiveSessionCap(
          acTypeSessionCap !== undefined &&
            acTypeSessionCap !== null &&
            acTypeSessionCap !== ""
            ? sessionCap !== undefined &&
              sessionCap !== null &&
              sessionCap !== ""
              ? acTypeSessionCap < sessionCap
                ? acTypeSessionCap
                : sessionCap
              : acTypeSessionCap
            : sessionCap
        );
      } else {
        setMostRestrictiveRateCap(rateCap);
        setMostRestrictiveSessionCap(sessionCap);
      }
    } else {
      setMostRestrictiveHourCap(hourCap);
      setMostRestrictiveRateCap(rateCap);
      setMostRestrictiveSessionCap(sessionCap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hcpStature, activityType]);

  useEffect(() => {
    setHourlyRateCapApplied(false);

    if (
      hcpSpecialtyMultiplier &&
      hcpSpecialtyMultiplier !== null &&
      hcpSpecialtyMultiplier !== "" &&
      hcpStatureMultiplier &&
      hcpStatureMultiplier !== null &&
      hcpStatureMultiplier !== "" &&
      activityType &&
      activityType !== null &&
      activityType !== ""
    ) {
      let specialtyMultiplier =
        hcpSpecialtyMultiplier &&
        hcpSpecialtyMultiplier != null &&
        hcpSpecialtyMultiplier !== ""
          ? hcpSpecialtyMultiplier
          : 1;
      let statureMultiplier =
        hcpStatureMultiplier &&
        hcpStatureMultiplier !== null &&
        hcpStatureMultiplier !== ""
          ? hcpStatureMultiplier
          : 1;

      let min_range =
        minRange && minRange !== null && minRange !== ""
          ? getParsedValue(minRange)
          : 0;
      let max_range =
        maxRange && maxRange !== null && maxRange !== ""
          ? getParsedValue(maxRange)
          : 0;
      let base_rate =
        baseRate && baseRate !== null && baseRate !== ""
          ? getParsedValue(baseRate)
          : 0;
      let round_of =
        roundOf && roundOf !== null && roundOf !== ""
          ? getParsedValue(roundOf)
          : 10;

      let hourlyrate =
        Math.round(
          (base_rate * specialtyMultiplier * statureMultiplier) / round_of
        ) * round_of;
      setActualHourlyRate(getParsedValue(hourlyrate));

      let lowestHourlyRate = getLowestRate(hourlyrate);
      setHourlyRate(lowestHourlyRate);
      setIsHourlyRateCapped(hourlyrate > lowestHourlyRate ? true : false);

      let minhourlyrate =
        Math.round(
          (Math.round(
            (base_rate * specialtyMultiplier * statureMultiplier) / round_of
          ) *
            round_of *
            (100 - min_range)) /
            100 /
            round_of
        ) * round_of;
      setActualMinHourlyRate(minhourlyrate);

      let lowestMinHourlyRate = getLowestRate(minhourlyrate);
      setMinHourlyRate(lowestMinHourlyRate);
      setIsMinHourlyRateCapped(
        minhourlyrate > lowestMinHourlyRate ? true : false
      );

      let maxhourlyrate =
        Math.round(
          (Math.round(
            (base_rate * specialtyMultiplier * statureMultiplier) / round_of
          ) *
            round_of *
            (100 + max_range)) /
            100 /
            round_of
        ) * round_of;
      setActualMaxHourlyRate(maxhourlyrate);

      let lowestMaxHourlyRate = getLowestRate(maxhourlyrate);
      setMaxHourlyRate(lowestMaxHourlyRate);
      setIsMaxHourlyRateCapped(
        maxhourlyrate > lowestMaxHourlyRate ? true : false
      );
    } else {
      setActualHourlyRate("");
      setHourlyRate("");
      setIsHourlyRateCapped(false);

      setActualMinHourlyRate("");
      setMinHourlyRate("");
      setIsMinHourlyRateCapped(false);

      setActualMaxHourlyRate("");
      setMaxHourlyRate("");
      setIsMaxHourlyRateCapped(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseRate,
    hcpSpecialtyMultiplier,
    hcpStatureMultiplier,
    activityType,
    activityRateCap,
  ]);

  useEffect(() => {
    setHourCapApplied(false);
    setSessionCapApplied(false);

    let travelHours =
      overrideTravel && overrideTravelTime !== ""
        ? getParsedValue(overrideTravelTime)
        : getParsedValue(stndTravelTime);
    let serviceHours =
      overrideService && overrideServiceTime !== ""
        ? getParsedValue(overrideServiceTime)
        : getParsedValue(stndServiceTime);
    let prepHours =
      overridePrep && overridePrepTime !== ""
        ? getParsedValue(overridePrepTime)
        : getParsedValue(stndPrepTime);

    let totalhours = travelHours + serviceHours + prepHours;
    setActualTotalHours(totalhours);
    setTotalHours(getLowestHour(totalhours));

    let travelcost =
      (hourlyRate * travelHours * getParsedValue(travelTimeDiscount)) / 100;
    setTravelCost(travelcost);
    let mintravelcost =
      (minHourlyRate * travelHours * getParsedValue(travelTimeDiscount)) / 100;
    setMinTravelCost(mintravelcost);
    let maxtravelcost =
      (maxHourlyRate * travelHours * getParsedValue(travelTimeDiscount)) / 100;
    setMaxTravelCost(maxtravelcost);

    let servicecost = hourlyRate * serviceHours;
    setServiceCost(servicecost);
    let minservicecost = minHourlyRate * serviceHours;
    setMinServiceCost(minservicecost);
    let maxservicecost = maxHourlyRate * serviceHours;
    setMaxServiceCost(maxservicecost);

    let prepcost = hourlyRate * prepHours;
    setPrepCost(prepcost);
    let minprepcost = minHourlyRate * prepHours;
    setMinPrepCost(minprepcost);
    let maxprepcost = maxHourlyRate * prepHours;
    setMaxPrepCost(maxprepcost);

    let totalCompensation = travelcost + servicecost + prepcost;
    let lowestTotalComp = getLowestCompensation(totalCompensation);
    setTotalCompensation(lowestTotalComp);
    setIsTotalCompensationCapped(
      totalCompensation > lowestTotalComp ? true : false
    );

    let minCompensation = mintravelcost + minservicecost + minprepcost;
    let lowestMinComp = getLowestCompensation(minCompensation);
    setMinTotalCompensation(lowestMinComp);
    setIsMinCompensationCapped(minCompensation > lowestMinComp ? true : false);

    let maxCompensation = maxtravelcost + maxservicecost + maxprepcost;
    let lowestMaxComp = getLowestCompensation(maxCompensation);
    setMaxTotalCompensation(lowestMaxComp);
    setIsMaxCompensationCapped(maxCompensation > lowestMaxComp ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hourlyRate,
    activitySessionCap,
    isRoundTrip,
    stndTravelTime,
    overrideTravelTime,
    travelTimeDiscount,
    stndServiceTime,
    overrideServiceTime,
    stndPrepTime,
    overridePrepTime,
  ]);

  useEffect(() => {
    setEventCost(travelCost + serviceCost);
    setMinEventCost(minTravelCost + minServiceCost);
    setMaxEventCost(maxTravelCost + maxServiceCost);

    setTotalCost(travelCost + serviceCost + prepCost);
    setMinTotalCost(minTravelCost + minServiceCost + minPrepCost);
    setMaxTotalCost(maxTravelCost + maxServiceCost + maxPrepCost);

    setTotalCostExclPrep(travelCost + serviceCost);
    setMinTotalCostExclPrep(minTravelCost + minServiceCost);
    setMaxTotalCostExclPrep(maxTravelCost + maxServiceCost);

    setTotalCostExclTravel(serviceCost + prepCost);
    setMinTotalCostExclTravel(minServiceCost + minPrepCost);
    setMaxTotalCostExclTravel(maxServiceCost + maxPrepCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelCost, serviceCost, prepCost]);

  useEffect(() => {
    if (actualHourlyRate) {
      let travelHours =
        overrideTravel && overrideTravelTime !== ""
          ? getParsedValue(overrideTravelTime)
          : getParsedValue(stndTravelTime);
      let serviceHours =
        overrideService && overrideServiceTime !== ""
          ? getParsedValue(overrideServiceTime)
          : getParsedValue(stndServiceTime);
      let prepHours =
        overridePrep && overridePrepTime !== ""
          ? getParsedValue(overridePrepTime)
          : getParsedValue(stndPrepTime);

      let actualtravelcost =
        (actualHourlyRate * travelHours * getParsedValue(travelTimeDiscount)) /
        100;
      setActualTravelCost(actualtravelcost);

      let actualservicecost = actualHourlyRate * serviceHours;
      setActualServiceCost(actualservicecost);

      let actualprepcost = actualHourlyRate * prepHours;
      setActualPrepCost(actualprepcost);

      let actualtotalcost =
        actualtravelcost + actualservicecost + actualprepcost;
      setActualTotalCost(actualtotalcost);

      let minactualtotalcost =
        (actualMinHourlyRate *
          travelHours *
          getParsedValue(travelTimeDiscount)) /
          100 +
        actualMinHourlyRate * serviceHours +
        actualMinHourlyRate * prepHours;
      setMinActualTotalCost(minactualtotalcost);

      let maxactualtotalcost =
        (actualMaxHourlyRate *
          travelHours *
          getParsedValue(travelTimeDiscount)) /
          100 +
        actualMaxHourlyRate * serviceHours +
        actualMaxHourlyRate * prepHours;
      setMaxActualTotalCost(maxactualtotalcost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualHourlyRate,
    stndTravelTime,
    overrideTravelTime,
    travelTimeDiscount,
    stndServiceTime,
    overrideServiceTime,
    stndPrepTime,
    overridePrepTime,
  ]);

  useEffect(() => {
    if (hourlyRateCapApplied || hourCapApplied || sessionCapApplied) {
      setShowCapExceededMssg(true);
    } else {
      setShowCapExceededMssg(false);
    }
  }, [hourlyRateCapApplied, hourCapApplied, sessionCapApplied]);

  useEffect(() => {
    if (isRoundTrip === "1") {
      setStndTravelTime(selectedTravelHr * 2);
    } else {
      setStndTravelTime(selectedTravelHr);
      if (overrideTravelTime > getParsedValue(selectedTravelHr)) {
        setTravelTimeMaxValidation(true);
        document.getElementById("txt_overrideTravelTime").focus();
        setTravelCommentValidation(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoundTrip]);

  useEffect(() => {
    if (overrideService) {
      document.getElementById("txt_overrideServiceTime").focus();
    }
  }, [overrideService]);

  useEffect(() => {
    if (overridePrep) {
      document.getElementById("txt_overridePrepTime").focus();
    }
  }, [overridePrep]);

  useEffect(() => {
    if (overrideTravel) {
      document.getElementById("txt_overrideTravelTime").focus();
    }
  }, [overrideTravel]);

  useEffect(() => {
    if (referenceValidation) document.getElementById("dd_ref_category").focus();
  }, [referenceValidation]);

  useEffect(() => {
    if (sessionId && sessionId !== undefined) {
      setSessionUuid(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (contrySectorNameData !== undefined) {
      let dataToProcess = [];
      let countryActivityData = [],
        sectorActivityData = [];
      if (
        calcId !== "" &&
        calcName !== "" &&
        userEmail !== "" &&
        userName !== "" &&
        contrySectorNameData?.country_name?.label !== "" &&
        contrySectorNameData?.sector_name?.label !== "" &&
        sessionUuid !== ""
      ) {
        countryActivityData = {
          session_id: sessionUuid,
          calculator_id: calcId,
          calculator_name: calcName,
          email: userEmail,
          name: userName,
          control: "Market",
          control_value: contrySectorNameData?.country_name?.label,
          details:
            "Market - chosen '" +
            contrySectorNameData?.country_name?.label +
            "'",
        };
        sectorActivityData = {
          session_id: sessionUuid,
          calculator_id: calcId,
          calculator_name: calcName,
          email: userEmail,
          name: userName,
          control: "Sector",
          control_value: contrySectorNameData?.sector_name?.label,
          details:
            "Sector - chosen '" +
            contrySectorNameData?.sector_name?.label +
            "'",
        };
      }
      if (
        hcpSpecialty !== "" &&
        hcpStature !== "" &&
        activityType !== "" &&
        contrySectorNameData?.country_name?.label !== "" &&
        contrySectorNameData?.sector_name?.label !== ""
      ) {
        if (
          specialtyActivityData?.length !== 0 &&
          statureActivityData?.length !== 0 &&
          activityTypeActivityData?.length !== 0 &&
          countryActivityData?.length !== 0 &&
          sectorActivityData?.length !== 0
        ) {
          dataToProcess.push(countryActivityData);
          dataToProcess.push(sectorActivityData);
          dataToProcess.push(specialtyActivityData);
          dataToProcess.push(statureActivityData);
          dataToProcess.push(activityTypeActivityData);
          if (dataToProcess?.length !== 0 && !preview) {
            setEndUserActivityDataToProcess(dataToProcess);
            setIsAllUserActivityValueSelected(true);
            setIsExportToPDFActivityCaptured(false);
            dispatch(UpdateEndUserActivity(dataToProcess));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contrySectorNameData,
    specialtyActivityData,
    statureActivityData,
    activityTypeActivityData,
  ]);

  const CaptureExportToPDFActvity = () => {
    if (isAllUserActivityValueSelected && sessionUuid !== "") {
      let dataToProcess = [];
      let pdfActivityData = {
        session_id: sessionUuid,
        calculator_id: calcId,
        calculator_name: calcName,
        email: userEmail,
        name: userName,
        control: "Export To PDF",
        control_value: "Exported To PDF",
        details: "PDF expoted for '" + calcName + "' calculator",
      };
      dataToProcess = endUserActivityDataToProcess;
      if (pdfActivityData?.length !== 0) {
        if (!isExportToPDFActivityCaptured) {
          dataToProcess.push(pdfActivityData);
          setIsExportToPDFActivityCaptured(true);
        }
        dispatch(UpdateEndUserActivity(dataToProcess));
      }
    }
  };

  useEffect(() => {
    if (isExecuting && isDownloadcalcPdfSelected) {
      document.getElementById("calculator_activity_type_id").style.border =
        "none";
      document.getElementById(
        "calculator_traveltimehourlyrate_id"
      ).style.border = "none";

      document.getElementById("txt_overrideTravelComment").style.display =
        "none";
      document.getElementById("pdf_txt_overrideTravelComment").style.display =
        "block";
      document.getElementById("txt_overrideTravelTime").style.border = "none";
      document.getElementById(
        "calculator_activity_location_traveltime_type_id"
      ).style.border = "none";

      document.getElementById("txt_overridePrepComment").style.display = "none";
      document.getElementById("pdf_txt_overridePrepComment").style.display =
        "block";
      document.getElementById("txt_overridePrepTime").style.border = "none";
      document.getElementById(
        "calculator_activity_type_prepTime_id"
      ).style.border = "none";

      document.getElementById("txt_overrideServiceComment").style.display =
        "none";
      document.getElementById("pdf_txt_overrideServiceComment").style.display =
        "block";
      document.getElementById("txt_overrideServiceTime").style.border = "none";
      document.getElementById("calculator_isroundtrip_id").style.border =
        "none";

      document.getElementById(
        "pdfcol_calculator_travelDistanceOneWay_id"
      ).style.display = "none";
      document.getElementById(
        "pdf_calculator_travelDistanceOneWay_id_space"
      ).style.display = "none";
      document.getElementById(
        "pdftext_calculator_travelDistanceOneWay_id"
      ).style.display = "block";

      document.getElementById(
        "pdfcol_calculator_activityTypeselect_id"
      ).style.display = "none";
      document.getElementById(
        "pdf_calculator_activityTypeselect_id_space"
      ).style.display = "none";
      document.getElementById(
        "pdftext_calculator_activityTypeselect_id"
      ).style.display = "block";
      if (!hideStatureMultiplier) {
        document.getElementById("calculator_kolmultiplier_id").style.border =
          "none";
      }

      document.getElementById(
        "pdfcol_calculator_hcpStatureKolStatus_id"
      ).style.display = "none";
      document.getElementById(
        "pdf_calculator_hcpStatureKolStatus_space_id"
      ).style.display = "none";
      document.getElementById(
        "pdftext_calculator_hcpStatureKolStatus_id"
      ).style.display = "block";
      if (!hideSpecialityMultiplier) {
        document.getElementById(
          "calculator_specialitymultiplier_id"
        ).style.border = "none";
      }

      document.getElementById("calculator_referenceNumber_id").style.display =
        "none";
      document.getElementById(
        "pdftext_calculator_referenceNumber_id"
      ).style.display = "block";
      document.getElementById("dd_ref_category").style.border = "none";

      document.getElementById("calculator_hcpspecialitycol_id").style.display =
        "none";
      document.getElementById(
        "pdftext_calculator_hcpspecialitycol_id"
      ).style.display = "block";
      document.getElementById("pdf-head-calculator-header-info").style.display =
        "block";
      document.getElementById(
        "calculator_hcpspecialitycol_space_id"
      ).style.display = "none";

      var draftStatus = isDraftVersion ? true : false;

      var headerElement = document.getElementById(
        "pdf-head-calculator-header-info"
      );
      ExportToPDF(
        "div-fmv-calculator",
        "FMV_Calculator",
        userID?.toUpperCase(),
        headerElement,
        "",
        dispatch,
        draftStatus
      );

      document.getElementById("calculator_activity_type_id").style.border = "";
      document.getElementById(
        "calculator_traveltimehourlyrate_id"
      ).style.border = "";

      document.getElementById("txt_overrideTravelComment").style.display = "";
      document.getElementById("pdf_txt_overrideTravelComment").style.display =
        "";
      document.getElementById("txt_overrideTravelTime").style.border = "";
      document.getElementById(
        "calculator_activity_location_traveltime_type_id"
      ).style.border = "";

      document.getElementById("txt_overridePrepComment").style.display = "";
      document.getElementById("pdf_txt_overridePrepComment").style.display = "";
      document.getElementById("txt_overridePrepTime").style.border = "";
      document.getElementById(
        "calculator_activity_type_prepTime_id"
      ).style.border = "";

      document.getElementById("txt_overrideServiceComment").style.display = "";
      document.getElementById("pdf_txt_overrideServiceComment").style.display =
        "";
      document.getElementById("txt_overrideServiceTime").style.border = "";
      document.getElementById("calculator_isroundtrip_id").style.border = "";

      document.getElementById(
        "pdfcol_calculator_travelDistanceOneWay_id"
      ).style.display = "";
      document.getElementById(
        "pdf_calculator_travelDistanceOneWay_id_space"
      ).style.display = "";
      document.getElementById(
        "pdftext_calculator_travelDistanceOneWay_id"
      ).style.display = "";

      document.getElementById(
        "pdfcol_calculator_activityTypeselect_id"
      ).style.display = "";
      document.getElementById(
        "pdf_calculator_activityTypeselect_id_space"
      ).style.display = "";
      document.getElementById(
        "pdftext_calculator_activityTypeselect_id"
      ).style.display = "";
      if (!hideStatureMultiplier) {
        document.getElementById("calculator_kolmultiplier_id").style.border =
          "";
      }

      document.getElementById(
        "pdfcol_calculator_hcpStatureKolStatus_id"
      ).style.display = "";
      document.getElementById(
        "pdf_calculator_hcpStatureKolStatus_space_id"
      ).style.display = "";
      document.getElementById(
        "pdftext_calculator_hcpStatureKolStatus_id"
      ).style.display = "";
      if (!hideSpecialityMultiplier) {
        document.getElementById(
          "calculator_specialitymultiplier_id"
        ).style.border = "";
      }

      document.getElementById("calculator_referenceNumber_id").style.display =
        "";
      document.getElementById(
        "pdftext_calculator_referenceNumber_id"
      ).style.display = "";
      document.getElementById("dd_ref_category").style.border = "";

      document.getElementById("calculator_hcpspecialitycol_id").style.display =
        "";
      document.getElementById(
        "pdftext_calculator_hcpspecialitycol_id"
      ).style.display = "";
      document.getElementById("pdf-head-calculator-header-info").style.display =
        "";
      document.getElementById(
        "calculator_hcpspecialitycol_space_id"
      ).style.display = "";
      setIsDownloadcalcPdfSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuting, isDownloadcalcPdfSelected]);

  useEffect(() => {
    if (
      draftVersionStatusData &&
      draftVersionStatusData !== "" &&
      draftVersionStatusData !== undefined
    ) {
      if (draftVersionStatusData?.toLowerCase() === "draftversion") {
        setIsDrftVersion(true);
      }
    }
  }, [draftVersionStatusData]);

  useEffect(() => {
    if (
      referenceNumber !== "" ||
      overrideService ||
      overridePrep ||
      overrideTravel
    ) {
      setShowExportPDFNote(true);
    } else if (
      referenceNumber === "" &&
      !overrideService &&
      !overridePrep &&
      !overrideTravel
    ) {
      setShowExportPDFNote(false);
    }
  }, [referenceNumber, overrideService, overridePrep, overrideTravel]);

  const getParsedValue = (value) => {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  };

  const getLowestRate = (rate) => {
    let rates = [rate, rateCap, activityRateCap];
    let lowestRate = rates[0];

    for (let i = 0; i < rates.length; i++) {
      if (
        rates[i] !== undefined &&
        rates[i] !== null &&
        rates[i] < lowestRate
      ) {
        lowestRate = rates[i];
        setHourlyRateCapApplied(true);
      }
    }
    return lowestRate;
  };

  const getLowestCompensation = (compensation) => {
    let compensations = [compensation, sessionCap, activitySessionCap];
    let lowestCompensation = compensations[0];

    for (let i = 0; i < compensations.length; i++) {
      if (
        compensations[i] !== undefined &&
        compensations[i] !== null &&
        compensations[i] < lowestCompensation
      ) {
        lowestCompensation = compensations[i];
        setSessionCapApplied(true);
      }
    }
    return lowestCompensation;
  };

  const getLowestHour = (hour) => {
    let hours = [hour, hourCap, activityHourCap];
    let lowestHour = hours[0];

    for (let i = 0; i < hours.length; i++) {
      if (
        hours[i] !== undefined &&
        hours[i] !== null &&
        hours[i] < lowestHour
      ) {
        lowestHour = hours[i];
        setHourCapApplied(true);
      }
    }
    return lowestHour;
  };

  const DropDownComp = (props) => {
    if (props.listObj) {
      return (
        <select
          id={props.elementId}
          className="select-config"
          value={props.objId}
          onChange={(e) => props.callOnChange(e.target.value)}
          disabled={props.disabled}
        >
          <option value="">Select</option>
          {props.listObj}
        </select>
      );
    }
    return (
      <select>
        <option value="">Select</option>
      </select>
    );
  };

  const OnChangeReferenceNumber = (e) => {
    setReferenceNumber(e.target.value);
    if (e.target.value === "") {
      setReferenceCategory("");
      setReferenceCategoryValue("");
    }
    dispatch(setReferenceNumberData({ data: e.target.value }));
  };

  const OnBlurReferenceNumber = (e) => {
    if (e.target.value && e.target.value !== "") {
      document.getElementById("dd_ref_category").focus();
      setReferenceValidation(true);
    } else {
      setReferenceValidation(false);
    }
  };

  const OnChangeReferenceCategory = (value) => {
    setReferenceCategory(value);
    let selectedItem = _.find(refCategoryMaster?.data, (o) => {
      return o.id === value;
    });
    setReferenceCategoryValue(selectedItem?.value);

    if (value && value !== "") {
      setReferenceValidation(false);
    } else {
      setReferenceValidation(true);
    }
  };

  const OnSelectSpecialty = (obj) => {
    setHCPSpecialty(obj.name);
    setHCPSpecialtyValue(obj.label);

    let selectedItem = _.find(calculatorData?.data?.hcpSpecialty, (o) => {
      return o.hcp_speciality_id === obj.name;
    });
    if (selectedItem) {
      let segmentId = selectedItem.segment_id;
      setHCPSpecialtyMultiplier(
        _.find(calculatorData?.data?.segments, (o) => {
          return o.segment_id === segmentId;
        })?.multiplier
      );
    } else {
      setHCPSpecialtyMultiplier("");
    }
    if (obj && obj?.label !== "") {
      let segmentId = selectedItem.segment_id;
      let multiplierValue = _.find(calculatorData?.data?.segments, (o) => {
        return o.segment_id === segmentId;
      })?.multiplier;
      if (
        calcId !== "" &&
        calcName !== "" &&
        userEmail !== "" &&
        userName !== "" &&
        sessionUuid !== ""
      ) {
        let data = {
          session_id: sessionUuid,
          calculator_id: calcId,
          calculator_name: calcName,
          email: userEmail,
          name: userName,
          control: "HCP Specialty",
          control_value: obj.label,
          details:
            "HCP Specialty - chosen '" +
            obj.label +
            "' - Specialty Multiplier - '" +
            multiplierValue +
            "'",
        };
        setSpecialtyActivityData(data);
      }
    }
  };

  const OnSelectStature = (value) => {
    setHCPStature(value);
    if (value && value !== "") {
      setHCPStatureValue(
        _.find(calculatorData?.data?.hcpStatures, (o) => {
          return o.hcp_stature_id === value;
        }).HCP_Stature
      );

      setHCPStatureMultiplier(
        _.find(calculatorData?.data?.hcpStatures, (o) => {
          return o.hcp_stature_id === value;
        }).multiplier
      );
      if (
        calcId !== "" &&
        calcName !== "" &&
        userEmail !== "" &&
        userName !== "" &&
        sessionUuid !== ""
      ) {
        let data = {
          session_id: sessionUuid,
          calculator_id: calcId,
          calculator_name: calcName,
          email: userEmail,
          name: userName,
          control: "HCP Stature (KOL Status)",
          control_value: _.find(calculatorData?.data?.hcpStatures, (o) => {
            return o.hcp_stature_id === value;
          }).HCP_Stature,
          details:
            "HCP Stature (KOL Status) - chosen '" +
            _.find(calculatorData?.data?.hcpStatures, (o) => {
              return o.hcp_stature_id === value;
            }).HCP_Stature +
            "' - KOL Multiplier - " +
            _.find(calculatorData?.data?.hcpStatures, (o) => {
              return o.hcp_stature_id === value;
            }).multiplier,
        };
        setStatureActivityData(data);
      }
    } else {
      setHCPStatureValue("");
      setHCPStatureMultiplier("");
    }
  };

  const OnSelectActivityType = (value) => {
    if (
      value === ""
    ) {
      setTravelDistance("");
    }
    
    
    if (sectorId === "fa713643-02f4-43ea-acb5-75d7c3d974c1") {
      //always hide 1-80 US speaker id
      //always show normal 1-80 id
      const newTravelDistanceList = calculatorData?.data?.travelDistances
          .filter(
            (data) =>
              data.travel_dist_id !== "549786a9-c978-4776-8526-5ef7509643d4"
          )
          .map((obj, i) => {
            return (
              <option key={i} value={obj.travel_dist_id}>
                {obj.title?.toLocaleString(locale)}
              </option>
            );
          });
        // console.log({ newTravelDistanceList });
        setTravelDistancesList(newTravelDistanceList);
    }
    else {
      if (
      value !== "75b50991-ef36-40be-a125-0e39343ae098" &&
      travelDistance === "168199be-20e2-4f2e-a861-f3694a95841f"
    ) {
      setTravelDistance("");
    }
    if (
      value === "75b50991-ef36-40be-a125-0e39343ae098" &&
      travelDistance === "549786a9-c978-4776-8526-5ef7509643d4"
    ) {
      setTravelDistance("");
    }
    if (value === "75b50991-ef36-40be-a125-0e39343ae098") {
   
      /*const newTravelDistanceList = calculatorData?.data?.travelDistances
        .filter(
          (data) =>
            {
             // console.log({check: data.travel_dist_id !== "168199be-20e2-4f2e-a861-f3694a95841f"}) 
              return data.travel_dist_id !== "168199be-20e2-4f2e-a861-f3694a95841f"
            }
        )
        .map((obj, i) => {
          return (
            <option key={i} value={obj.travel_dist_id}>
              {obj.title?.toLocaleString(locale)}
            </option>
          );
        });
     // console.log({ newTravelDistanceList });
      setTravelDistancesList(newTravelDistanceList);*/
    } else {
     
      const newTravelDistanceList = calculatorData?.data?.travelDistances
        .filter(
          (data) =>
            data.travel_dist_id !== "549786a9-c978-4776-8526-5ef7509643d4"
        )
        .map((obj, i) => {
          return (
            <option key={i} value={obj.travel_dist_id}>
              {obj.title?.toLocaleString(locale)}
            </option>
          );
        });
      // console.log({ newTravelDistanceList });
      setTravelDistancesList(newTravelDistanceList);
    }}
    setActivityType(value);

    setOverrideService(false);
    setOverrideServiceTime("");
    document.getElementById("txt_overrideServiceTime").value = "";
    setServiceTimeOverrideComment("");
    setServiceTimeValidation(false);
    setServiceCommentValidation(false);
    setOverridePrep(false);
    setOverridePrepTime("");
    document.getElementById("txt_overridePrepTime").value = "";
    setPrepTimeOverrideComment("");
    setPrepTimeValidation(false);
    setPrepCommentValidation(false);

    if (value && value !== "") {
      setActivityTypeValue(
        _.find(calculatorData?.data?.activityTypes, (o) => {
          return o.activity_type_id === value;
        }).Activity_Type
      );

      let serviceTime = _.find(calculatorData?.data?.activityTypes, (o) => {
        return o.activity_type_id === value;
      }).service_time_hrs;
      let prepTime = _.find(calculatorData?.data?.activityTypes, (o) => {
        return o.activity_type_id === value;
      }).prep_time_hrs;

      setStndServiceTime(
        serviceTime && serviceTime !== null && serviceTime !== ""
          ? serviceTime
          : 0
      );
      setStndPrepTime(
        prepTime && prepTime !== null && prepTime !== "" ? prepTime : 0
      );
      if (
        calcId !== "" &&
        calcName !== "" &&
        userEmail !== "" &&
        userName !== "" &&
        sessionUuid !== ""
      ) {
        let data = {
          session_id: sessionUuid,
          calculator_id: calcId,
          calculator_name: calcName,
          email: userEmail,
          name: userName,
          control: "Activity Type",
          control_value: _.find(calculatorData?.data?.activityTypes, (o) => {
            return o.activity_type_id === value;
          }).Activity_Type,
          details:
            "Activity Type - chosen '" +
            _.find(calculatorData?.data?.activityTypes, (o) => {
              return o.activity_type_id === value;
            }).Activity_Type +
            "'",
        };
        setActivityTypeActivityData(data);
      }
    } else {
      setActivityTypeValue("");
      setStndServiceTime(0);
      setStndPrepTime(0);
    }
  };
//console.log({activityType});
  const OnSelectTravelDistance = (value) => {
    if (
      activityType === ""
    ) {
      setTravelDistance("");
    }
    else{
    setTravelDistance(value);
    }

    setIsRoundTrip("0");
    setIsRoundTripValue("No");

    setOverrideTravel(false);
    setOverrideTravelTime("");
    document.getElementById("txt_overrideTravelTime").value = "";
    setTravelTimeOverrideComment("");

    if (value && value !== "") {
      setTravelDistanceValue(
        _.find(calculatorData?.data?.travelDistances, (o) => {
          return o.travel_dist_id === value;
        }).title
      );

      let travelHr = _.find(calculatorData?.data?.travelDistances, (o) => {
        return o.travel_dist_id === value;
      }).time_hr;

      setSelectedTravelHr(
        travelHr && travelHr !== null && travelHr !== "" ? travelHr : 0
      );
      setStndTravelTime(
        travelHr && travelHr !== null && travelHr !== "" ? travelHr : 0
      );
      setDisableOverrideTravel(
        travelHr && travelHr !== null && travelHr !== "" ? false : true
      );
    } else {
      setTravelDistanceValue("");
      setSelectedTravelHr(0);
      setStndTravelTime(0);
      setDisableOverrideTravel(false);
    }
  };

  const OnSelectRoundTrip = (value) => {
    setIsRoundTrip(value);
    if (value === "0") {
      setIsRoundTripValue("No");
    } else if (value === "1") {
      setIsRoundTripValue("Yes");
    }
  };

  const OnOverrideService = (e) => {
    if (e.target.checked) {
      setOverrideService(true);
    } else {
      document.getElementById("txt_overrideServiceTime").value = "";
      setOverrideService(false);
      setOverrideServiceTime("");
      setServiceTimeOverrideComment("");
      setServiceTimeValidation(false);
      setServiceCommentValidation(false);
    }
  };

  const OnOverridePrep = (e) => {
    if (e.target.checked) {
      setOverridePrep(true);
    } else {
      document.getElementById("txt_overridePrepTime").value = "";
      setOverridePrep(false);
      setOverridePrepTime("");
      setPrepTimeOverrideComment("");
      setPrepTimeValidation(false);
      setPrepCommentValidation(false);
    }
  };

  const OnOverrideTravel = (e) => {
    if (e.target.checked) {
      setOverrideTravel(true);
    } else {
      document.getElementById("txt_overrideTravelTime").value = "";
      setOverrideTravel(false);
      setOverrideTravelTime("");
      setTravelTimeOverrideComment("");
      setTravelTimeValidation(false);
      setTravelTimeMaxValidation(false);
      setTravelCommentValidation(false);
    }
  };

  const OnBlurServiceOverride = (e) => {
    if (e.target.value && e.target.value !== "") {
      document.getElementById("txt_overrideServiceComment").focus();
      setServiceCommentValidation(true);
    } else {
      document.getElementById("txt_overrideServiceTime").focus();
      setServiceCommentValidation(false);
    }
  };
  const CheckOverrideServiceComment = (e) => {
    if (e.target.value === "") {
      document.getElementById("txt_overrideServiceComment").focus();
      setServiceCommentValidation(true);
      setOverrideServiceTime("");
    } else {
      setServiceCommentValidation(false);
      setOverrideServiceTime(
        document.getElementById("txt_overrideServiceTime").value
      );
    }
  };

  const OnBlurPrepOverride = (e) => {
    if (e.target.value && e.target.value !== "") {
      document.getElementById("txt_overridePrepComment").focus();
      setPrepCommentValidation(true);
    } else {
      document.getElementById("txt_overridePrepTime").focus();
      setPrepCommentValidation(false);
    }
  };
  const CheckOverridePrepComment = (e) => {
    if (e.target.value === "") {
      document.getElementById("txt_overridePrepComment").focus();
      setPrepCommentValidation(true);
      setOverridePrepTime("");
    } else {
      setPrepCommentValidation(false);
      setOverridePrepTime(
        document.getElementById("txt_overridePrepTime").value
      );
    }
  };

  const OnBlurTravelOverride = (e) => {
    if (e.target.value && e.target.value !== "") {
      if (e.target.value > getParsedValue(stndTravelTime)) {
        document.getElementById("txt_overrideTravelTime").focus();
        setTravelCommentValidation(false);
      } else {
        document.getElementById("txt_overrideTravelComment").focus();
        setTravelCommentValidation(true);
      }
    } else {
      document.getElementById("txt_overrideTravelTime").focus();
      setTravelCommentValidation(false);
    }
  };
  const CheckOverrideTravelComment = (e) => {
    if (e.target.value === "") {
      document.getElementById("txt_overrideTravelComment").focus();
      setTravelCommentValidation(true);
      setOverrideTravelTime("");
    } else {
      setTravelCommentValidation(false);
      setOverrideTravelTime(
        document.getElementById("txt_overrideTravelTime").value
      );
    }
  };

  const handleServiceTimeValidation = (event) => {
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      setServiceTimeValidation(true);
      event.preventDefault();
    } else {
      setServiceTimeValidation(false);
    }
  };

  const handlePrepTimeValidation = (event) => {
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      setPrepTimeValidation(true);
      event.preventDefault();
    } else {
      setPrepTimeValidation(false);
    }
  };

  const handleTravelTimeValidation = (event) => {
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      setTravelTimeValidation(true);
      event.preventDefault();
    } else {
      setTravelTimeValidation(false);
    }
  };

  const OnChangeTravelTimeOverride = (e) => {
    if (e.target.value > getParsedValue(stndTravelTime)) {
      setTravelTimeMaxValidation(true);
      e.preventDefault();
    } else {
      setTravelTimeMaxValidation(false);
    }
  };

  const ExportCalculatorToPDF = () => {
    dispatch(setIsExecutingAction(true));
    setIsDownloadcalcPdfSelected(true);
    CaptureExportToPDFActvity();
  };

  const CheckSeparator = (val) => {
    if (locale === "fr-FR") {
      let stringEng = val?.toLocaleString("en-US");
      let spaceStr = stringEng?.replace(/,/g, " ");
      let newStr = spaceStr?.replace(/\./g, ",");
      return newStr;
    } else {
      return val?.toLocaleString(locale);
    }
  };

  async function ReadFile(url) {
    return new Promise((accept, reject) => {
      var req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";

      req.onload = function (event) {
        var resp = req.response;
        if (resp) {
          accept(resp);
        }
      };
      req.send(null);
    });
  }

  function base64ArrayBuffer(arrayBuffer) {
    var base64 = "";
    var encodings =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63; // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3   = 2^2 - 1

      base64 += encodings[a] + encodings[b] + "==";
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15    = 2^4 - 1

      base64 += encodings[a] + encodings[b] + encodings[c] + "=";
    }
    return base64;
  }

  async function GetCustomFontTTF(language) {
    let transFont = "",
      base64Font = "";

    if (language.toLowerCase().includes("japanese")) {
      transFont = YuGothic;
    } else if (language.toLowerCase().includes("chinese")) {
      transFont = JhenGhei;
    } else if (language.toLowerCase().includes("hindi")) {
      transFont = Nirmala;
    } else {
      transFont = Segui;
    }

    if (transFont && transFont !== "")
      base64Font = base64ArrayBuffer(await ReadFile(transFont));

    return base64Font;
  }

  const ExportCalculatorToJSPDF = () => {
    CaptureExportToPDFActvity();
    dispatch(setIsExecutingAction(true));

    var headerElement = document.getElementById("calculator-header-info");
    Promise.all([
      new Promise((success, fail) => {
        if (headerElement) {
          html2canvas(headerElement).then((canvas) => {
            success(canvas);
          });
        } else {
          success(null);
        }
      }),
    ]).then(async (canvases, other) => {
      const doc = new jsPDF();

      const customFontTTF = await GetCustomFontTTF(selectedTransLanguage);
      doc.addFileToVFS("customfont-normal.ttf", customFontTTF);
      doc.addFont("customfont-normal.ttf", "CustomFont", "normal");
      doc.setFont("CustomFont");

      doc.addImage(jnjlogo, "PNG", 14, 4, 40, 7);
      if (isDraftVersion === true) {
        doc.setFontSize(13);
        doc.setTextColor("#EB1701");
        doc.text("DRAFT VERSION", doc.internal.pageSize.getWidth() / 2 - 16, 8);
        doc.setTextColor(0, 0, 0);
      }

      doc.setFontSize(12);
      doc.setTextColor("#EB1701");
      var splitTitle = doc.splitTextToSize(calcName, 90);
      var titleHeight =
        splitTitle && splitTitle.length > 0
          ? splitTitle.length === 1
            ? splitTitle.length * 17
            : splitTitle.length === 2
            ? splitTitle.length * 11
            : splitTitle.length === 3
            ? splitTitle.length * 9
            : splitTitle.length * 8
          : 8;

      doc.text(splitTitle, 14, 17);
      doc.setFontSize(7);
      doc.setTextColor(0, 100, 0);
      doc.text(
        "HCP FFS FMV Calculator " +
          (calculatorHeaderData !== " " ? calculatorHeaderData : "") +
          " - " +
          (isDraftVersion === true ? lastUpdatedLabel : lastPublishedLabel) +
          ": " +
          lastPublished,
        14,
        titleHeight + 5
      );
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
      doc.text(
        regionLabel +
          ": " +
          calcRegion +
          "  " +
          countryLabel +
          ": " +
          (contrySectorNameData?.country_name?.label !== ""
            ? contrySectorNameData?.country_name?.label
            : ""),
        doc.internal.pageSize.getWidth() - 14,
        16,
        "right"
      );
      if (!isPreviewCalculatorData) {
        let sectorsToMap = _.map(
          _.filter(calculatorData?.data?.sectors, (o) => {
            return (
              o.sector.toLowerCase() !==
              contrySectorNameData?.sector_name?.label?.toLowerCase()
            );
          }),
          (obj) => {
            return obj.sector;
          }
        ).join(", ");
        if (sectorsToMap && sectorsToMap !== null && sectorsToMap !== "") {
          doc.setFontSize(7);

          let startX = doc.internal.pageSize.getWidth() - 14;
          const inputValue =
            sectorLabel +
            ": **" +
            contrySectorNameData?.sector_name?.label +
            "**, " +
            sectorsToMap;
          const reverseArray = inputValue.split("**");
          const arrayOfCustomText = reverseArray?.reverse();
          arrayOfCustomText.map((text, i) => {
            doc.setTextColor("#EB1701");
            if (i % 2 === 0) {
              doc.setTextColor(0, 0, 0);
            }
            doc.text(text, startX, 19, "right");
            startX = startX - doc.getStringUnitWidth(text) * 2.5;
            return text;
          });
        } else {
          doc.setFontSize(7);
          let startX = doc.internal.pageSize.getWidth() - 14;
          const inputValue =
            sectorLabel +
            ": **" +
            contrySectorNameData?.sector_name?.label +
            "**";
          const reverseArray = inputValue.split("**");
          const arrayOfCustomText = reverseArray?.reverse();
          arrayOfCustomText.map((text, i) => {
            doc.setTextColor("#EB1701");
            if (i % 2 === 0) {
              doc.setTextColor(0, 0, 0);
            }
            doc.text(text, startX, 19, "right");
            startX = startX - doc.getStringUnitWidth(text) * 2.5;
            return text;
          });
        }
      } else {
        doc.text(
          sectorLabel + ": " + calcSectors,
          doc.internal.pageSize.getWidth() - 14,
          19,
          "right"
        );
      }
      doc.setTextColor(0, 0, 0);
      doc.autoTable({
        startY: titleHeight + 12,
        bodyStyles: {
          fontSize: 6,
          fontStyle: "bold",
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: { font: "CustomFont" },
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 46 },
          2: { cellWidth: 45 },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: completedbyLabel,
            },
            {
              content: userName,
            },
            {
              content: "WWID",
            },
            {
              content: userWWID,
            },
          ],
          [
            {
              content: referenceNumberLabel,
            },
            {
              content: referenceNumber,
            },
            {
              content: referenceCategoryLabel,
            },
            {
              content: referenceCategoryValue,
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 7,
        bodyStyles: {
          fontSize: 6,
          fontStyle: "bold",
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: { fillColor: "#EB1701", fontSize: 7, font: "CustomFont" },
        head: [
          [
            {
              content: calculationInputsLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 46 },
          2: { cellWidth: 45 },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: baseRateLabel,
            },
            {
              content: CheckSeparator(baseRate),
            },
            {
              content: ratePerHourCapLabel,
            },
            {
              content:
                mostRestrictiveRateCap === undefined ||
                mostRestrictiveRateCap === null
                  ? "N/A"
                  : CheckSeparator(mostRestrictiveRateCap),
            },
          ],
          [
            {
              content: currencyLabel,
            },
            {
              content: currency,
            },
            {
              content: sessionCapLabel,
            },
            {
              content:
                mostRestrictiveSessionCap === undefined ||
                mostRestrictiveSessionCap === null
                  ? "N/A"
                  : CheckSeparator(mostRestrictiveSessionCap),
            },
          ],
          [
            {
              content: hcpSpecialtyLabel,
            },
            {
              content: hcpSpecialtyValue,
              styles: { fontStyle: "courier" },
            },
            {
              content: !hideSpecialityMultiplier
                ? specialtyMultiplierLabel
                : "",
            },
            {
              content: !hideSpecialityMultiplier
                ? CheckSeparator(hcpSpecialtyMultiplier)
                : "",
              styles: { fontStyle: "courier" },
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 0,
        bodyStyles: {
          fontSize: 6,
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: {
          textColor: "#000000",
          fillColor: "#EB1701",
          fontSize: 7,
          font: "CustomFont",
        },
        head: [
          [
            {
              content: selectTheFollowingAfterHcpSpecialtyIsChosenLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#d9d9d9" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45, fontStyle: "bold" },
          1: { cellWidth: 46 },
          2: { cellWidth: 45, fontStyle: "bold" },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: hcpStatureKolStatusLabel,
            },
            {
              content: hcpStatureValue,
            },
            {
              content: !hideStatureMultiplier ? kolMultiplierLabel : "",
            },
            {
              content: !hideStatureMultiplier
                ? CheckSeparator(hcpStatureMultiplier)
                : "",
            },
          ],
          [
            {
              content: activityTypeLabel,
            },
            {
              content: activityTypeValue,
            },
            {
              content: "",
            },
            {
              content: "",
            },
          ],
          [
            {
              content: travelDistanceOneWayLabel,
            },
            {
              content: CheckSeparator(travelDistanceValue),
            },
            {
              content: "",
            },
            {
              content: "",
            },
          ],
          [
            {
              content: IsTravelRoundTripLabel,
            },
            {
              content: isRoundTripValue,
            },
            {
              content: "",
            },
            {
              content: "",
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 0,
        bodyStyles: {
          fontSize: 6,
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: {
          textColor: "#000000",
          fillColor: "#EB1701",
          fontSize: 7,
          font: "CustomFont",
        },
        head: [
          [
            {
              content: selectHoursLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#d9d9d9" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45, fontStyle: "bold" },
          1: { cellWidth: 46 },
          2: { cellWidth: 45 },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: typeLabel,
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: standardHoursLabel,
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: overrideHoursLabel,
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: commentsLabel,
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: activityTypeServiceTimeLabel,
            },
            {
              content: stndServiceTime,
            },
            {
              content: overrideServiceTime,
            },
            {
              content: serviceTimeOverrideComment,
            },
          ],
          [
            {
              content: activityTypePrepTimeLabel,
            },
            {
              content: stndPrepTime,
            },
            {
              content: overridePrepTime,
            },
            {
              content: prepTimeOverrideComment,
            },
          ],
          [
            {
              content: activityLocationTravelTimeLabel,
            },
            {
              content: stndTravelTime,
            },
            {
              content: overrideTravelTime,
            },
            {
              content: travelTimeOverrideComment,
            },
          ],
          [
            {
              content: travelTimeOfHourlyRateLabel,
            },
            {
              content: travelTimeDiscount,
            },
            {
              content: "",
            },
            {
              content: "",
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 7,
        bodyStyles: {
          fontSize: 6,
          fontStyle: "bold",
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: { fillColor: "#EB1701", fontSize: 7, font: "CustomFont" },
        head: [
          [
            {
              content: calculatedfmvrangeLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 46 },
          2: { cellWidth: 45 },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: "",
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: showMinRange ? minimumRateLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? midLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? maximumRateLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: fmvRatePerHourRangeLabel + "(" + currency + ")",
            },
            {
              content: showMinRange ? CheckSeparator(minHourlyRate) : "",
              styles: {
                textColor: isMinHourlyRateCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: showMidRange ? CheckSeparator(hourlyRate) : "",
              styles: {
                textColor: isHourlyRateCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: showMaxRange ? CheckSeparator(maxHourlyRate) : "",
              styles: {
                textColor: isMaxHourlyRateCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
          ],
          [
            {
              content: "",
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: showMinRange ? minimumLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? midLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? maximumLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: fmvTotalCompensationLabel + "(" + currency + ")",
              styles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
            },
            {
              content: showMinRange ? CheckSeparator(minTotalCompensation) : "",
              styles: {
                textColor: isMinCompensationCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: "center",
              },
            },
            {
              content: showMidRange ? CheckSeparator(totalCompensation) : "",
              styles: {
                textColor: isTotalCompensationCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: "center",
              },
            },
            {
              content: showMaxRange ? CheckSeparator(maxTotalCompensation) : "",
              styles: {
                textColor: isMaxCompensationCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: "center",
              },
            },
          ],
        ],
        theme: "grid",
      });
      doc.setFontSize(7);
      doc.setTextColor(255, 0, 0);
      var splitTitleMsg = doc.splitTextToSize(stripHtml(globalCapMessage), 180);
      doc.text(
        showCapExceededMssg ? splitTitleMsg : "",
        14,
        doc.lastAutoTable.finalY + 4
      );
      doc.autoTable({
        startY: showCapExceededMssg
          ? doc.lastAutoTable.finalY + 13
          : doc.lastAutoTable.finalY + 7,
        bodyStyles: {
          fontSize: 6,
          fontStyle: "bold",
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: { fillColor: "#EB1701", fontSize: 7, font: "CustomFont" },
        head: [
          [
            {
              content: calculationDetailsLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 46 },
          2: { cellWidth: 45 },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: localCurrencyLabel,
            },
            {
              content: currency,
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: totalTimeHrsLabel,
            },
            {
              content: CheckSeparator(totalHours),
              styles: {
                textColor: hourCapApplied ? "#EB1701" : "#000000",
                fontStyle: "courier",
                halign: "center",
              },
            },
          ],
          [
            {
              content: baseRateInLocalCurrencyLabel,
            },
            {
              content: CheckSeparator(baseRate),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: hourlyCapHrsLabel,
            },
            {
              content:
                mostRestrictiveHourCap === undefined ||
                mostRestrictiveHourCap === null
                  ? "N/A"
                  : CheckSeparator(mostRestrictiveHourCap),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: serviceTimeHrsLabel,
            },
            {
              content:
                overrideService && overrideServiceTime !== ""
                  ? CheckSeparator(overrideServiceTime)
                  : CheckSeparator(stndServiceTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: adjServiceTimeHrsLabel,
            },
            {
              content:
                overrideService && overrideServiceTime !== ""
                  ? CheckSeparator(overrideServiceTime)
                  : CheckSeparator(stndServiceTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: prepTimeHrsLabel,
            },
            {
              content:
                overridePrep && overridePrepTime !== ""
                  ? CheckSeparator(overridePrepTime)
                  : CheckSeparator(stndPrepTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: adjPrepTimeHrsLabel,
            },
            {
              content:
                overridePrep && overridePrepTime !== ""
                  ? CheckSeparator(overridePrepTime)
                  : CheckSeparator(stndPrepTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: travelTimeHrsLabel,
            },
            {
              content:
                overrideTravel && overrideTravelTime !== ""
                  ? CheckSeparator(overrideTravelTime)
                  : CheckSeparator(stndTravelTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: adjTravelTimeHrsLabel,
            },
            {
              content:
                overrideTravel && overrideTravelTime !== ""
                  ? CheckSeparator(overrideTravelTime)
                  : CheckSeparator(stndTravelTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 0,
        bodyStyles: {
          fontSize: 6,
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: {
          textColor: "#000000",
          fillColor: "#EB1701",
          fontSize: 7,
          font: "CustomFont",
        },
        head: [
          [
            {
              content: fmvRatePerHourLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#d9d9d9" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45, fontStyle: "bold" },
          1: { cellWidth: 46 },
          2: { cellWidth: 45, lineWidth: 0 },
          3: { cellWidth: 46, lineWidth: 0 },
        },
        body: [
          !hideSpecialityMultiplier
            ? [
                {
                  content: hcpSpecialtyMultiplierLabel,
                  styles: { fontStyle: "courier", halign: "left" },
                },
                {
                  content: CheckSeparator(hcpSpecialtyMultiplier),
                  styles: { fontStyle: "courier", halign: "center" },
                },
              ]
            : "",
          !hideStatureMultiplier
            ? [
                {
                  content: hcpStatureMultiplierLabel,
                  styles: { fontStyle: "courier", halign: "left" },
                },
                {
                  content: CheckSeparator(hcpStatureMultiplier),
                  styles: { fontStyle: "courier", halign: "center" },
                },
              ]
            : "",
          [
            {
              content: calculatedRatePerHourLabel,
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: CheckSeparator(hourlyRate),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: minRangeLabel,
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: minRange + (showMinRange ? "%" : ""),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: maxRangeLabel,
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: maxRange + (showMaxRange ? "%" : ""),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 0,
        bodyStyles: {
          fontSize: 6,
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        columnStyles: {
          0: { cellWidth: 45, fontStyle: "bold" },
          1: { cellWidth: 46 },
          2: { cellWidth: 45 },
          3: { cellWidth: 46 },
        },
        body: [
          [
            {
              content: "",
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: showMinRange ? minimumRateLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? midLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? maximumRateLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: fmvRatePerHourRangeLabel,
            },
            {
              content: showMinRange ? CheckSeparator(minHourlyRate) : "",
              styles: {
                textColor: isMinHourlyRateCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: showMidRange ? CheckSeparator(hourlyRate) : "",
              styles: {
                textColor: isHourlyRateCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: showMaxRange ? CheckSeparator(maxHourlyRate) : "",
              styles: {
                textColor: isMaxHourlyRateCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 0,
        bodyStyles: {
          fontSize: 6,
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: {
          textColor: "#000000",
          fillColor: "#EB1701",
          fontSize: 7,
          font: "CustomFont",
        },
        head: [
          [
            {
              content: totalFeePerActivityLabel,
              colSpan: 4,
              styles: { halign: "left", fillColor: "#d9d9d9" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45, fontStyle: "bold" },
          1: { cellWidth: 46, fontStyle: "bold" },
          2: { cellWidth: 45, fontStyle: "bold" },
          3: { cellWidth: 46, fontStyle: "bold" },
        },
        body: [
          [
            {
              content: "",
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: showMinRange ? minimumFeeLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? midLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? maximumFeeLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content:
                travelTimeLabel +
                " (" +
                (overrideTravel ? overrideTravelTime : stndTravelTime) +
                " " +
                hoursLabel +
                ")",
              styles: { fontStyle: "courier" },
            },
            {
              content: showMinRange ? CheckSeparator(minTravelCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(travelCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? CheckSeparator(maxTravelCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content:
                serviceTimeLabel +
                " (" +
                (overrideService ? overrideServiceTime : stndServiceTime) +
                " " +
                hoursLabel +
                ")",
              styles: { fontStyle: "courier" },
            },
            {
              content: showMinRange ? CheckSeparator(minServiceCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(serviceCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? CheckSeparator(maxServiceCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: costOfEventLabel,
            },
            {
              content: showMinRange ? CheckSeparator(minEventCost) : "",
              styles: { halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(eventCost) : "",
              styles: { halign: "center" },
            },
            {
              content: showMaxRange ? CheckSeparator(maxEventCost) : "",
              styles: { halign: "center" },
            },
          ],
          [
            {
              content:
                prepTimeCostLabel +
                " (" +
                (overridePrep ? overridePrepTime : stndPrepTime) +
                " " +
                hoursLabel +
                ")",
              styles: { fontStyle: "courier" },
            },
            {
              content: showMinRange ? CheckSeparator(minPrepCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(prepCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: maxPrepCost ? CheckSeparator(maxPrepCost) : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: totalCostLabel,
            },
            {
              content: showMinRange ? CheckSeparator(minTotalCost) : "",
              styles: { halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(totalCost) : "",
              styles: { halign: "center" },
            },
            {
              content: showMaxRange ? CheckSeparator(maxTotalCost) : "",
              styles: { halign: "center" },
            },
          ],
          [
            {
              content: totalCostExclPrepTimeLabel,
            },
            {
              content: showMinRange ? CheckSeparator(minTotalCostExclPrep) : "",
              styles: { halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(totalCostExclPrep) : "",
              styles: { halign: "center" },
            },
            {
              content: showMaxRange ? CheckSeparator(maxTotalCostExclPrep) : "",
              styles: { halign: "center" },
            },
          ],
          [
            {
              content: totalCostExclTravelTimeLabel,
            },
            {
              content: showMinRange
                ? CheckSeparator(minTotalCostExclTravel)
                : "",
              styles: { halign: "center" },
            },
            {
              content: showMidRange ? CheckSeparator(totalCostExclTravel) : "",
              styles: { halign: "center" },
            },
            {
              content: showMaxRange
                ? CheckSeparator(maxTotalCostExclTravel)
                : "",
              styles: { halign: "center" },
            },
          ],
          [
            {
              content: "",
              styles: { fontStyle: "courier", halign: "left" },
            },
            {
              content: showMinRange ? minimumLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMidRange ? midLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: showMaxRange ? maximumLabel : "",
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: fmvTotalCompensationLabel,
              styles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
            },
            {
              content: showMinRange ? CheckSeparator(minTotalCompensation) : "",
              styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                textColor: isMinCompensationCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: showMidRange ? CheckSeparator(totalCompensation) : "",
              styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                textColor: isTotalCompensationCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: showMaxRange ? CheckSeparator(maxTotalCompensation) : "",
              styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                textColor: isMaxCompensationCapped ? "#EB1701" : "#000000",
                fontStyle: "bold",
                halign: "center",
              },
            },
          ],
        ],
        theme: "grid",
      });
      doc.setFontSize(7);
      doc.setTextColor(255, 0, 0);
      var splitTitleCap = doc.splitTextToSize(stripHtml(globalCapMessage), 180);
      doc.text(
        showCapExceededMssg ? splitTitleCap : "",
        14,
        doc.lastAutoTable.finalY + 4
      );
      doc.autoTable({
        startY: showCapExceededMssg
          ? doc.lastAutoTable.finalY + 13
          : doc.lastAutoTable.finalY + 7,
        bodyStyles: {
          fontSize: 6,
          fontStyle: "bold",
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        headStyles: { fillColor: "#EB1701", fontSize: 7, font: "CustomFont" },
        head: [
          [
            {
              content: calculatedCompensationWithoutCapsLabel,
              colSpan: 5,
              styles: { halign: "left", fillColor: "#EB1701" },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 35 },
          2: { cellWidth: 34 },
          3: { cellWidth: 34 },
          4: { cellWidth: 34 },
        },
        body: [
          [
            {
              content: memoInformationPurposeOnlyLabel,
              colSpan: 5,
              styles: { fontSize: 7, lineWidth: 0 },
            },
          ],
          [
            {
              content: "",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: timeLabel,
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: "%",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: ratePerHourLabel,
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: totalCostLabel,
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: travelLabel,
              styles: { fontStyle: "courier" },
            },
            {
              content:
                overrideTravel && overrideTravelTime !== ""
                  ? CheckSeparator(overrideTravelTime)
                  : CheckSeparator(stndTravelTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: travelTimeDiscount,
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(actualHourlyRate),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(actualTravelCost),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: serviceLabel,
              styles: { fontStyle: "courier" },
            },
            {
              content:
                overrideService && overrideServiceTime !== ""
                  ? CheckSeparator(overrideServiceTime)
                  : CheckSeparator(stndServiceTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: "100%",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(actualHourlyRate),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(actualServiceCost),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: preparationLabel,
              styles: { fontStyle: "courier" },
            },
            {
              content:
                overridePrep && overridePrepTime !== ""
                  ? CheckSeparator(overridePrepTime)
                  : CheckSeparator(stndPrepTime),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: "100%",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(actualHourlyRate),
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(actualPrepCost),
              styles: { fontStyle: "courier", halign: "center" },
            },
          ],
          [
            {
              content: totalLabel,
              styles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
            },
            {
              content: CheckSeparator(actualTotalHours),
              styles: {
                fontStyle: "courier",
                halign: "center",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
              },
            },
            {
              content: "",
              styles: {
                fontStyle: "courier",
                halign: "center",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
              },
            },
            {
              content: "",
              styles: {
                fontStyle: "courier",
                halign: "center",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
              },
            },
            {
              content: CheckSeparator(actualTotalCost),
              styles: {
                fontStyle: "bold",
                halign: "center",
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
              },
            },
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 0.3,
        bodyStyles: {
          fontSize: 6,
          fontStyle: "bold",
          lineColor: [240, 240, 240],
          font: "CustomFont",
        },
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 35 },
          2: { cellWidth: 34 },
          3: { cellWidth: 34 },
          4: { cellWidth: 34 },
        },
        body: [
          [
            {
              content: "",
            },
            {
              content: "",
            },
            {
              content: "",
            },
            {
              content: "Min.",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(minActualTotalCost),
              styles: { fontStyle: "bold", halign: "center" },
            },
          ],
          [
            {
              content: "",
            },
            {
              content: "",
            },
            {
              content: "",
            },
            {
              content: "Max.",
              styles: { fontStyle: "courier", halign: "center" },
            },
            {
              content: CheckSeparator(maxActualTotalCost),
              styles: { fontStyle: "bold", halign: "center" },
            },
          ],
        ],
        theme: "grid",
      });

      var date = new Date();
      var dtProps = date.toDateString().substring(4).split(" ");
      var datesuffix = dtProps[2] + "-" + dtProps[0] + "-" + dtProps[1];
      var fileName =
        datesuffix + "_" + userID?.toUpperCase() + "_FMV_Calculator.pdf";

      doc.save(fileName);
      dispatch(setIsExecutingAction(false));
    });
  };

  function stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  return (
    <div>
      <div className="fmv-calculator" id="div-fmv-calculator">
        <Container className="calculation-inputs">
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {completedbyLabel}
            </Col>
            <Col className="label-name" md={2}>
              {userName}
            </Col>
            <Col className="label-name" md={2}>
              WWID
            </Col>
            <Col className="label-name" md={2}>
              {userWWID}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {referenceNumberLabel}
            </Col>
            <Col className="label-name" md={2}>
              <input
                type="text"
                min={0}
                id="calculator_referenceNumber_id"
                value={referenceNumber}
                onChange={OnChangeReferenceNumber}
                onBlur={OnBlurReferenceNumber}
              />
              <span
                id="pdftext_calculator_referenceNumber_id"
                className="display-text-for-pdf"
              >
                {referenceNumber}
              </span>
            </Col>
            <Col className="label-name" md={2}>
              {referenceCategoryLabel}
            </Col>
            <Col className="label-name" md={2}>
              <DropDownComp
                elementId={"dd_ref_category"}
                listObj={refCategoryList}
                objId={referenceCategory}
                callOnChange={OnChangeReferenceCategory}
                disabled={referenceNumber === null || referenceNumber === ""}
              ></DropDownComp>
            </Col>
          </Row>
          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <span
                className={
                  referenceValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *{pleaseSelectReferenceCategoryLabel}
              </span>
            </Col>
          </Row>
          <Row className="data-row"></Row>
          <Row>
            <Col className="calc-section-header" md={12}>
              {calculationInputsLabel}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {baseRateLabel}
            </Col>
            <Col className="label-name" md={4}>
              {baseRate.toLocaleString(locale)}
            </Col>
            <Col className="label-name" md={2}>
              {ratePerHourCapLabel}
            </Col>
            <Col className="label-name" md={1}>
              {mostRestrictiveRateCap === undefined ||
              mostRestrictiveRateCap === null
                ? "N/A"
                : mostRestrictiveRateCap.toLocaleString(locale)}
            </Col>
          </Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {currencyLabel}
            </Col>
            <Col className="label-name" md={4}>
              {currency}
            </Col>
            <Col className="label-name" md={2}>
              {sessionCapLabel}
            </Col>
            <Col className="label-name" md={1}>
              {mostRestrictiveSessionCap === undefined ||
              mostRestrictiveSessionCap === null
                ? "N/A"
                : mostRestrictiveSessionCap.toLocaleString(locale)}
            </Col>
          </Row>
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
          <Row>
            <Col className="label-name" md={2}>
              {hcpSpecialtyLabel}
              <span className="asterisk-mandatory">*</span>
            </Col>
            <Col
              md={3}
              id="calculator_hcpspecialitycol_id"
              className="hcp-speciality-select-style"
            >
              <Select
                onChange={OnSelectSpecialty}
                options={hcpSpecialtiesList}
                getOptionValue={(option) => option.name}
                placeholder={"Select"}
              />
            </Col>
            <Col
              id="pdftext_calculator_hcpspecialitycol_id"
              className="display-text-for-pdf"
              md={4}
            >
              <span>{hcpSpecialtyValue}</span>
            </Col>
            <Col md={1} id="calculator_hcpspecialitycol_space_id"></Col>
            <Col className="label-name" md={2}>
              {!hideSpecialityMultiplier ? specialtyMultiplierLabel : ""}
            </Col>
            <Col md={2}>
              {!hideSpecialityMultiplier ? (
                <input
                  id="calculator_specialitymultiplier_id"
                  type="text"
                  value={hcpSpecialtyMultiplier?.toLocaleString(locale)}
                  disabled
                />
              ) : (
                ""
              )}
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col className="section-header" md={12}>
              {selectTheFollowingAfterHcpSpecialtyIsChosenLabel}
            </Col>
          </Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {hcpStatureKolStatusLabel}
              <span className="asterisk-mandatory">*</span>
            </Col>
            <Col id="pdfcol_calculator_hcpStatureKolStatus_id" md={3}>
              <DropDownComp
                elementId={"calculator_hcpStatureKolStatus_id"}
                listObj={hcpStaturesList}
                callOnChange={OnSelectStature}
                objId={hcpStature}
              ></DropDownComp>
            </Col>
            <Col
              id="pdftext_calculator_hcpStatureKolStatus_id"
              className="display-text-for-pdf"
              md={4}
            >
              <span>{hcpStatureValue}</span>
            </Col>
            <Col id="pdf_calculator_hcpStatureKolStatus_space_id" md={1}></Col>
            <Col className="label-name" md={2}>
              {!hideStatureMultiplier ? kolMultiplierLabel : ""}
            </Col>
            <Col md={2}>
              {!hideStatureMultiplier ? (
                <input
                  id="calculator_kolmultiplier_id"
                  type="text"
                  value={hcpStatureMultiplier?.toLocaleString(locale)}
                  disabled
                />
              ) : (
                ""
              )}
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {activityTypeLabel}
              <span className="asterisk-mandatory">*</span>
            </Col>
            <Col md={3} id="pdfcol_calculator_activityTypeselect_id">
              <DropDownComp
                elementId={"calculator_activityTypeselect_id"}
                listObj={activityTypesList}
                callOnChange={OnSelectActivityType}
                objId={activityType}
              ></DropDownComp>
            </Col>
            <Col
              md={10}
              id="pdftext_calculator_activityTypeselect_id"
              className="display-text-for-pdf"
            >
              <span>{activityTypeValue}</span>
            </Col>
            <Col id="pdf_calculator_activityTypeselect_id_space" md={7}></Col>
          </Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {travelDistanceOneWayLabel}
              <span className="asterisk-mandatory">*</span>
            </Col>
            <Col id="pdfcol_calculator_travelDistanceOneWay_id" md={3}>
              <DropDownComp
                elementId={"calculator_travelDistanceOneWay_id"}
                listObj={travelDistancesList}
                callOnChange={OnSelectTravelDistance}
                objId={travelDistance}
              ></DropDownComp>
            </Col>
            <Col
              id="pdftext_calculator_travelDistanceOneWay_id"
              className="display-text-for-pdf"
              md={4}
            >
              <span>{travelDistanceValue}</span>
            </Col>
            <Col id="pdf_calculator_travelDistanceOneWay_id_space" md={1}></Col>
            <Col md={6}>
              <div
                className="determine_traveldistance_link"
                onClick={() =>
                  window.open(
                    "https://www.freemaptools.com/how-far-is-it-between.htm"
                  )
                }
              >
                {determineTraveldistanceLinkLabel}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {IsTravelRoundTripLabel}
            </Col>
            <Col md={3}>
              <select
                id="calculator_isroundtrip_id"
                className="select-config"
                disabled={selectedTravelHr === 0}
                value={isRoundTrip}
                onChange={(e) => OnSelectRoundTrip(e.target.value)}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </Col>
            <Col md={7}></Col>
          </Row>
          <Row>
            <Col className="section-header" md={12}>
              {selectHoursLabel}
            </Col>
          </Row>
          <Row className="data-row">
            <Col md={2}>{typeLabel}</Col>
            <Col className="align-middle" md={2}>
              {standardHoursLabel}
            </Col>
            <Col md={1}></Col>
            <Col className="align-middle" md={2}>
              {overrideHoursLabel}
            </Col>
            <Col className="align-middle" md={5}>
              {commentsLabel}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {activityTypeServiceTimeLabel}
            </Col>
            <Col md={2}>
              <input
                id="calculator_activity_type_id"
                type="number"
                value={stndServiceTime}
                min={0}
                disabled
              />
            </Col>
            <Col className="label-name" md={1}>
              <div className="input-check">
                <input
                  type="checkbox"
                  id="chkServiceTime"
                  onChange={OnOverrideService}
                  checked={overrideService}
                />
                <label htmlFor="chkServiceTime">{overrideLabel}</label>
              </div>
            </Col>
            <Col md={2}>
              <input
                type="number"
                id="txt_overrideServiceTime"
                min={0}
                onKeyDown={handleServiceTimeValidation}
                onBlur={OnBlurServiceOverride}
                disabled={!overrideService}
              />
            </Col>
            <Col md={5}>
              <input
                type="text"
                id="txt_overrideServiceComment"
                min={0}
                placeholder={
                  pleaseEnterCommentsForOverridingServiceTimeLabel + "..."
                }
                value={serviceTimeOverrideComment}
                onChange={(e) => setServiceTimeOverrideComment(e.target.value)}
                onBlur={CheckOverrideServiceComment}
                disabled={!overrideService}
              />
              <span
                id="pdf_txt_overrideServiceComment"
                className="display-text-for-pdf"
              >
                {serviceTimeOverrideComment}
              </span>
            </Col>
          </Row>
          <Row className="data-row">
            <Col md={5}></Col>
            <Col md={2}>
              <span
                className={
                  serviceTimeValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *{onlyNumbersAreAllowedLabel}
              </span>
            </Col>
            <Col md={5} className="align-right">
              <span
                className={
                  serviceCommentValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *{pleaseEnterCommentsLabel}
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {activityTypePrepTimeLabel}
            </Col>
            <Col md={2}>
              <input
                id="calculator_activity_type_prepTime_id"
                type="number"
                value={stndPrepTime}
                min={0}
                disabled
              />
            </Col>
            <Col className="label-name" md={1}>
              <div className="input-check">
                <input
                  type="checkbox"
                  id="chkPrepTime"
                  onChange={OnOverridePrep}
                  checked={overridePrep}
                />
                <label htmlFor="chkPrepTime">{overrideLabel}</label>
              </div>
            </Col>
            <Col md={2}>
              <input
                type="number"
                id="txt_overridePrepTime"
                min={0}
                onKeyDown={handlePrepTimeValidation}
                onBlur={OnBlurPrepOverride}
                disabled={!overridePrep}
              />
            </Col>
            <Col md={5}>
              <input
                type="text"
                id="txt_overridePrepComment"
                min={0}
                placeholder={
                  pleaseEnterCommentsForOverridingPrepTimeLabel + "..."
                }
                value={prepTimeOverrideComment}
                onChange={(e) => setPrepTimeOverrideComment(e.target.value)}
                onBlur={CheckOverridePrepComment}
                disabled={!overridePrep}
              />
              <span
                id="pdf_txt_overridePrepComment"
                className="display-text-for-pdf"
              >
                {prepTimeOverrideComment}
              </span>
            </Col>
          </Row>
          <Row className="data-row">
            <Col md={5}></Col>
            <Col md={2}>
              <span
                className={
                  prepTimeValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *{onlyNumbersAreAllowedLabel}
              </span>
            </Col>
            <Col md={5} className="align-right">
              <span
                className={
                  prepCommentValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *{pleaseEnterCommentsLabel}
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {activityLocationTravelTimeLabel}
            </Col>
            <Col md={2}>
              <input
                id="calculator_activity_location_traveltime_type_id"
                type="number"
                value={stndTravelTime}
                min={0}
                disabled
              />
            </Col>
            <Col className="label-name" md={1}>
              <div className="input-check">
                <input
                  type="checkbox"
                  id="chkTravelTime"
                  onChange={OnOverrideTravel}
                  checked={overrideTravel}
                  disabled={disableOverrideTravel}
                />
                <label htmlFor="chkTravelTime">{overrideLabel}</label>
              </div>
            </Col>
            <Col md={2}>
              <input
                type="number"
                id="txt_overrideTravelTime"
                min={0}
                onChange={OnChangeTravelTimeOverride}
                onKeyDown={handleTravelTimeValidation}
                onBlur={OnBlurTravelOverride}
                disabled={!overrideTravel}
              />
            </Col>
            <Col md={5}>
              <input
                type="text"
                id="txt_overrideTravelComment"
                min={0}
                placeholder={
                  pleaseEnterCommentsForOverridingTravelTimeLabel + "..."
                }
                value={travelTimeOverrideComment}
                onChange={(e) => setTravelTimeOverrideComment(e.target.value)}
                onBlur={CheckOverrideTravelComment}
                disabled={!overrideTravel}
              />
              <span
                id="pdf_txt_overrideTravelComment"
                className="display-text-for-pdf"
              >
                {travelTimeOverrideComment}
              </span>
            </Col>
          </Row>
          <Row className="data-row">
            <Col md={2}></Col>
            <Col className="label-name" md={3}>
              {isRoundTrip === "1" ? (
                <span className={overrideTravel ? "no-show" : ""}>
                  {" "}
                  Corresponds to {stndTravelTime} hours for a round trip
                </span>
              ) : (
                ""
              )}
            </Col>
            <Col md={5}>
              <span
                className={
                  travelTimeValidation || travelTimeMaxValidation
                    ? "col-message calc-validation-msg"
                    : "no-show"
                }
              >
                {travelTimeValidation ? (
                  <span>*{onlyNumbersAreAllowedLabel}</span>
                ) : travelTimeMaxValidation ? (
                  <span>*{travelTimeMaxValidationMsgLabel}</span>
                ) : (
                  ""
                )}
              </span>
            </Col>
            <Col md={2} className="align-right">
              <span
                className={
                  travelCommentValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *{pleaseEnterCommentsLabel}
              </span>
            </Col>
          </Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {travelTimeOfHourlyRateLabel}
            </Col>
            <Col md={2}>
              <input
                id="calculator_traveltimehourlyrate_id"
                type="number"
                value={travelTime ? travelTime : travelTimeDiscount}
                min={0}
                disabled
              />
            </Col>
            <Col md={8}></Col>
          </Row>
          <Row className="data-row"></Row>
        </Container>

        <Container className="calculation-detail">
          <Row>
            <Col className="calc-section-header" md={12}>
              {calculatedfmvrangeLabel}
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            {showMinRange ? (
              <Col md={2} className="align-middle">
                {minimumRateLabel}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col md={2} className="align-middle">
                {midLabel}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col md={2} className="align-middle">
                {maximumRateLabel}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row">
            <Col md={3} className="label-name">
              {fmvRatePerHourRangeLabel}({currency})
            </Col>
            {showMinRange ? (
              <Col
                md={2}
                className={
                  isMinHourlyRateCapped
                    ? "label-name align-middle highlight"
                    : "label-name align-middle"
                }
              >
                {minHourlyRate.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col
                md={2}
                className={
                  isHourlyRateCapped
                    ? "label-name align-middle highlight"
                    : "label-name align-middle"
                }
              >
                {hourlyRate.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col
                md={2}
                className={
                  isMaxHourlyRateCapped
                    ? "label-name align-middle highlight"
                    : "label-name align-middle"
                }
              >
                {maxHourlyRate.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row"></Row>
          <Row>
            <Col md={3}></Col>
            {showMinRange ? (
              <Col md={2} className="align-middle">
                {minimumLabel}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col md={2} className="align-middle">
                {midLabel}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col md={2} className="align-middle">
                {maximumLabel}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row label-bold table-row">
            <Col md={3}>
              {fmvTotalCompensationLabel}({currency})
            </Col>
            {showMinRange ? (
              <Col
                md={2}
                className={
                  isMinCompensationCapped
                    ? "align-middle highlight"
                    : "align-middle"
                }
              >
                {minTotalCompensation.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col
                md={2}
                className={
                  isTotalCompensationCapped
                    ? "align-middle highlight"
                    : "align-middle"
                }
              >
                {totalCompensation.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col
                md={2}
                className={
                  isMaxCompensationCapped
                    ? "align-middle highlight"
                    : "align-middle"
                }
              >
                {maxTotalCompensation.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className={showCapExceededMssg ? "" : "no-show"}>
            <Col
              md={12}
              className="col-message calc-validation-msg inherit-font"
            >
              <RenderRichText html={globalCapMessage} />
            </Col>
          </Row>
          <Row>
            <Col className="calc-section-header" md={12}>
              {calculationDetailsLabel}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {localCurrencyLabel}
            </Col>
            <Col className="table-col" md={2}>
              {currency}
            </Col>
            <Col className="label-name" md={2}>
              {totalTimeHrsLabel}
            </Col>
            <Col
              className={
                hourCapApplied ? "table-col label-name highlight" : "table-col"
              }
              md={2}
            >
              {totalHours?.toLocaleString(locale)}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {baseRateInLocalCurrencyLabel}
            </Col>
            <Col className="table-col" md={2}>
              {baseRate.toLocaleString(locale)}
            </Col>
            <Col className="label-name" md={2}>
              {hourlyCapHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {mostRestrictiveHourCap === undefined ||
              mostRestrictiveHourCap === null
                ? "N/A"
                : mostRestrictiveHourCap.toLocaleString(locale)}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {serviceTimeHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {/*    {overrideService && overrideServiceTime !== ""
                ? overrideServiceTime?.toLocaleString(locale) : */}
              {stndServiceTime?.toLocaleString(locale)}
            </Col>
            <Col className="label-name" md={2}>
              {adjServiceTimeHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {overrideService && overrideServiceTime !== ""
                ? overrideServiceTime?.toLocaleString(locale)
                : stndServiceTime?.toLocaleString(locale)}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {prepTimeHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {/*  {overridePrep && overridePrepTime !== ""
                ? overridePrepTime?.toLocaleString(locale) :  */}
              {stndPrepTime?.toLocaleString(locale)}
            </Col>
            <Col className="label-name" md={2}>
              {adjPrepTimeHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {overridePrep && overridePrepTime !== ""
                ? overridePrepTime?.toLocaleString(locale)
                : stndPrepTime?.toLocaleString(locale)}
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {travelTimeHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {/* {overrideTravel && overrideTravelTime !== ""
                ? overrideTravelTime?.toLocaleString(locale) : */}
              {stndTravelTime?.toLocaleString(locale)}
            </Col>
            <Col className="label-name" md={2}>
              {adjTravelTimeHrsLabel}
            </Col>
            <Col className="table-col" md={2}>
              {overrideTravel && overrideTravelTime !== ""
                ? overrideTravelTime.toLocaleString(locale)
                : stndTravelTime.toLocaleString(locale)}
            </Col>
          </Row>
          <Row>
            <Col className="section-header" md={12}>
              {fmvRatePerHourLabel}
            </Col>
          </Row>
          {!hideSpecialityMultiplier ? (
            <Row>
              <Col md={2}>{hcpSpecialtyMultiplierLabel}</Col>
              <Col className="table-col" md={2}>
                {hcpSpecialtyMultiplier?.toLocaleString(locale)}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {!hideStatureMultiplier ? (
            <Row>
              <Col md={2}>{hcpStatureMultiplierLabel}</Col>
              <Col className="table-col" md={2}>
                {hcpStatureMultiplier?.toLocaleString(locale)}
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col md={2}>{calculatedRatePerHourLabel}</Col>
            <Col className="table-col" md={2}>
              {hourlyRate.toLocaleString(locale)}
            </Col>
          </Row>
          <Row>
            <Col md={2}>{minRangeLabel}</Col>
            <Col className="table-col" md={2}>
              {minRange}
              {showMinRange ? "%" : ""}
            </Col>
          </Row>
          <Row>
            <Col md={2}>{maxRangeLabel}</Col>
            <Col className="table-col" md={2}>
              {maxRange}
              {showMaxRange ? "%" : ""}
            </Col>
          </Row>
          <Row className="data-row"></Row>
          <Row>
            <Col md={2}></Col>
            {showMinRange ? (
              <Col className="align-middle" md={2}>
                {minimumRateLabel}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="align-middle" md={2}>
                {midLabel}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="align-middle" md={2}>
                {maximumRateLabel}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {fmvRatePerHourRangeLabel}
            </Col>
            {showMinRange ? (
              <Col
                md={2}
                className={
                  isMinHourlyRateCapped
                    ? "label-name table-col highlight"
                    : "label-name table-col"
                }
              >
                {minHourlyRate.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col
                md={2}
                className={
                  isHourlyRateCapped
                    ? "label-name table-col highlight"
                    : "label-name table-col"
                }
              >
                {hourlyRate.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col
                md={2}
                className={
                  isMaxHourlyRateCapped
                    ? "label-name table-col highlight"
                    : "label-name table-col"
                }
              >
                {maxHourlyRate.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col className="section-header" md={12}>
              {totalFeePerActivityLabel}
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            {showMinRange ? (
              <Col className="align-middle" md={2}>
                {minimumFeeLabel}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="align-middle" md={2}>
                {midLabel}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="align-middle" md={2}>
                {maximumFeeLabel}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col md={2}>
              {travelTimeLabel} (
              {overrideTravel ? overrideTravelTime : stndTravelTime}{" "}
              {hoursLabel})
            </Col>
            {showMinRange ? (
              <Col className="table-col" md={2}>
                {minTravelCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="table-col" md={2}>
                {travelCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="table-col" md={2}>
                {maxTravelCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col md={2}>
              {serviceTimeLabel} (
              {overrideService ? overrideServiceTime : stndServiceTime}{" "}
              {hoursLabel})
            </Col>
            {showMinRange ? (
              <Col className="table-col" md={2}>
                {minServiceCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="table-col" md={2}>
                {serviceCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="table-col" md={2}>
                {maxServiceCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col className="label-name" md={2}>
              {costOfEventLabel}
            </Col>
            {showMinRange ? (
              <Col className="label-name table-col" md={2}>
                {minEventCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="label-name table-col" md={2}>
                {eventCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="label-name table-col" md={2}>
                {maxEventCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col md={2}>
              {prepTimeCostLabel} (
              {overridePrep ? overridePrepTime : stndPrepTime} {hoursLabel})
            </Col>
            {showMinRange ? (
              <Col className="table-col" md={2}>
                {minPrepCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="table-col" md={2}>
                {prepCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="table-col" md={2}>
                {maxPrepCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {totalCostLabel}
            </Col>
            {showMinRange ? (
              <Col className="label-name table-col" md={2}>
                {minTotalCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="label-name table-col" md={2}>
                {totalCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="label-name table-col" md={2}>
                {maxTotalCost.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row"></Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {totalCostExclPrepTimeLabel}
            </Col>
            {showMinRange ? (
              <Col className="label-name table-col" md={2}>
                {minTotalCostExclPrep.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="label-name table-col" md={2}>
                {totalCostExclPrep.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="label-name table-col" md={2}>
                {maxTotalCostExclPrep.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row"></Row>
          <Row className="data-row">
            <Col className="label-name" md={2}>
              {totalCostExclTravelTimeLabel}
            </Col>
            {showMinRange ? (
              <Col className="label-name table-col" md={2}>
                {minTotalCostExclTravel.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="label-name table-col" md={2}>
                {totalCostExclTravel.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="label-name table-col" md={2}>
                {maxTotalCostExclTravel.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row"></Row>
          <Row>
            <Col md={2}></Col>
            {showMinRange ? (
              <Col className="align-middle" md={2}>
                {minimumLabel}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col className="align-middle" md={2}>
                {midLabel}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col className="align-middle" md={2}>
                {maximumLabel}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="data-row table-row">
            <Col className="label-name" md={2}>
              {fmvTotalCompensationLabel}
            </Col>
            {showMinRange ? (
              <Col
                md={2}
                className={
                  isMinCompensationCapped
                    ? "label-name align-middle highlight"
                    : "label-name align-middle"
                }
              >
                {minTotalCompensation.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMidRange ? (
              <Col
                md={2}
                className={
                  isTotalCompensationCapped
                    ? "label-name align-middle highlight"
                    : "label-name align-middle"
                }
              >
                {totalCompensation.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
            {showMaxRange ? (
              <Col
                md={2}
                className={
                  isMaxCompensationCapped
                    ? "label-name align-middle highlight"
                    : "label-name align-middle"
                }
              >
                {maxTotalCompensation.toLocaleString(locale)}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className={showCapExceededMssg ? "" : "no-show"}>
            <Col md={12} className="calc-validation-msg inherit-font">
              <RenderRichText html={globalCapMessage} />
            </Col>
          </Row>
        </Container>

        <Container className="calc-without-caps">
          <Row>
            <Col className="calc-section-header" md={12}>
              <div>{calculatedCompensationWithoutCapsLabel}</div>
            </Col>
          </Row>
          <Row>
            <Col className="label-name" md={12}>
              <div>{memoInformationPurposeOnlyLabel}</div>
            </Col>
          </Row>
          <Container>
            <Row className="calc-with-border">
              <Col md={2}></Col>
              <Col className="align-middle" md={2}>
                {timeLabel}
              </Col>
              <Col className="align-middle" md={2}>
                %
              </Col>
              <Col className="align-middle" md={2}>
                {ratePerHourLabel}
              </Col>
              <Col className="align-middle" md={2}>
                {totalCostLabel}
              </Col>
            </Row>
            <Row className="no-margin-row">
              <Col md={2}>{travelLabel}</Col>
              <Col className="align-middle" md={2}>
                {overrideTravel && overrideTravelTime !== ""
                  ? overrideTravelTime?.toLocaleString(locale)
                  : stndTravelTime?.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}>
                {travelTime ? travelTime : travelTimeDiscount}
              </Col>
              <Col className="align-middle" md={2}>
                {actualHourlyRate.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}>
                {actualTravelCost.toLocaleString(locale)}
              </Col>
            </Row>
            <Row className="no-margin-row">
              <Col md={2}>{serviceLabel}</Col>
              <Col className="align-middle" md={2}>
                {overrideService && overrideServiceTime !== ""
                  ? overrideServiceTime?.toLocaleString(locale)
                  : stndServiceTime?.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}>
                100%
              </Col>
              <Col className="align-middle" md={2}>
                {actualHourlyRate.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}>
                {actualServiceCost.toLocaleString(locale)}
              </Col>
            </Row>
            <Row className="no-margin-row">
              <Col md={2}>{preparationLabel}</Col>
              <Col className="align-middle" md={2}>
                {overridePrep && overridePrepTime !== ""
                  ? overridePrepTime?.toLocaleString(locale)
                  : stndPrepTime?.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}>
                100%
              </Col>
              <Col className="align-middle" md={2}>
                {actualHourlyRate.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}>
                {actualPrepCost.toLocaleString(locale)}
              </Col>
            </Row>
            <Row className="calc-with-border">
              <Col md={2}>{totalLabel}</Col>
              <Col md={2} className="align-middle">
                {actualTotalHours?.toLocaleString(locale)}
              </Col>
              <Col className="align-middle" md={2}></Col>
              <Col className="align-middle" md={2}></Col>
              <Col className="label-name align-middle" md={2}>
                {actualTotalCost.toLocaleString(locale)}
              </Col>
            </Row>
          </Container>
          <Row>
            <Col md={2}></Col>
            <Col md={2}></Col>
            <Col md={2}></Col>
            <Col className="align-middle" md={2}>
              Min.
            </Col>
            <Col className="label-name align-middle" md={2}>
              {minActualTotalCost.toLocaleString(locale)}
            </Col>
          </Row>
          <Row className="data-row">
            <Col md={2}></Col>
            <Col md={2}></Col>
            <Col md={2}></Col>
            <Col className="align-middle" md={2}>
              Max.
            </Col>
            <Col className="label-name align-middle" md={2}>
              {maxActualTotalCost.toLocaleString(locale)}
            </Col>
          </Row>
        </Container>
      </div>
      {hideJspdfButton ? (
        <div
          onClick={ExportCalculatorToJSPDF}
          className="display-text-for-pdf"
        ></div>
      ) : (
        ""
      )}
      <div className="calc-config-buttons disable_exporttopdf_feature">
        {showExportPDFNote ? (
          <p className="export-pdf-note">
            To preserve text entered using non-Latin characters, please use the
            browser's print to PDF feature. (Google Chrome is recommended)
          </p>
        ) : (
          ""
        )}
        <Button
          variant="success"
          className="export-to-pdf"
          onClick={ExportCalculatorToPDF}
        >
          EXPORT TO PDF
        </Button>
      </div>
      <div className="hide_exporttopdf_msg">
        <p className="disable_exporttopdf_msg align-right">
          Export to pdf is not available for mobile devices.
        </p>
      </div>
    </div>
  );
}
